export const meuGetNFTsTicketDeedScript = `
// Official
import NonFungibleToken from 0xNFT
// MEU
import DeedMarketTicket from 0xMEU

pub fun main(acctAddrUser: Address): [&DeedMarketTicket.NFT?] {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  let cltnCapTkDeedUser = getAccount(acctAddrUser).getCapability(/public/MetaUzuCollection_Emojia_Ticket_Deed)
                            .borrow<&DeedMarketTicket.Collection{NonFungibleToken.CollectionPublic, DeedMarketTicket.CollectionPublic}>()
                            ?? panic("Can't get the User's tkDeed's collection.")

  let returnVals: [&DeedMarketTicket.NFT?] = []

  let ids = cltnCapTkDeedUser.getIDs()
  for id in ids {
    returnVals.append(cltnCapTkDeedUser.borrowEntireNFT(id: id))
  }

  return returnVals
}
`

import React, { useState } from "react";
  
export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
    const [items, setItems] = useState(0);
    const [user, setUser] = useState();
  
    return (
        <Context.Provider value={{ user, setUser }}>
            {children}
        </Context.Provider>
    );
};
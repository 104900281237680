import React from "react"
import ReactDOM from "react-dom"

import "@fontsource/poppins"    // tailwind 의 basic font 로 custom 하여 사용중

import "./config/config.js" // FCL config 환경변수 설정 삽입..

import App from "./App"
import reportWebVitals from "./reportWebVitals"
import {Header} from "./components/Header.js"

import {ContextProvider} from "./Context"
import "./style/main.css";
import "./style/custom.css"

ReactDOM.render(
    <ContextProvider>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </ContextProvider>,
    document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

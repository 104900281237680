import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useState, useEffect, useContext, Fragment } from 'react';
import { Context } from '../Context';

import * as fcl from '@onflow/fcl';
import * as types from '@onflow/types';
// import { meuGetNFTsCentralScript } from '../cadence/scripts/meu_get_nfts_central';
import { meuGetNFTsReplicaScript } from '../cadence/scripts/meu_get_nfts_replicas';
import { meuGetNFTCentralSingleScript } from '../cadence/scripts/meu_get_nft_central_single.js';
import { meuGetNFTsDeedScript } from "../cadence/scripts/meu_get_nfts_deeds.js";
import { meuDeedSetPriceTx } from "../cadence/transactions/meu_deed_set_price";
import { deedListForSaleTx } from "../cadence/transactions/meu_deed_list_for_sale";
import { serverAuthorization } from '../serverSigner';
import { meuGetNFTsTicketReplicaScript } from "../cadence/scripts/meu_get_nfts_ticket_replica_v1";
import { meuGetNFTsTicketDeedScript } from "../cadence/scripts/meu_get_nfts_ticket_deed_v1";
import { meuReplicaPaid2Tx } from "../cadence/transactions/meu_replica_paid2";
import { meuCancleSaleReplicaTx } from "../cadence/transactions/meu_replica_cancle_sale";
import { meuReplicaPaidTx } from "../cadence/transactions/meu_replica_paid";
import { meuReplicaListForSaleTx } from '../cadence/transactions/meu_replica_list_for_sale';
import { meuCancleSaleDeedTx } from "../cadence/transactions/meu_deed_cancle_sale";
import { meuDeedPaidTx } from "../cadence/transactions/meu_deed_paid";
import { meuGetCollectionCheckTx } from '../cadence/scripts/meu_get_collections_check_v1';
import { meuCreateAllCollectionTx } from '../cadence/transactions/meu_create_all_collection';

import { FocusTrap, Listbox, Transition } from '@headlessui/react'
import * as icos from '@heroicons/react/24/solid'
import * as icoo from "@heroicons/react/24/outline"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaucet } from '@fortawesome/free-solid-svg-icons';

import Chart from 'react-apexcharts'

import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from 'sweetalert2' // 현재 bulma 테마 적용중.. >> tailwind.css 에 선언되어있음..
// https://www.npmjs.com/package/sweetalert2
// https://github.com/sweetalert2/sweetalert2-themes/tree/main/bulma
import { toast, ToastContainer } from 'react-toastify'; // https://www.npmjs.com/package/react-toastify
import 'react-toastify/dist/ReactToastify.css'; // import 'react-toastify/dist/ReactToastify.min.css';
// react-toastify 는 RCA 버전관련 문제/충돌 있음.. 때문에, 일단 버전을 9.0.3 으로 낮추었음.
// https://github.com/fkhadra/react-toastify/issues/775
// https://github.com/reactioncommerce/reaction-component-library/issues/399#issuecomment-467860022



// export default MyWallet;  // react-toastify 사용을 위해서라도, default export 선언이 있어야함.
export default function MyWallet() {
  /*******************************************************
  * 화면 공통사용 함수 선언
  *******************************************************/
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  function srcNFT(ipfsHash) {
    return `https://ipfs.io/ipfs/${ipfsHash}`
  }
  const navigate = useNavigate();
  const openItem = (nft, category, objOriginal) => {
    console.log('nft is ', nft);
    console.log('category is ', category);
    console.log('objOriginal is ', objOriginal);

    nft.objOriginal = objOriginal

    navigate('/item', {
      state: {
        nft: nft,
        category: category,
      },
    });
  };
  const openItemByCid = async (cid) => {
    try {
      const result = await fcl.send([
          fcl.script(meuGetNFTCentralSingleScript),
          fcl.args([
            fcl.arg(MARKET_ADDRESS, types.Address),
            fcl.arg(cid, types.UInt64),
          ]),
        ]).then(fcl.decode);
  
      console.log("getCentralNFT ===> ", result);
      
      // openItem 함수 호출
      openItem(result, "Singles", result)

    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  const funcRandomInt = (min=0, max=100) => {
    return Math.floor( (Math.random() * (max - min + 1) ) + min);
  }
  const funcRandomIntArray = (min=0, max=100, count=10, isSort=true) => {
    //종료
    if(max-min+1<count)return;
    //배열생성
    const rst=[];
    while(1){
      let index = funcRandomInt(min, max);
      if(rst.indexOf(index) > -1){  //중복여부를체크
        continue;
      }
      rst.push(index);
      //원하는배열갯수가되면종료
      if(rst.length===count){
        break;
      }
    }
    //정렬여부 확인
    if (isSort) {
      return rst.sort(function(a,b){
        return a - b;
      });  
    } else {
      return rst
    }
  }


  /*******************************************************
  * 화면 공통사용 변수 선언
  *******************************************************/
  const [ isCollectionUser, setIsCollectionUser] = useState(true)
  const [tab, setTab] = useState("home")            // 탭화면 관련 보여주거나 안보여주도록 하는 네비게이션 역할의 훅
  const { user, setUser } = useContext(Context)     // FCL 블록토지갑 로그인한 사용자값 Context 로 상속받아온 것?
  console.log('User ==> ', user);
  const gradeDeeds = [
    { bg: "bg-blue-600",   text: "text-blue-600",   bgh: "hover:bg-blue-700", border: "border-blue-600/80" },
    { bg: "bg-pink-600",   text: "text-pink-600",   bgh: "hover:bg-pink-700",   border: "border-pink-600/80" },
    { bg: "bg-meu-500",    text: "text-meu-500",    bgh: "hover:bg-meu-600",    border: "border-meu-500/80" },
    { bg: "bg-indigo-500", text: "text-indigo-500", bgh: "hover:bg-indigo-600", border: "border-indigo-500/80" },
    { bg: "bg-gray-800",   text: "text-gray-800",   bgh: "hover:bg-black",   border: "border-gray-800/80" },
    { bg: "bg-amber-500",  text: "text-amber-500",  bgh: "hover:bg-amber-600",  border: "border-amber-500/80" },
    { bg: "bg-lime-500",   text: "text-lime-500",   bgh: "hover:bg-lime-600",   border: "border-lime-500/80" },
    { bg: "bg-cyan-600",   text: "text-cyan-600",   bgh: "hover:bg-cyan-700",   border: "border-cyan-600/80" },
    { bg: "bg-purple-500", text: "text-purple-500", bgh: "hover:bg-purple-600", border: "border-purple-500/80" },
    { bg: "bg-rose-500",   text: "text-rose-500",   bgh: "hover:bg-rose-600",   border: "border-rose-500/80" },
  ]

  /*******************************************************
  * FCL관련 변수/함수 선언
  *******************************************************/
  const MARKET_ADDRESS = process.env.REACT_APP_FCL_MEU_ADDRESS
  const [balance, setBalance] = useState(0)
  const [nfts, setNFTs] = useState([])    // 레플리카
  const [deeds, setDeeds] = useState([])  // 증서
  const [ticketsReplica, setTicketsReplica] = useState([])  // 티켓_레플리카
  const [ticketsDeed, setTicketsDeed] = useState([])  // 티켓_증서
  // const [ticketProceeds, setTicketProceeds] = useState([]) // 티켓_판매대금
  // const [ticketReplicas, setTicketReplicas] = useState([]) // 티켓_레플리카(리세일분)
  // const [ticketDeeds, setTicketDeeds] = useState([]) // 티켓_증서
  /*** 지갑주소로, 로그인한 사용자의 MEU 서비스의 Collections 모두 만들어내는 함수 ***/
  const createAllCollectionUser = async () => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuCreateAllCollectionTx),
          fcl.args([fcl.arg(false, types.Bool)]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };
  /*** 지갑주소로, 로그인한 사용자가 MEU 서비스의 Collections 들을 제대로 갖고 있는지 확인하는 함수 ***/
  const scriptGetCollectionCheckUser = async () => {
    try {
      const result = await fcl.send([
        fcl.script(meuGetCollectionCheckTx),
        fcl.args([
          // fcl.arg(user.addr, types.Address),
          fcl.arg(user.addr, types.Address),
          // fcl.arg(namesCollectionUser, types.Array(types.String)),
          
        ]),
      ]).then(fcl.decode);
        
      console.log('scriptGetCollectionCheckUser result ===> ', result);
      let exists = true
      for (let i = 1; i < result.length; i++){
        exists = exists && result[i].exists
      }
      setIsCollectionUser(exists)
      // setIsCollectionUser(prevList => [...result])

    } catch (error) {
      console.log("error ===> ", error)
      setIsCollectionUser(false)
      // alert("error ===> ", error)
    }
  };
  /*** 지갑주소로, 지갑 FLOW 잔액 가져오는 함수 ***/
  const getAccount = async (address) => {
    try {
      const account = await fcl.send([
        fcl.getAccount(address)
      ]).then(fcl.decode)
      console.log('result is [getAccount] ', account)
      // return account;
      // setBalance(account.balance * 0.00000001)
      setBalance(account.balance / 100000000)
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  /*** 지갑주소로, 레플리카(Replica) NFTs 가져오는 함수 ***/  
  const getMyNFTs = async () => {
    try {
      const result = await fcl.send([
        fcl.script(meuGetNFTsReplicaScript),
        fcl.args([fcl.arg(user.addr, types.Address)]),
      ]).then(fcl.decode)
      // console.log('result is [getMyNFTs] ', result)
      setNFTs(result)
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  /*** 지갑주소로, 센트럴 단일 NFT 가져오는 함수 ***/  
  const getCentralNFT = async (cid) => {
    try {
      const result = await fcl.send([
          fcl.script(meuGetNFTCentralSingleScript),
          fcl.args([
            fcl.arg(MARKET_ADDRESS, types.Address),
            fcl.arg(cid, types.UInt64),
          ]),
        ]).then(fcl.decode);
  
      console.log("getCentralNFT ===> ", result);
      
      // 모달 카드창에 내용 채우기..
      setCardTitle(result.metadata.nameArticle)
      setCardDesc(result.metadata.description)
      setCardBirthday(result.metadata.birthday)
      setAuthorName(result.metadata.nameAuthor)
      setAuthorAddr(result.metadata.addrAuthor)
      setCardPrice(result.price)
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };
  /*** 지갑주소로, Deed(증서) NFTs 가져오는 함수 ***/  
  const getMyDeeds = async () => {
    try {
      const result = await fcl.send([
            fcl.script(meuGetNFTsDeedScript),
            fcl.args([
                fcl.arg(user.addr, types.Address),
                fcl.arg(MARKET_ADDRESS, types.Address)
            ])
        ]).then(fcl.decode);
  
        console.log("getMyDeeds ===> ", result);
        setDeeds(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }  
  }
  /*** 지갑주소 & cid로, Deed(증서) NFT 의 마켓에서 판매되는 레플리카의 가격(Price of Replica)을 변경하는 함수 ***/  
  const setPriceOfDeed = async (cid, price) => {
    try {
      const transactionId = await fcl.send([
        fcl.transaction(meuDeedSetPriceTx),
        fcl.args([
          fcl.arg(cid, types.UInt64),
          fcl.arg(price, types.UFix64),
        ]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz, serverAuthorization]),
        fcl.limit(9999)
      ]).then(fcl.decode);
  
      console.log(transactionId);
  
      funcCloseModalDeed()
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  /*** 지갑주소 & cid & id(did)로, Deed(증서) NFT 판매거래소(마켓)에 올리는 함수 >> deed는 거래소에 올리고, 그에 해당하는 티켓nft를 발권받음.. ***/ 
  const deedListForSale = async (id, cid, price) => {
    try {
      const transactionId = await fcl.send([
        fcl.transaction(deedListForSaleTx),
        fcl.args([
          fcl.arg(id, types.UInt64),
          fcl.arg(cid, types.UInt64),
          fcl.arg(price, types.UFix64)
        ]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz, serverAuthorization]),
        fcl.limit(9999)
      ]).then(fcl.decode);
  
      console.log(transactionId);
  
      funcCloseModalDeed()
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  const replicaListForSale = async (id, cid, nid, price) => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuReplicaListForSaleTx),
          fcl.args([
            fcl.arg(id, types.UInt64),
            fcl.arg(cid, types.UInt64),
            fcl.arg(nid, types.UInt64),
            fcl.arg(price, types.UFix64),
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
  
      funcCloseModalDeed()
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };


  /*******************************************************
  * 모달관련 변수/함수 선언 (레플리카 용)
  *******************************************************/
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [cardImage, setCardImage] = useState("")
  const [cardNumber, setCardNumber] = useState("")
  const [cardTitle, setCardTitle] = useState("")
  const [cardDesc, setCardDesc] = useState("")
  const [cardBirthday, setCardBirthday] = useState("")
  const [cardMintday, setMintday] = useState("")
  const [cardAuthorName, setAuthorName] = useState("")
  const [cardAuthorAddr, setAuthorAddr] = useState("")
  const [cardMinterAddr, setMinterAddr] = useState("")
  const [cardPrice, setCardPrice] = useState("")
  const [cardId, setCardId] = useState("")
  const [cardCid, setCardCid] = useState("")
  const [cardNid, setCardNid] = useState("")
  const [cardContainer, setCardContainer] = useState("replica") // replica, graph ...
  /*** 레플리카 모달 페이지 오픈 함수 ***/
  const funcOpenModal = (e, nft) => {
    // cardContainer 기본값으로 돌리기..
    setCardContainer("replica")
    
    // card 내용 채우기..
    getCentralNFT(nft.cid)
    console.log(nft)

    setCardImage(srcNFT(nft.ipfsHash))
    setCardNumber(nft.nid)
    setMintday(nft.metadata.birthday)
    setMinterAddr(nft.replicator)

    setCardId(nft.id)
    setCardCid(nft.cid)
    setCardNid(nft.nid)
    
    // card 보여주기..
    setIsOpenModal(true)
  }
  /*** 레플리카 모달 페이지 클로징 함수 ***/
  const funcCloseModal = (e) => {
    setIsOpenModal(false)
  }

  /*******************************************************
  * 모달관련 변수/함수 선언 (Deed 용)
  *******************************************************/
  const [isOpenModalDeed, setIsOpenModalDeed] = useState(false)
  const [nameModalDeed, setNameModalDeed] = useState("")
  const [priceModalDeed, setPriceModalDeed] = useState(parseFloat(0.00000001).toFixed(8))
  const [cidModalDeed, setCidModalDeed] = useState(0)
  const [didModalDeed, setDidModalDeed] = useState(0)
  const [nidModalDeed, setNidModalDeed] = useState(0)
  /*** Deed 모달 페이지 오픈 함수 ***/
  const funcOpenModalDeedSell = (e, deed) => {
    setPriceModalDeed(parseFloat(0.00000001).toFixed(8))
    setCidModalDeed(deed.nft.cid)
    setDidModalDeed(deed.nft.id)
    setNameModalDeed("Deed")

    setIsOpenModalDeed(true)
  }
  const funcOpenModalDeedSet = (e, deed) => {
    setPriceModalDeed(parseFloat(deed.price).toFixed(8))
    setCidModalDeed(deed.nft.cid)
    setNameModalDeed("Replica")

    setIsOpenModalDeed(true)
  }
  const funcOpenModalReplicaSell = (e) => {
    setIsOpenModal(false)

    setPriceModalDeed(parseFloat(0.00000001).toFixed(8))
    // setCidModalDeed(cardCid)
    // setDidModalDeed(cardId)
    // setNidModalDeed(cardNid)
    setNameModalDeed("MyReplica")

    setIsOpenModalDeed(true)
  }
  const funcCloseModalDeed = () => {
    setPriceModalDeed(parseFloat(0.00000001).toFixed(8))
    setCidModalDeed(0)
    setIsOpenModalDeed(false)
  }



  /*******************************************************
  * 티켓관련 변수/함수 선언 (TicketOfDeed, TicketOfReplica ... 용)
  *******************************************************/
  const [selected, setSelected] = useState("proceeds")
  /*** Deed 모달 페이지 오픈 함수 ***/
  const getMyTicketsReplica = async () => {
    try {
      const result = await fcl.send([
        fcl.script(meuGetNFTsTicketReplicaScript),
        fcl.args([
          fcl.arg(user.addr, types.Address),
          fcl.arg(MARKET_ADDRESS, types.Address)
        ])
      ]).then(fcl.decode);
  
      console.log("getMyTicketsReplica >>>> ", result)
      setTicketsReplica(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  const getMyTicketsDeed = async () => {
    try {
      const result = await fcl.send([
        fcl.script(meuGetNFTsTicketDeedScript),
        fcl.args([
          fcl.arg(user.addr, types.Address),
          fcl.arg(MARKET_ADDRESS, types.Address)
        ])
      ]).then(fcl.decode);
  
      console.log("getMyTicketsDeed >>>> ", result)
      setTicketsDeed(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  /*** Deed 를 갖고있는 상태에서, 마켓에서 Replica 발행판매로 인한 수익을 정산받는 기능 ***/
  const paid2 = async (id) => {
    try {
      const transactionId = await fcl.send([
        fcl.transaction(meuReplicaPaid2Tx),
        fcl.args([
          fcl.arg(id, types.UInt64),    // 여기서의 id 는 tid 를 가리킴..
        ]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz, serverAuthorization]),
        fcl.limit(9999)
      ]).then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }

  const cancleSaleReplica = async (id, rid) => {
    try {
      const transactionId = await fcl.send([
        fcl.transaction(meuCancleSaleReplicaTx),
        fcl.args([
          fcl.arg(id, types.UInt64),
          fcl.arg(rid, types.UInt64),
        ]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz, serverAuthorization]),
        fcl.limit(9999)
      ]).then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  const paidReplica = async (id, rid, price) => {
    try {
      const transactionId = await fcl.send([
          fcl.transaction(meuReplicaPaidTx),
          fcl.args([
            fcl.arg(id, types.UInt64),    // 여기서의 id 는 tid 를 가리킴..
            fcl.arg(rid, types.UInt64),
            fcl.arg(price, types.UFix64) 
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999)
        ]).then(fcl.decode);
    
        console.log(transactionId);
        return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  const paidDeed = async (id, cid, did, price) => {
    try {
      const transactionId = await fcl.send([
          fcl.transaction(meuDeedPaidTx),
          fcl.args([
            fcl.arg(id, types.UInt64),    // 여기서의 id 는 tid 를 가리킴..
            fcl.arg(cid, types.UInt64),     
            fcl.arg(did, types.UInt64),
            fcl.arg(price, types.UFix64) 
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999)
        ]).then(fcl.decode);
    
        console.log(transactionId);
        return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  const cancleSaleDeed = async (id, did) => {
    try {
      const transactionId = await fcl.send([
        fcl.transaction(meuCancleSaleDeedTx),
        fcl.args([
          fcl.arg(id, types.UInt64),
          fcl.arg(did, types.UInt64),
        ]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz, serverAuthorization]),
        fcl.limit(9999)
      ]).then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  


   /*******************************************************
  * useEffect 함수 사용 부분
  *******************************************************/
  /*** user 정보 바뀔때마다.. ***/
  useEffect(() => {
    if (!user?.addr) {
      console.log('not logined..')
      
    }
    getAccount(user?.addr);
    scriptGetCollectionCheckUser();
  }, [user]);
  /*** payments 페이지 관련 ***/
  useEffect(() => {
    if (tab === "payments") {
      console.log("selected ===> ", selected)

      if (selected === "proceeds" || selected === "replicas") {
        getMyTicketsReplica()  
      } else if (selected === "deeds") {
        getMyTicketsDeed()  
      }
    }
  }, [selected]);
  

  /*******************************************************
  * 최종 페이지 리턴 :: user 값의 여부에 따라, 페이지 리턴
  *******************************************************/
  /*** user 값이 없을 경우, / 페이지로 이동 ***/
  if (!user?.addr) {
    return <>
      <div className="bg-white p-12 py-8 w-full flex flex-col justify-center items-center">
        <span className="font-semibold">To completely use MEU's services, please Sign in.</span>
        <button className="py-2 px-4 sm:px-8 ml-4 bg-meu-500 hover:bg-meu-600 text-white rounded-md mt-5"
          onClick={() => {
            // location.href = '/mywallet'
            fcl.authenticate();
          }}
        >
          Sign In
        </button>
      </div>
    </>;
  }
  /*** user 값이 있는 경우, 해당페이지 출력 ***/
  if (user.addr)
    return (
      <>
        {/* <h1>My address: {user && user.addr ? user.addr : ''}</h1> */}
        {/* <h2>admin address: {process.env.REACT_APP_FCL_MEU_ADDRESS}</h2> */}
        {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
        
        {/* react-toastify 본문 return 안에 넣어줘야 toast 메시지가 작동함 */}
        <ToastContainer />

        <div className="md:container md:mx-auto">
          <ul
            className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4 mt-3"
            id="tabs-tab"
            role="tablist"
          >
            <li className="nav-item cursor-pointer" role="presentation">
              <a
                // href="#tabs-home"
                className={classNames(tab==="home" ? "bg-meu-500 text-white underline underline-offset-4" : "", "nav-link block font-semibold text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-meu-600 hover:text-white focus:border-transparent active")}
                id="tabs-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-home"
                role="tab"
                aria-controls="tabs-home"
                aria-selected="true"
                onClick={() => { getAccount(user.addr); setTab("home"); }}
              >
                Home
              </a>
            </li>
            {isCollectionUser && <>
            <li className="nav-item cursor-pointer" role="presentation">
              <a
                // href="#tabs-replicas"
                className={classNames(tab==="replicas" ? "bg-meu-500 text-white underline underline-offset-4" : "", "nav-link block font-semibold text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-meu-600 hover:text-white focus:border-transparent")}
                id="tabs-replicas-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-replicas"
                role="tab"
                aria-controls="tabs-replicas"
                aria-selected="false"
                onClick={() => { getMyNFTs(); setTab("replicas"); }}
              >
                Replicas
              </a>
            </li>
            <li className="nav-item cursor-pointer" role="presentation">
              <a
                // href="#tabs-deeds"
                className={classNames(tab==="deeds" ? "bg-meu-500 text-white underline underline-offset-4" : "", "nav-link block font-semibold text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-meu-600 hover:text-white focus:border-transparent")}
                id="tabs-deeds-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-deeds"
                role="tab"
                aria-controls="tabs-deeds"
                aria-selected="false"
                onClick={() => { getMyDeeds(); setTab("deeds"); }}
              >
                Deeds
              </a>
            </li>
            <li className="nav-item cursor-pointer" role="presentation">
              <a
                // href="#tabs-payments"
                className={classNames(tab==="payments" ? "bg-meu-500 text-white underline underline-offset-4" : "", "nav-link block font-semibold text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-meu-600 hover:text-white focus:border-transparent")}
                id="tabs-payments-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-payments"
                role="tab"
                aria-controls="tabs-payments"
                aria-selected="false"
                onClick={() => {
                  if (selected === "proceeds" || selected === "replicas") {
                    getMyTicketsReplica()  
                  } else if (selected === "deeds") {
                    getMyTicketsDeed()  
                  }
                  setTab("payments")
                }}
              >
                Payments
              </a>
            </li>
            </>}
          </ul>
          <div className="tab-content" id="tabs-tabContent">
            { (tab === "home") &&
            <div
              className="tab-pane fade show active"
              id="tabs-home"
              role="tabpanel"
              aria-labelledby="tabs-home-tab"
            >
              {/* My Information */}
              <div className="bg-white">
                <div className="px-4 py-5 pt-12 sm:px-6">
                  <h3 className="text-2xl font-bold leading-7 text-gray-600">
                    # My Information
                  </h3>
                  {/* <p className="mt-1 max-w-2xl text-sm text-gray-500"></p> */}
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">
                        Email
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {user && user.services[0].scoped["email"] ? user.services[0].scoped["email"] : ''}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">
                        Wallet Address
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center ">
                        {/* <a className="flex items-center hover:underline underline-offset-4 hover:text-meu-500 cursor-pointer"
                          href="https://testnet-faucet.onflow.org/fund-account" target={"_blank"}
                        >
                          <icoo.CloudArrowDownIcon className="h-4 w-4 mr-1.5" /> {user && user.addr ? user.addr : ''}
                        </a> */}
                        {(user && user.addr) &&
                            <CopyToClipboard text={user.addr} onCopy={() => {
                              // alert("copied")
                              // toast.success('🦄 Wow so easy!', {
                              toast.success('📋 Copied!', {
                                // 사용방법 :: https://fkhadra.github.io/react-toastify/how-to-style
                                position: "top-center",
                                autoClose: 1200,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                className: "border shadow-lg",
                                bodyClassName: "",
                                // progressClassName: 'fancy-progress-bar'
                                });
                            }}>
                            <button className="flex items-center hover:underline underline-offset-4 hover:text-meu-500">
                              <icoo.ClipboardIcon className="h-4 w-4 mr-1.5" />
                              <span>{user.addr}</span>
                              {/* <span className="ml-3 rounded border bg-meu-500 text-white px-1  py-0.5 text-xs">copy</span> */}
                            </button>
                          </CopyToClipboard>
                        }
                        
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">
                        FLOW
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <a className="flex items-center hover:underline underline-offset-4 hover:text-meu-500 cursor-pointer"
                          href="https://testnet-faucet.onflow.org/fund-account" target={"_blank"}
                        >
                          <icoo.CloudArrowDownIcon className="h-4 w-4 mr-1.5" /> {parseFloat(balance).toFixed(8)}
                        </a>
                          
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              {/* Create Collections Button.. */}
                {!isCollectionUser &&
                  <div className="bg-white px-4 py-5 pt-16 sm:px-6 flex justify-between items-center">
                    <span className="font-semibold">To completely use MEU's services, please link your wallet.</span>
                    <button className="py-2 px-4 sm:px-8 ml-4 bg-meu-500 hover:bg-meu-600 text-white rounded-md"
                      onClick={() => {
                        createAllCollectionUser()
                      }}
                    >
                      Connect Now
                    </button>
                  </div>
                }
              

              {/* My Profile */}
                {isCollectionUser &&
                  <div className="bg-white">
                    <div className="pl-4 py-5 pt-16 sm:pl-6 flex justify-between w-full">
                      <h3 className="text-2xl font-bold leading-7 text-gray-600">
                        {/* text-2xl font-bold text-center leading-7 text-gray-900 sm:text-3xl sm:truncate */}
                        # My Profile
                      </h3>
                      <button className="border rounded-md bg-meu-500 text-white flex flex-row flex-nowrap items-center py-1 pl-2 pr-4 text-sm hover:bg-meu-600 font-semibold"
                        onClick={()=>{alert("Comming soon. Stay tuned!")}}
                      >
                        <icos.PencilIcon className="h-4 w-4 mr-1.5 pl-0.5" />Edit
                      </button>
                      {/* <p className="mt-1 max-w-2xl text-sm text-gray-500"></p> */}
                    </div>
                    <div className="border-t border-gray-200">
                      <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-bold text-gray-500">
                            Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Bomber Girl
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-t border-gray-200">
                      <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-bold text-gray-500">
                            Channel
                          </dt>
                          <dd className="flex flex-row items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 cursor-pointer hover:text-meu-500 hover:underline underline-offset-4"
                            onClick={() => navigate("/channel")}
                          >
                            <icoo.LinkIcon className="h-4 w-4 mr-1.5" /> Carnman Project
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-t border-gray-200">
                      <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-bold text-gray-500">
                            Introduce
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Hi! there. I have autism, but instead I can draw "Carnman". All are welcome. Thank you :)
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-t border-gray-200">
                      <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-bold text-gray-500">
                            Avatar
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <div>
                              <div className="mt-1 flex items-center">
                                <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100 border">
                                  <img
                                    className="w-full rounded-t-lg object-cover object-center shadow-md"
                                    src={`${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_007.gif`} // 360 x 200 px ===> only size..
                                  />
                                  {/* 추후 살릴것, 아바타없을시 보여줘야하는 부분임..
                              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg> */}
                                </span>
                                <button
                                  type="button"
                                  className="ml-5 py-2 px-3 border border-transparent rounded-md text-white shadow-sm text-sm leading-4 font-medium bg-meu-500 hover:bg-meu-600 focus:outline-none"
                                >
                                  Change
                                </button>
                              </div>
                            </div>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                }
            </div>
            }
            
            {(tab === "replicas") &&
            <div
              className="tab-pane fade"
              id="tabs-replicas"
              role="tabpanel"
              aria-labelledby="tabs-replicas-tab"
            >
              <div className="bg-white">
                <div className="px-4 py-5 pt-12 sm:px-6">
                  <h3 className="text-2xl font-bold leading-7 text-gray-600">
                    # My Replicas
                  </h3>
                  {/* <p className="mt-1 max-w-2xl text-sm text-gray-500"></p> */}
                </div>
              </div>
                  
              <div className="grid px-4 py-5 pt-8 grid-cols-4 gap-y-10 sm:grid-cols-6 gap-x-2 lg:grid-cols-8 xl:grid-cols-10 xl:gap-x-4">
                {/* {JSON.stringify(nft)} */}

                {nfts.map((nft, index) => {
                  return (<>
                    <div key={nft.id} className="flex border-2 rounded-md p-1 shadow hover:border-meu-500 cursor-pointer transform hover:scale-110 transition duration-0"
                      onClick={(e) => {funcOpenModal(e, nft)}}>
                      <ul>
                        <li>
                          <img className="w-full h-full" alt="" src={`https://ipfs.io/ipfs/${nft.ipfsHash}`} />
                        </li>
                        <li className="bg-yellow-50 hover:bg-yellow-100 text-meu-600 -m-1 mt-0 rounded-md pr-1.5 text-sm text-right font-semibold">
                          <span className="text-xs">#</span>&nbsp;{nft.nid}
                        </li>
                      </ul>
                    </div>
                  </>);
                })}
                {/*  */}
              </div>
                  
            </div>
            }


            {(tab === "deeds") && <>
            <div
              className="tab-pane fade"
              id="tabs-deeds"
              role="tabpanel"
              aria-labelledby="tabs-deeds-tab"
            >
              <div className="bg-white">
                <div className="px-4 py-5 pt-12 sm:px-6">
                  <h3 className="text-2xl font-bold leading-7 text-gray-600">
                    # My Deeds
                  </h3>
                  {/* <p className="mt-1 max-w-2xl text-sm text-gray-500"></p> */}
                </div>
              </div>
                  
              <div className="grid px-4 py-5 pt-8 grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-2 lg:grid-cols-4 xl:grid-cols-6 xl:gap-x-4">
                {/* {JSON.stringify(nft)} */}

                {deeds.map((deed, index) => {
                  return (<>
                    <div key={index} className={gradeDeeds[deed.nft.uuid%10].bg + " rounded-3xl rounded-bl-lg"}>
                      <div className={gradeDeeds[deed.nft.uuid % 10].border + " relative hover:translate-x-1 hover:-translate-y-1 delay-50 duration-100 bg-white border-8 rounded-3xl rounded-b-lg "}>
                        {/* <button className={gradeDeeds[deed.nft.uuid % 10].bg + " absolute top-2 right-2 text-white rounded-full w-9 h-9 text-center p-2 font-semibold " + gradeDeeds[deed.nft.uuid % 10].bgh}> */}
                        <button className={gradeDeeds[deed.nft.uuid % 10].text + " absolute top-2 right-2 text-center font-semibold "}>
                          {deed.nft.uuid % 2
                            ? <icos.LockClosedIcon className="h-6 w-6" />
                            : <icos.LockOpenIcon className="h-6 w-6"/>
                          }
                        </button>
                        <img src={`https://ipfs.io/ipfs/${deed.nft.ipfsHash}`}
                          className="rounded-t-3xl cursor-pointer"
                          onClick={() => {
                            console.log(deed)
                            openItemByCid(deed.nft.cid)
                          }}
                        />
                        <div className="w-full flex">
                          {/* 좌측 */}
                          <div className={gradeDeeds[deed.nft.uuid % 10].bg + " w-1/2 rounded-tr-lg opacity-80"}>
                            <ul className="text-white">
                              <li className="flex pt-2 pl-1">
                                <icos.HeartIcon className="h-5 w-5 pr-1"/>1,281
                              </li>
                              <li className="flex pt-1 pl-1">
                                <icos.ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5 pr-1"/> 985
                              </li>
                              <li className="flex pt-1 pl-1">
                                <icos.ArrowDownTrayIcon className="h-5 w-5 pr-1"/> 20,428
                              </li>
                            </ul>
                          </div>
                          {/* 우측 */}
                          <div className=" w-1/2 bg-white rounded-br-3xl relative">
                            <div className="flex justify-end">
                              <button className={gradeDeeds[deed.nft.uuid % 10].bg + " text-sm font-semibold px-4 py-2 hover:shadow-lg tracking-wider text-white rounded-tl  opacity-50 rounded-bl " + gradeDeeds[deed.nft.uuid % 10].bgh}
                                onClick={(e) => { 
                                  // console.log("Sell button Clicked !!")
                                  funcOpenModalDeedSell(e, deed)
                                }}
                              >
                                <span>Sell</span>
                              </button>
                            </div>
                            <div className="absolute right-2 bottom-0 font-semibold text-right -mb-2">
                              <p className={gradeDeeds[deed.nft.uuid % 10].text + " text-sm"}>price :</p>
                              {/* <p className="text-sm">{parseFloat(deed.price).toFixed(8)}</p> */}
                            </div>
                          </div>
                        </div>
                        <div>
                          <p className={" text-black text-right pr-2 py-1.5 font-semibold text-lg cursor-pointer hover:underline underline-offset-2 animate-pulse"}
                            onClick={(e) => { 
                              funcOpenModalDeedSet(e, deed)
                            }}
                          >
                            <span>{parseFloat(deed.price).toFixed(8).split(".")[0]}.</span>
                            <span className="text-xs">{parseFloat(deed.price).toFixed(8).split(".")[1]}</span>
                          </p>
                        </div>
                      </div>
                      
                    </div>

                    {/* <div key={index} className="flex border-2 rounded-md p-1 shadow hover:border-meu-500 cursor-pointer"
                      // onClick={(e) => { funcOpenModal(e, deed) }}
                    >
                      <ul>
                        <li>
                          <img className="w-full h-full hover:translate-x-1 hover:-translate-y-1 delay-50 duration-100" alt="" src={`https://ipfs.io/ipfs/${deed.nft.ipfsHash}`} />
                        </li>
                        <li className="bg-yellow-50 hover:bg-yellow-100 text-meu-600 -m-1 mt-0 rounded-md pr-1.5 text-sm text-right font-semibold">
                          <span className="text-xs">#</span>&nbsp;{parseFloat(deed.price)}
                        </li>
                      </ul>
                    </div> */}
                  </>);
                })}
                {/*  */}
              </div>
                  
            </div>
              


            </>}




            {(tab === "payments") &&
              <div
                className="tab-pane fade"
                id="tabs-payments"
                role="tabpanel"
                aria-labelledby="tabs-payments-tab"
              >
                <div className="bg-white">
                  <div className="px-4 py-5 pt-12 sm:px-6">
                    <h3 className="text-2xl font-bold leading-7 text-gray-600">
                      # My Payments
                    </h3>
                  </div>
                </div>
                
                <div className="overflow-x-auto relative sm:rounded-lg">
                  <div className="flex justify-between items-center pb-4 bg-white ">
                    <select className="rounded py-1 ml-0.5 border-meu-500 text-sm text-gray-500 focus:ring-meu-500 focus:border-meu-500 font-semibold"
                      value={selected} onChange={(e)=>setSelected(e.target.value)}
                    >
                      <option value="proceeds">Proceeds</option>
                      <option value="replicas">Replicas</option>
                      <option value="deeds">Deeds</option>
                    </select>
                    <label htmlFor="table-search" className="sr-only">Search</label>
                    <div className="relative">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg className="w-5 h-5 text-meu-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                      </div>
                      <input type="text" id="table-search-users" className="block p-2 py-1.5 pl-10 w-80 mr-1 mt-1 text-sm rounded border  bg-meu-50 border-meu-500 placeholder-meu-300 text-gray-500 focus:ring-meu-500 focus:border-meu-500" placeholder="Search for tickets" />
                    </div>
                  </div>

                  <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs text-gray-700 uppercase bg-meu-300 ">
                      <tr>
                        <th scope="col" className="p-4">
                          <div className="flex items-center">
                            <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-purple-600 bg-gray-100 rounded border-gray-300 focus:ring-purple-500 " />
                            <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                          </div>
                        </th>
                        <th scope="col" className="py-3 px-6 text-center">Name</th>
                        <th scope="col" className="py-3 px-6 text-center">Price</th>
                        <th scope="col" className="py-3 px-6 text-center">Status</th>
                        <th scope="col" className="py-3 px-6 text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketsReplica.map((ticket, index) => {
                        return (<>
                          { ((selected === "proceeds") && (ticket.ticket.rid !== ticket.ticket.tgtkid)) &&  // Deed를 갖고 있어서 발생한 Replica 판매분..
                            <tr key={ticket.ticket.id} className="bg-white border-b  hover:bg-meu-50 ">
                              <td className="p-4 w-4">
                                <div className="flex items-center">
                                  <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-purple-600 bg-gray-100 rounded border-gray-300 focus:ring-purple-500  focus:ring-2 " />
                                  <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                </div>
                              </td>
                              <th scope="row" className="flex items-center sm:pl-2 md:pl-4 lg:pl-6 py-4 text-gray-900 whitespace-nowrap ">
                                <img className="w-10 h-10 rounded-full" src={srcNFT(ticket.nft.ipfsHash)} alt="Jese image" />
                                <div className="pl-3">
                                  <div className="text-base font-semibold">{ticket.nft.metadata.nameArticle}</div>
                                  <div className="font-normal text-gray-500">
                                    {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, }).format(parseInt(ticket.ticket.metadata.birthday) * 1000)}
                                  </div>
                                </div>
                              </th>
                              <td className="py-4 text-center">
                                <span className="text-base">{ (ticket.ticket.price).split(".")[0] }.</span>
                                <span className="text-sm">{ (ticket.ticket.price).split(".")[1] }</span>
                              </td>
                              <td className="py-4 text-center">
                                <ul>
                                  <li className="flex items-center justify-center">
                                    <span className=" h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></span> Sold
                                  </li>
                                  <li className="flex items-center justify-center">
                                    <span className=" h-2.5 w-2.5 rounded-full bg-amber-400 mr-2"></span> Not yet Paid
                                  </li>
                                </ul>
                              </td>
                              <td className="py-4 text-center">
                                <a className="font-semibold text-purple-600 hover:underline cursor-pointer"
                                  onClick={() => {paid2(ticket.ticket.id)}}
                                >Get Paid</a>
                              </td>
                            </tr>
                          }
                          {/* {((selected === "replicas") && (ticket.ticket.rid === ticket.ticket.tgtkid)) &&  // 추후 관리자 지갑을 변경하게되면, 이걸로 적용해야함.. 초기개발중에 블록체인에 남은 쓰레기때문에.. */}
                          {((selected === "replicas") && (ticket.ticket.rid === ticket.ticket.tgtkid) && (ticket.ticket.rid !== "0")) &&  // 갖고 있던 Replica 를 nft마켓에 올린것들..
                            <tr key={ticket.ticket.id} className="bg-white border-b  hover:bg-meu-50 ">
                              <td className="p-4 w-4">
                                <div className="flex items-center">
                                  <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-purple-600 bg-gray-100 rounded border-gray-300 focus:ring-purple-500  focus:ring-2 " />
                                  <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                </div>
                              </td>
                              <th scope="row" className="flex items-center sm:pl-2 md:pl-4 lg:pl-6 py-4 text-gray-900 whitespace-nowrap ">
                                <img className="w-10 h-10 rounded-full" src={srcNFT(ticket.nft.ipfsHash)} alt="Jese image" />
                                <div className="pl-3">
                                  <div className="text-base font-semibold">{ticket.nft.metadata.nameArticle}</div>
                                  <div className="font-normal text-gray-500">
                                    {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, }).format(parseInt(ticket.ticket.metadata.birthday) * 1000)}
                                  </div>
                                </div>
                              </th>
                              <td className="py-4 text-center">
                                <span className="text-base">{ (ticket.ticket.price).split(".")[0] }.</span>
                                <span className="text-sm">{ (ticket.ticket.price).split(".")[1] }</span>
                              </td>
                              <td className="py-4 text-center">
                                <ul>
                                  <li className="flex items-center justify-center">
                                    {ticket.check !== true  // true 는 팔린상태, false 는 아직팔고있는 상태
                                      ? <><span className=" h-2.5 w-2.5 rounded-full bg-amber-400 mr-2"></span> On Sale..</>
                                      : <><span className=" h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></span> Sold Out</>
                                    }
                                  </li>
                                  <li className="flex items-center justify-center">
                                    {ticket.check === true && // true 는 팔린상태, false 는 아직팔고있는 상태
                                      <><span className=" h-2.5 w-2.5 rounded-full bg-amber-400 mr-2"></span> Not yet Paid</>
                                    }
                                  </li>
                                </ul>
                              </td>
                              <td className="py-4 text-center">
                                {ticket.check !== true  // true 는 팔린상태, false 는 아직팔고있는 상태
                                  ? <a onClick={() => {cancleSaleReplica(ticket.ticket.id, ticket.ticket.rid)}} className="font-semibold text-purple-600 hover:underline cursor-pointer">Cancel the Sale</a>
                                  : <a onClick={() => {paidReplica(ticket.ticket.id, ticket.ticket.rid, ticket.ticket.price)}} className="font-semibold text-purple-600 hover:underline cursor-pointer">Get Paid</a>
                                }
                              </td>
                            </tr>
                          }
                          
                        </>)
                      })}
                      {ticketsDeed.map((ticket, index) => {
                        return (<>
                          {((selected === "deeds") ) &&  // 갖고 있던 Deed 를 nft마켓에 올린것들..
                            <tr key={ticket.ticket.id} className="bg-white border-b  hover:bg-meu-50 ">
                              <td className="p-4 w-4">
                                <div className="flex items-center">
                                  <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-purple-600 bg-gray-100 rounded border-gray-300 focus:ring-purple-500  focus:ring-2 " />
                                  <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                </div>
                              </td>
                              <th scope="row" className="flex items-center sm:pl-2 md:pl-4 lg:pl-6 py-4 text-gray-900 whitespace-nowrap ">
                                <img className="w-10 h-10 rounded-full" src={srcNFT(ticket.nft.ipfsHash)} alt="Jese image" />
                                <div className="pl-3">
                                  <div className="text-base font-semibold">{ticket.nft.metadata.nameArticle}</div>
                                  <div className="font-normal text-gray-500">
                                    {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, }).format(parseInt(ticket.ticket.metadata.birthday) * 1000)}
                                  </div>
                                </div>
                              </th>
                              <td className="py-4 text-center">
                                <span className="text-base">{ (ticket.ticket.price).split(".")[0] }.</span>
                                <span className="text-sm">{ (ticket.ticket.price).split(".")[1] }</span>
                              </td>
                              <td className="py-4 text-center">
                                <ul>
                                  <li className="flex items-center justify-center">
                                    {ticket.check !== true  // true 는 팔린상태, false 는 아직팔고있는 상태
                                      ? <><span className=" h-2.5 w-2.5 rounded-full bg-amber-400 mr-2"></span> On Sale..</>
                                      : <><span className=" h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></span> Sold Out</>
                                    }
                                  </li>
                                  <li className="flex items-center justify-center">
                                    {ticket.check === true && // true 는 팔린상태, false 는 아직팔고있는 상태
                                      <><span className=" h-2.5 w-2.5 rounded-full bg-amber-400 mr-2"></span> Not yet Paid</>
                                    }
                                  </li>
                                </ul>
                              </td>
                              <td className="py-4 text-center">
                                {ticket.check !== true  // true 는 팔린상태, false 는 아직팔고있는 상태
                                  ? <a onClick={() => {cancleSaleDeed(ticket.ticket.id, ticket.ticket.did)}} className="font-semibold text-purple-600 hover:underline cursor-pointer">Cancel the Sale</a>
                                  : <a onClick={() => {paidDeed(ticket.ticket.id, ticket.ticket.cid, ticket.ticket.did, ticket.ticket.price)}} className="font-semibold text-purple-600 hover:underline cursor-pointer">Get Paid</a>
                                }
                              </td>
                            </tr>
                          }
                        </>)
                      })}
                    </tbody>
                  </table>
                </div>
                
                  
              </div>
            }

            
          </div>




















          
        </div>

        {/* 모달창 뒷배경 */}
        <div className={classNames(isOpenModal || isOpenModalDeed ? "block" : "hidden", "fixed inset-0 bg-gray-800 opacity-50 w-full h-full")}
          onClick={() => {
            funcCloseModal()
            funcCloseModalDeed()
          }}
        ></div>
        {/* Modal */}
        <div className={classNames(isOpenModal || isOpenModalDeed ? "" : "hidden", "")}>
          {/* Deed 모달화면 */}
          <div className={classNames(isOpenModalDeed ? "" : "hidden", "")}>
            <div className="shadow-md flex flex-col items-center gap-2 -translate-y-1/2 bg-white rounded-xl top-1/2 left-1/2 -translate-x-1/2 fixed ">
              <div className="container">
                <div className="bg-meu-500 w-full rounded-t-lg h-3 p-0 m-0 absolute"></div>
                <div className="w-96 max-w-xs sm:w-full sm:max-w-sm md:max-w-md lg:max-w-md bg-gray-100 shadow-lg rounded-xl px-6 py-7 pt-4">
                  <div className="mb-4 mt-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Sale Price of <span className={classNames(nameModalDeed==="Deed" ? "text-rose-500" : "text-meu-600")}>"{nameModalDeed}"</span></label>
                    <input type="number" className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-meu-500 focus:border-meu-500 font-semibold"
                      value={priceModalDeed} min={0}
                      onChange={(e) => { setPriceModalDeed(e.target.value) }}
                      onBlur={(e) => {
                        const valTarget = e.target.value * 1
                        const valMin = e.target.min * 1
                        const defaultValue = 0.00000001
                        if (valTarget <= valMin) e.target.value = defaultValue
                        else if (isNaN(Number(e.target.value)) || !e.target.value) e.target.value = defaultValue
                        setPriceModalDeed(parseFloat(e.target.value).toFixed(8))
                        // console.log(priceModalDeed)
                      }}
                    />
                  </div>
                  <div className="flex px-2 pt-2 text-semibold">
                    <button className="w-1/2 bg-rose-400 hover:bg-rose-500 mr-2 py-1 text-white rounded"
                      onClick={() => {
                        if (nameModalDeed === "MyReplica") {
                          setIsOpenModalDeed(false)
                          setIsOpenModal(true)
                        } else {
                          funcCloseModal()
                          funcCloseModalDeed()  
                        }
                      }}
                    >
                      Cancel
                    </button>
                    {nameModalDeed === "Deed" && 
                      <button className="w-1/2 bg-meu-600 hover:bg-meu-700 ml-2 py-1 text-white rounded"
                        onClick={() => {
                          console.log(priceModalDeed)
                          deedListForSale(didModalDeed, cidModalDeed, priceModalDeed)
                        }}
                      >
                      Lift Up
                    </button>  
                    }
                    {nameModalDeed === "Replica" && 
                      <button className="w-1/2 bg-meu-600 hover:bg-meu-700 ml-2 py-1 text-white rounded"
                        onClick={() => {
                          console.log(priceModalDeed)
                          setPriceOfDeed(cidModalDeed, priceModalDeed)
                        }}
                      >
                      Set
                    </button>  
                    }
                    {nameModalDeed === "MyReplica" && 
                      <button className="w-1/2 bg-meu-600 hover:bg-meu-700 ml-2 py-1 text-white rounded"
                        onClick={() => {
                          console.log(priceModalDeed)
                          replicaListForSale(cardId, cardCid, cardNid, priceModalDeed)
                        }}
                      >
                      Lift Up
                    </button>  
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 레플리카 모달화면 */}
          <div className={classNames(isOpenModal ? "" : "hidden", "")}>
            {/* <div className=" shadow-md sm:w-[385px] sm:min-w-[40vw] min-w-[80vw] min-h-[50vh] flex flex-col items-center gap-2 -translate-y-1/2 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 absolute">
              <div className="bg-meu-500 w-full rounded-t-lg h-3 p-0 m-0"></div>
              <span className="text-2xl font-medium">Payment Successful</span>
              <p className="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus, consequatur?</p>
              <button id="modal-close" className="p-3 bg-[#4F46E5] rounded-lg w-full text-white">Click Background</button>
              <div className="bg-meu-500 w-full rounded-b-lg h-3 p-0 m-0 absolute bottom-0 "></div>
            </div> */}

            {/* <div className="relative min-h-screen flex flex-col items-center justify-center shadow-md"> */}
            <div className="shadow-md flex flex-col items-center gap-2 -translate-y-1/2 bg-white rounded-xl top-1/2 left-1/2 -translate-x-1/2 fixed ">
              <div className="container">
                <div className="bg-meu-500 w-full rounded-t-lg h-3 p-0 m-0 absolute"></div>
                <div className="bg-meu-500 w-full rounded-b-lg h-3 p-0 m-0 absolute bottom-0 "></div>
                {/* <div className="max-w-xs sm:max-w-sm lg:max-w-md w-full bg-gray-800 shadow-lg rounded-xl px-6 py-7"> */}
                <div className="w-96 max-w-xs sm:w-full sm:max-w-sm md:max-w-md lg:max-w-md bg-gray-800 shadow-lg rounded-xl px-6 py-7 pt-4">
                  {/* 좋아요, 댓글, 다운로드수 */}
                  <div className="py-2 px-4 text-center tracking-wide grid grid-cols-3 gap-6">
                    <div className="flex tools justify-center cursor-pointer">
                        <p className="flex text-amber-400 text-base justify-center">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                          </svg> */}
                          <icoo.HeartIcon className="h-5 w-5"/>
                        </p>
                        <p className="text-base text-amber-50 px-2">324</p>
                    
                    </div>
                    <div className="flex followers justify-center cursor-pointer">
                        <p className="flex text-amber-400 text-base justify-center">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                          </svg> */}
                          <icoo.ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5"/>
                        </p>
                        <p className="text-base text-amber-50 px-2">7542</p>
                      
                    </div>
                    <div className="flex following justify-center cursor-pointer">
                        <p className="flex text-amber-400 text-base justify-center">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                          </svg> */}
                        <icoo.ArrowDownTrayIcon className="h-5 w-5"/>
                        </p>
                        <p className="text-base text-amber-50 px-2">295</p>
                    
                    </div>
                  </div>
                  {/* 카드내용 */}
                  <div className="flex flex-col ">
                    <div className="">
                      <div className="relative w-full mb-3">
                        <div className="absolute flex flex-col top-0 right-0 p-3">
                          <button className="bg-gray-800  hover:text-meu-500 shadow hover:shadow-md text-white rounded-full w-8 h-8 text-center p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                            </svg>
                            {/* <BookmarkIcon className="ml-0.5 h-5 w-5"/> */}
                          </button>
                        </div>
                          <img src={cardImage} className="w-full object-fill rounded-2xl bg-gray-100"></img>
                      </div>
                      <div className="flex-auto justify-evenly">
                        <div className="flex flex-wrap ">
                          {/* <div className="w-full flex-none text-sm flex items-center text-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-red-500 mr-1" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <span className="text-gray-400 whitespace-nowrap mr-3">4.60</span><span className="mr-2 text-gray-400">India</span>
                          </div> */}
                          <div className="flex items-center w-full justify-between min-w-0 ">
                            <h2 className="text-3xl font-semibold mr-auto cursor-pointer text-purple-500 hover:text-purple-600 truncate pt-0.5"
                              onClick={() => { 
                                openItemByCid(cardCid)
                              }}
                            >
                              {cardTitle}
                            </h2>
                            <div className="flex cursor-pointer items-center text-amber-500 text-base font-semibold px-1.5 py-0.5 ml-3 rounded-lg">
                              #&nbsp;{cardNumber}</div>
                          </div>
                        </div>
                        <div className={classNames(cardContainer==="replica" ? "bg-gray-600 text-white" : "bg-gray-50 text-gray-900", "my-4 p-3 rounded-md text-md font-semibold")}>
                          {cardContainer === "replica" && 
                            <ul>
                              <li className="text-xs text-purple-400">[ Title ]</li>
                              <li className="text-lg ml-4 text-gray-300">{cardTitle}</li>
                              <li className="text-xs text-purple-400">[ Contents ] </li>
                              <li className="text-lg ml-4 text-gray-300">{cardDesc}</li>
                              
                              <li className="text-xs text-purple-400 pt-4">[ Description ] </li>
                              <li className="flex text-gray-300 text-xs sm:text-sm">
                                <div className="w-16 text-right ml-3">Birthday : </div>
                                <div className="ml-4">{cardBirthday && new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12:false, }).format(parseInt(cardBirthday) * 1000)}</div>
                              </li>
                              <li className="flex text-gray-300 text-xs sm:text-sm">
                                <div className="w-16 text-right ml-3">Mintday : </div>
                                <div className="ml-4">{cardMintday && new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12:false, }).format(parseInt(cardMintday) * 1000)}</div>
                              </li>
                              <li className="flex text-gray-300 text-xs sm:text-sm">
                                <div className="w-16 text-right ml-3">Author : </div>
                                <div className="ml-4">{cardAuthorName}({cardAuthorAddr})</div>
                              </li>
                              <li className="flex text-gray-300 text-xs sm:text-sm">
                                <div className="w-16 text-right ml-3">Minter : </div>
                                <div className="ml-4">{cardMinterAddr}</div>
                              </li>
                            </ul>
                          }
                          {cardContainer === "graph" && 
                            <Chart
                              type="area"
                              series={[
                                { name: "Replicas", data: funcRandomIntArray(0,500,7,false) },
                                { name: "Price", data: funcRandomIntArray(30,200,7,true) },
                              ]}
                              options={{
                                // theme: { mode: "dark", },
                                chart: {
                                  type: "area",
                                  height: "auto",
                                  width: "100%",
                                  toolbar: { show: false, },
                                  background: "transparent",
                                },
                                stroke: { curve: "smooth", width: 2, },
                                dataLabels: { enabled: false, },
                                // xaxis: {
                                //   type: 'datetime',
                                //   categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                                // },
                                xaxis: {
                                  type: 'category',
                                  categories: ["1 Years ago", "6 Month ago", "1 Months ago", "2 Weeks ago", "1 Weeks ago", "Yesterday", "Today"],
                                  labels: {
                                    show: false,
                                  },
                                },
                                yaxis: { show: false, },
                                plotOptions: {
                                  area: {
                                    fillTo: 'origin',
                                  },
                                },
                                // tooltip: {
                                //   x: {
                                //     format: 'dd/MM/yy HH:mm',
                                //   },
                                // },
                              }}
                            />
                          }
                        </div>
                        <div className="text-xl text-white font-semibold mt-1 text-right">{cardPrice && parseFloat(cardPrice)} <span className="text-sm text-meu-500">FLOW</span></div>
                        
                        
                        {/* <div className="lg:flex  py-4  text-sm text-gray-600">
                          <div className="flex-1 inline-flex items-center  mb-3">
                            <div className="w-full flex-none text-sm flex items-center text-gray-600">
                              <ul className="flex flex-row justify-center items-center space-x-2">
                                <li className="">
                                  <span className="block p-1 border-2 border-gray-900 hover:border-blue-600 rounded-full transition ease-in duration-300">
                                    <a href="#blue" className="block w-3 h-3 bg-blue-600 rounded-full"></a>
                                  </span>
                                </li>
                                <li className="">
                                  <span className="block p-1 border-2 border-gray-900 hover:border-yellow-400 rounded-full transition ease-in duration-300">
                                    <a href="#yellow" className="block w-3 h-3  bg-yellow-400 rounded-full"></a>
                                  </span>
                                </li>
                                <li className="">
                                  <span className="block p-1 border-2 border-gray-900 hover:border-red-500 rounded-full transition ease-in duration-300">
                                    <a href="#red" className="block w-3 h-3  bg-red-500 rounded-full"></a>
                                  </span>
                                </li>
                                <li className="">
                                  <span className="block p-1 border-2 border-gray-900 hover:border-green-500 rounded-full transition ease-in duration-300">
                                    <a href="#green" className="block w-3 h-3  bg-green-500 rounded-full"></a>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="flex-1 inline-flex items-center mb-3">
                            <span className="text-secondary whitespace-nowrap mr-3">Size</span>
                            <div className="cursor-pointer text-gray-400 ">
                              <span className="hover:text-purple-500 p-1 py-0">S</span>
                              <span className="hover:text-purple-500 p-1 py-0">M</span>
                              <span className="hover:text-purple-500 p-1 py-0">L</span>
                              <span className="hover:text-purple-500 p-1 py-0">XL</span>
                            </div>
                          </div>
                        </div> */}
                        <div className="flex space-x-2 text-sm font-medium mt-4">
                          <div className="w-1/2">
                            <button className="text-sm font-semibold mb-2 md:mb-0 bg-purple-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded hover:bg-purple-600"
                              onClick={()=>{setCardContainer("replica")}}
                            >
                              <span>Replica</span>
                            </button>
                          </div>
                          <div className="flex justify-end w-1/2">
                            <button className="bg-amber-500 hover:bg-amber-600 text-white rounded-full w-9 h-9 text-center p-2 font-semibold"
                              onClick={()=>{setCardContainer("graph")}}
                            >
                              <icos.PresentationChartLineIcon className="h-5 w-5"/>
                            </button>
                            <button className="ml-2 text-sm font-semibold mb-2 md:mb-0 bg-amber-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded hover:bg-amber-600 "
                              onClick={() => {
                                funcOpenModalReplicaSell()
                                
                              }}>
                              <span>Sell</span>
                            </button>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>

          </div>
        </div>


      </>
    );
}

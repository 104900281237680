export const meuReplicaListForSaleTx = `
//Official
import FlowToken from 0xFLOW
import FungibleToken from 0xFT
import NonFungibleToken from 0xNFT
// MEU
import ReplicaNFT from 0xMEU
import ReplicaMarket from 0xMEU
import ReplicaMarketTicket from 0xMEU

transaction(id: UInt64, cid: UInt64, nid: UInt64, price: UFix64) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthUser: AuthAccount, acctAuthAdmin: AuthAccount) {

    // [1] (판매자==사용자)계정으로, 레플리카NFT, borrow..
    // 레플리카NFT 출금..
    let cltnBorReplicaUser = acctAuthUser.borrow<&ReplicaNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Replica)!
    let replicaOfUser <- cltnBorReplicaUser.withdraw(withdrawID: id)
    
    // [2] 관리자주소로, 레플리카NFT, collection..
    // 레플리카NFT 입금..
    let cltnCapReplicaAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Emojia_Replica)
                                .borrow<&ReplicaNFT.Collection{NonFungibleToken.CollectionPublic, ReplicaNFT.CollectionPublic}>()
                                ?? panic("Can't get the Admin's Replica's collection.")
    cltnCapReplicaAdmin.deposit(token: <- replicaOfUser)

    // [3] 관리자계정으로, 레플리카마켓, borrow..
    // 판매내역 올리기..
    let cltnBorMkReplicaAdmin = acctAuthAdmin.borrow<&ReplicaMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Replica)!
    let trid = ReplicaMarketTicket.totalSupply
    cltnBorMkReplicaAdmin.listForSale(id: trid, rid: id, price: price, seller: acctAuthUser.address)

    // [4] 레플리카티켓 생성.. (원래는.. 관리자계정에서 생성해야함.., 레플리카티켓, borrow..)
    let tkReplicaOfAdmin <- ReplicaMarketTicket.createToken(
      cid: cid, 
      rid: id, 
      nid: nid, 
      tgtkid: id,
      price: price,
      metadata: {
        "birthday": (getCurrentBlock().timestamp).toString(),
        "soldday": "",
        "paidday": ""
      }, metadataAny: {
      }
    )

    // [5] (판매자==사용자)주소로, 레플리카티켓, collection..
    // 레플리카티켓 입금..
    let cltnCapTkReplicaUser = getAccount(acctAuthUser.address).getCapability(/public/MetaUzuCollection_Emojia_Ticket_Replica)
                                .borrow<&ReplicaMarketTicket.Collection{NonFungibleToken.CollectionPublic, ReplicaMarketTicket.CollectionPublic}>()
                                ?? panic("Can't get the User's tkReplica's collection.")
    cltnCapTkReplicaUser.deposit(token: <- tkReplicaOfAdmin)

    // [5] 까지 완료하면.. [6] 현재 상태..
    // 판매자는 레플리카티켓을 보유한 상태..
    // 관리자는 레플리카를 보유한 상태..
    // 관리자 레플리카마켓에 레플리카의 rid 를 기준으로 판매상품이 올라가져있는 상태..
    // 가 된다..

  }
  execute {
    log("A user listed an NFT for Sale")
  }
}
`
import * as fcl from '@onflow/fcl';
import * as types from '@onflow/types';
import { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Link, NavLink, useNavigate } from 'react-router-dom';
import { Context } from '../Context';

// 아이콘 써드파티 컴포넌트 삽입
import * as icos from '@heroicons/react/24/solid'; // heroicon solid
import * as icoo from '@heroicons/react/24/outline'; // heroicon outline
import * as icom from '@heroicons/react/20/solid'; // heroicon mini (solid)

export function Header() {
  // const [user, setUser] = useState(null);
  const { user, setUser } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    // sets the `user` variable to the person that is logged in through Blocto
    fcl.currentUser().subscribe(setUser);
  }, []);

  const logIn = () => {
    // log in through Blocto
    fcl.authenticate();
  };

  const logOut = () => {
    // log in through Blocto

    fcl.unauthenticate();
    setUser(null);
    navigate('/');
  };
  return (
    <>
      {/* <!-- component --> */}
      <nav className="bg-white flex flex-col md:flex-row items-center md:justify-between px-6 py-4 border-b border-b-gray-60 max-w-screen-2xl mx-auto">
        {/* <!-- Logo --> */}
        <div>
          {/* <h2 className="text-3xl font-bold">
            <a>
              Meta <span className="text-blue-600">u</span>zu
            </a>
          </h2> */}
          <span className="sr-only">MetaUzu</span>
          <img
            className="block lg:hidden h-10 w-auto sm:h-11"
            src={`${process.env.PUBLIC_URL}/assets/LogoMetaUzu.png`}
            alt="MetaUzu"
          />
          <img
            className="hidden lg:block h-10 w-auto sm:h-11 cursor-pointer"
            src={`${process.env.PUBLIC_URL}/assets/LogoPlusTextMetaUzu.png`}
            alt="MetaUzu"
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
        {/* <!-- /End Logo --> */}
        <div className="mt-5 md:mt-0">
          <ul className="flex flex-col md:flex-row md:space-x-5 w-full items-center">
            <li>
              <NavLink
                to="/"
                className="text-black font-semibold tracking-tight block cursor-pointer p-2 hover:text-meu-500 transition-colors duration-75 hover:underline underline-offset-4"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/marketplace"
                className="text-black font-semibold tracking-tight block cursor-pointer p-2 hover:text-meu-500 transition-colors duration-75 hover:underline underline-offset-4"
              >
                Marketplace
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/channel"
                className="text-black font-semibold tracking-tight block cursor-pointer p-2 hover:text-meu-500 transition-colors duration-75 hover:underline underline-offset-4"
              >
                Channle
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/atelier"
                className="text-black font-semibold tracking-tight block cursor-pointer p-2 hover:text-meu-500 transition-colors duration-75 hover:underline underline-offset-4"
              >
                Atelier
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/mywallet"
                className="text-black font-semibold tracking-tight cursor-pointer p-2  hover:text-meu-500   duration-75 flex items-center hover:underline underline-offset-4"
              >
                My Wallet
                {/* <icos.WalletIcon className="h-5 w-5 pr-0.5" />Wallet */}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/check"
                className="text-black font-semibold tracking-tight cursor-pointer p-2 pl-0 hover:text-meu-500 transition-colors duration-75 flex items-center hover:underline underline-offset-4"
              >
                {/* Check */}
                <icos.ShieldCheckIcon className="h-5 w-5" />
              </NavLink>
            </li>

            {/* Old Example Menu Tab.. 초기개발간에 필요했던 기능들 리스트 모음탭.. */}
            {/* {user?.addr === "0x7d4f6586d2f3e790" && // 현재로서 테스트계정으로 사용하고 있음.. >> meutest004@mailinator.com >> 2022.08.06
              <li onClick={()=>{console.log(user?.addr)}}>
                <NavLink to="/example" className="font-semibold tracking-tight block cursor-pointer p-2 hover:text-meu-500 transition-colors duration-75">
                  Old
                </NavLink>
              </li>
            } */}

            <li>
              {user?.addr ? (
                <button
                  className="inline-block text-sm px-4 py-2 leading-none border rounded text-black border-black hover:border-transparent hover:text-meu-500 hover:bg-black mt-4 lg:mt-0"
                  onClick={logOut}
                >
                  Sign Out
                </button>
              ) : (
                <button
                  className="inline-block text-sm px-4 py-2 leading-none border rounded text-black border-black hover:border-transparent hover:text-meu-500 hover:bg-black mt-4 lg:mt-0"
                  onClick={logIn}
                >
                  Sign In
                </button>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

// cdc 작성하는 기본 문장 예시.. >> https://docs.onflow.org/cadence/language/transactions/#post-phase
export const meuMintCentralDeedNFT = `
// Official
import FlowToken from 0xFLOW
import FungibleToken from 0xFT
import NonFungibleToken from 0xNFT
// MEU
import CentralNFT from 0xMEU
import DeedNFT from 0xMEU
import DeedMarket from 0xMEU

transaction(ipfsHash: String, nameArticle: String, nameAuthor: String, description: String, price:UFix64) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthUser: AuthAccount, acctAuthAdmin: AuthAccount) {

    // 관리자 Central 에 원본 NFT 민팅..
    let cltnBorCentralAdmin = acctAuthAdmin.borrow<&CentralNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Central)!
    let centralOfAdmin <- CentralNFT.createToken(did: DeedNFT.totalSupply, price: price, ipfsHash: ipfsHash, addrOwner: acctAuthUser.address, metadata: {
      "nameArticle":nameArticle, 
      "nameAuthor":nameAuthor, 
      "description":description,
      "birthday": (getCurrentBlock().timestamp).toString(),
      "addrAuthor": (acctAuthUser.address).toString()
    }, metadataAny: {
    } )
    let cid = centralOfAdmin.id
    cltnBorCentralAdmin.deposit(token: <- centralOfAdmin)

    // 사용자 Deed 에 관리자 Central에서 민팅된 NFT 원본에 대한 증서 민팅..
    let cltnBorDeedUser = acctAuthUser.borrow<&DeedNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Deed)!
    let deedOfUser <- DeedNFT.createToken(cid: cid, ipfsHash: ipfsHash, metadata: {}, metadataAny: {} )
    cltnBorDeedUser.deposit(token: <- deedOfUser)

  }

  execute {
    log("A user minted an NFT into their account")
  }
  
}
`
export const deedListForSaleTx = `
// Official
import FlowToken from 0xFLOW
import FungibleToken from 0xFT
import NonFungibleToken from 0xNFT
// MEU
import CentralNFT from 0xMEU
import DeedNFT from 0xMEU
import DeedMarket from 0xMEU
import DeedMarketTicket from 0xMEU

transaction(id: UInt64, cid:UInt64, price: UFix64) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthUser: AuthAccount, acctAuthAdmin: AuthAccount) {

    // (사용자==판매자)의 deed 조회해오기
    let cltnCapDeedUser = getAccount(acctAuthUser.address).getCapability(/public/MetaUzuCollection_Emojia_Deed)
                            .borrow<&DeedNFT.Collection{NonFungibleToken.CollectionPublic, DeedNFT.CollectionPublic}>()
                            ?? panic("Can't get the User's Deed's collection.")
    let deedOfUser = cltnCapDeedUser.borrowEntireNFT(id: id)
    
    // 관리자의 central 조회해오기
    let cltnCapCentralAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Emojia_Central)
                                .borrow<&CentralNFT.Collection{NonFungibleToken.CollectionPublic, CentralNFT.CollectionPublic}>()
                                ?? panic("Can't get the Admin's Central's collection.")
    let centralOfAdmin = cltnCapCentralAdmin.borrowEntireNFT(id: cid)

    // 양쪽 id 데이터가 서로 바라보는 것이 같다면..
    if( deedOfUser?.id == centralOfAdmin?.did && centralOfAdmin?.id == deedOfUser?.cid ){
      // 담아야 하는 관리자의 deed 콜렉션 가져오기
      let cltnCapDeedAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Emojia_Deed) 
                              .borrow<&DeedNFT.Collection{NonFungibleToken.CollectionPublic}>()
                              ?? panic("Can't get the Admin's Deed's collection.")
      
      // 관리자에게 deed 넘겨주기
      let cltnBorDeedUser = acctAuthUser.borrow<&DeedNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Deed)!
      cltnCapDeedAdmin.deposit(token: <- cltnBorDeedUser.withdraw(withdrawID: id))

      // 관리자의 마켓에 해당상품(deed)정보 올리기
      let cltnBorMkDeedAdmin = acctAuthAdmin.borrow<&DeedMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Deed)
                                ?? panic("This Admin's mkDeed's SaleCollection does not exist")
      let tdid = DeedMarketTicket.totalSupply
      cltnBorMkDeedAdmin.listForSale(id: tdid, did: id, price: price, seller: acctAuthUser.address)
      
      // 사용자 계정에 마켓콜렉션에 올린것과 id 번호 맞춰서 티켓 생성하여 넣어주기  (원래는 관리자계정에서 티켓을 생성해야함..)
      let cltnBorTkDeedUser = acctAuthUser.borrow<&DeedMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Deed)!
      let tkDeedOfAdmin <- DeedMarketTicket.createToken(cid: cid, did: id, price: price, metadata: {
        "birthday": (getCurrentBlock().timestamp).toString(),
        "soldday": "",
        "paidday": ""
      }, metadataAny: {
      })
      cltnBorTkDeedUser.deposit(token: <- tkDeedOfAdmin)

    }
  }
  execute {
    log("A user listed an NFT for Sale")
  }
}
`
import { useState } from 'react';

export function Blinder() {

  const [blinder, setBlinder] = useState(true)
  const answerNames = [
    'pakt87', 'dinoksb', 'teddy', 'Teddy', 'T3d.Kang', 't3d.kang', 'ted', 'Ted', 'T3d', 't3d'
  ]

  const funcBlinder = () => {
    // console.log("funcBlinder clicked..")
    let input = prompt(`Welcome! Nice to meet you.\nWhat's your name?`, 'YourName')
    console.log(input)
		if(answerNames.includes(input)) {
			setBlinder(false)
		}else{
      setBlinder(true)
      alert(`Hello, ${input} !\nPlease look forward to it. Thank You :)`)
		}
  }

  return (<>
    {
      blinder &&
      <div className="absolute z-50 h-full w-full bg-white">
        <div className="sticky top-0 pt-10 w-full flex flex-col bg-white justify-items-center items-center">
          <img className="w-[320px] rounded-full" src={`${process.env.PUBLIC_URL}/assets/images/3800_2_03.jpg`} />
          <div className="py-12 font-black text-center text-black ">
            <p className="pb-2 text-6xl text-violet-500">LAUNCHING</p>
            <p className="text-8xl text-gray-700 underline underline-offset-8 decoration-wavy decoration-8 decoration-meu-500/50">SOON</p>
          </div>
          <div className="mt-8 py-1 px-2 bg-meu-500 text-white text-sm rounded-lg font-semibold">
            Please look forward to it.
            <span onClick={()=>{funcBlinder()}}>@MetaUzu</span>
          </div>
        </div>
      </div>
    }
    
  </>)
}
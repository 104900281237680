export const meuGetNFTsTicketReplicaScript = `
// Official
import NonFungibleToken from 0xNFT
// MEU
import CentralNFT from 0xMEU
import ReplicaMarket from 0xMEU
import ReplicaMarketTicket from 0xMEU

pub struct TempStruct {
  pub let ticket: &ReplicaMarketTicket.NFT?
  pub let nft: &CentralNFT.NFT?
  pub let check: Bool?
  
  init(
        ticket: &ReplicaMarketTicket.NFT?,
        nft: &CentralNFT.NFT?,
        check: Bool?,
    ) {
        self.ticket = ticket
        self.nft = nft
        self.check = check
    }
}

// pub fun main(acctAddrUser: Address): [&ReplicaMarketTicket.NFT?] {
pub fun main(acctAddrUser: Address, acctAddrAdmin: Address): [AnyStruct] {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  // 센트럴 nft 관련
  let cltnCapCentralAdmin = getAccount(acctAddrAdmin).getCapability(/public/MetaUzuCollection_Emojia_Central)
                                .borrow<&CentralNFT.Collection{NonFungibleToken.CollectionPublic, CentralNFT.CollectionPublic}>()
                                ?? panic("Can't get the Admin's Central's collection.")

  // 티켓 nft 관련
  let cltnCapTkReplicaUser = getAccount(acctAddrUser).getCapability(/public/MetaUzuCollection_Emojia_Ticket_Replica)
                              .borrow<&ReplicaMarketTicket.Collection{NonFungibleToken.CollectionPublic, ReplicaMarketTicket.CollectionPublic}>()
                              ?? panic("Can't get the User's tkReplica's collection.")
  // 레플리카 마켓 관련
  let cltnCapMkReplicaAdmin = getAccount(acctAddrAdmin).getCapability(/public/MetaUzuCollection_Market_Replica)
                                .borrow<&ReplicaMarket.SaleCollection{ReplicaMarket.SaleCollectionPublic}>()
                                ?? panic("Can't get the Admin's mkReplica's collection.")

  // let returnVals: [&ReplicaMarketTicket.NFT?] = []
  let returnVals: [TempStruct] = []            // 최종 반환값을 빈 배열로 생성
  
  let ids = cltnCapTkReplicaUser.getIDs()                     // 반환형태 : &ReplicaMarketTicket.NFT?
  let checks = cltnCapMkReplicaAdmin.checkState(keys: ids)    // 반환형태 : {UInt64: Bool}  >> UInt64 부분이 ids 부분과 매칭되는 부분

  for id in ids {
    // returnVals.append(cltnCapTkReplicaUser.borrowEntireNFT(id: id))
    
    let ticket = cltnCapTkReplicaUser.borrowEntireNFT(id: id)

    returnVals.append(TempStruct(
      // price: central?.price,
      ticket: ticket,
      nft: cltnCapCentralAdmin.borrowEntireNFT(id: (ticket?.cid)! ),
      check: checks[id]
    ))
  }

  return returnVals
}
`
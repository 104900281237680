export const meuGetNFTsReplicaScript = `
// Official
import NonFungibleToken from 0xNFT
// MEU
import ReplicaNFT from 0xMEU

pub fun main(acctAddrUser: Address): [&ReplicaNFT.NFT?] {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  let cltnCapReplicaUser = getAccount(acctAddrUser).getCapability(/public/MetaUzuCollection_Emojia_Replica)
                            .borrow<&ReplicaNFT.Collection{NonFungibleToken.CollectionPublic, ReplicaNFT.CollectionPublic}>()
                            ?? panic("Can't get the User's Replica's collection.")

  let returnVals: [&ReplicaNFT.NFT?] = []      // 최종 반환값을 빈 배열로 생성
  let ids = cltnCapReplicaUser.getIDs()             // replica id 값들 가져옴..

  for id in ids {
    let replica = cltnCapReplicaUser.borrowEntireNFT(id: id)          // replica 가져옴..
    returnVals.append(replica)
  }

  return returnVals
}
`
export const meuDeleteAllCollectionTx = `
// MEU
import CentralNFT from 0xMEU
import DeedNFT from 0xMEU
import DeedMarket from 0xMEU
import DeedMarketTicket from 0xMEU
import ReplicaNFT from 0xMEU
import ReplicaMarket from 0xMEU
import ReplicaMarketTicket from 0xMEU

transaction() {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthAnyone: AuthAccount) {
    
    // MEU _ CentralNFT		Collection	/storage/MetaUzuCollection_Emojia_Central
    if acctAuthAnyone.borrow<&CentralNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Central) != nil {
      let cltnBorCentral <- acctAuthAnyone.load<@CentralNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Central)
                              ?? panic("Could not borrow collection reference: @CentralNFT.Collection")
      destroy cltnBorCentral
      acctAuthAnyone.unlink(/public/MetaUzuCollection_Emojia_Central)
      acctAuthAnyone.unlink(/private/MetaUzuCollection_Emojia_Central)
    }

    // MEU _ DeedNFT			Collection	/storage/MetaUzuCollection_Emojia_Deed	
    if acctAuthAnyone.borrow<&DeedNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Deed) != nil {
      let cltnBorDeed <- acctAuthAnyone.load<@DeedNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Deed)
                          ?? panic("Could not borrow collection reference: @DeedNFT.Collection")
      destroy cltnBorDeed
      acctAuthAnyone.unlink(/public/MetaUzuCollection_Emojia_Deed)
      acctAuthAnyone.unlink(/private/MetaUzuCollection_Emojia_Deed)
    }

    // MEU _ DeedMarket		SaleCollection	/storage/MetaUzuCollection_Market_Deed
    if acctAuthAnyone.borrow<&DeedMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Deed) != nil {
      let cltnBorMkDeed <- acctAuthAnyone.load<@DeedMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Deed)
                            ?? panic("Could not borrow collection reference: @DeedMarket.SaleCollection")
      destroy cltnBorMkDeed
      acctAuthAnyone.unlink(/public/MetaUzuCollection_Market_Deed)
    }

    // MEU _ DeedMarketTicket		Collection	/storage/MetaUzuCollection_Emojia_Ticket_Deed   
    if acctAuthAnyone.borrow<&DeedMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Deed) != nil {
      let cltnBorTkDeed <- acctAuthAnyone.load<@DeedMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Deed)
                            ?? panic("Could not borrow collection reference: @DeedMarketTicket.Collection")
      destroy cltnBorTkDeed
      acctAuthAnyone.unlink(/public/MetaUzuCollection_Emojia_Ticket_Deed)
      acctAuthAnyone.unlink(/private/MetaUzuCollection_Emojia_Ticket_Deed)
    }

    // MEU _ ReplicaNFT			Collection	/storage/MetaUzuCollection_Emojia_Replica	
    if acctAuthAnyone.borrow<&ReplicaNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Replica) != nil {
      let cltnBorReplica <- acctAuthAnyone.load<@ReplicaNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Replica)
                              ?? panic("Could not borrow collection reference: @ReplicaNFT.Collection")
      destroy cltnBorReplica
      acctAuthAnyone.unlink(/public/MetaUzuCollection_Emojia_Replica)
      acctAuthAnyone.unlink(/private/MetaUzuCollection_Emojia_Replica)
    }

    // MEU _ ReplicaMarket		SaleCollection	/storage/MetaUzuCollection_Market_Replica
    if acctAuthAnyone.borrow<&ReplicaMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Replica) != nil {
      let cltnBorMkReplica <- acctAuthAnyone.load<@ReplicaMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Replica)
                                ?? panic("Could not borrow collection reference: @ReplicaMarket.SaleCollection")
      destroy cltnBorMkReplica
      acctAuthAnyone.unlink(/public/MetaUzuCollection_Market_Replica)
    }

    // MEU _ ReplicaMarketTicket		Collection	/storage/MetaUzuCollection_Emojia_Ticket_Replica   
    if acctAuthAnyone.borrow<&ReplicaMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Replica) != nil {
      let cltnBorTkReplica <- acctAuthAnyone.load<@ReplicaMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Replica)
                                ?? panic("Could not borrow collection reference: @ReplicaMarketTicket.Collection")
      destroy cltnBorTkReplica
      acctAuthAnyone.unlink(/public/MetaUzuCollection_Emojia_Ticket_Replica)
      acctAuthAnyone.unlink(/private/MetaUzuCollection_Emojia_Ticket_Replica)
    }

  }
}
`
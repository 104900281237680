import * as fcl from '@onflow/fcl';
import * as types from '@onflow/types';

import {
  BrowserRouter,
  Routes,
  Route,
  Switch,
  NavLink,
  useLocation,
} from 'react-router-dom';
import { MyWallet } from '../page/MyWallet';
import { useState, useEffect, useRef } from 'react';
import { create } from 'ipfs-http-client';

// import {Swiper, SwiperSlide} from "swiper/react"
// import "swiper/swiper-bundle.min.css"
// import "swiper/swiper.min.css"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  Controller,
  Thumbs,
  Scrollbar,
} from 'swiper';

import {
  faCirclePlay,
  faCircleChevronLeft,
  faCircleChevronRight,
  faSquareParking,
  faWallet,
  faLink,
  faFolderPlus,
  faCubes,
  faPuzzlePiece,
  faGavel,
  faPalette,
  faIcons,
  faCloudArrowUp,
  faTags,
  faGears,
  faListCheck,
  faDumpster,
  faLocationCrosshairs,
  faCartPlus,
  faCommentsDollar,
  faPlusMinus,
  faRecycle,
  faMobileScreen,
  faDesktop,
  faGamepad,
} from '@fortawesome/free-solid-svg-icons';
import {
  ChevronDownIcon,
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';

import {
  faFacebookMessenger,
  faTwitch,
} from '@fortawesome/free-brands-svg-icons';

import MeuCollectionMarkets from '../MeuCollectionMarkets';
import MeuCollectionMarketsDeeds from '../MeuCollectionMarketsDeeds';
import MeuCollectionMarketsReplicas from '../MeuCollectionMarketsReplicas';

import MeuCollectionDeeds from '../MeuCollectionDeeds';
import MeuCollectionDeedTickets from '../MeuCollectionDeedTickets';
import MeuCollectionReplicas from '../MeuCollectionReplicas';
import MeuCollectionReplicaTickets from '../MeuCollectionReplicaTickets';

import { serverAuthorization } from '../serverSigner';
import { meuTestScriptCallTx } from '../cadence/scripts/meu_test_script_call';
import { meuTestTransactionCallTx } from '../cadence/transactions/meu_test_transaction_call';
import _ from 'loadsh';
import { Header } from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MetaFcl from '../api/fcl';

import { swiperBreakpoint, swiperStyle } from '../config/options';
import {
  notableItems,
  rankingPriceItems11to15,
  rankingPriceItems1to5,
  rankingPriceItems6to10,
  trendingItems,
} from '../config/sample';
import Footer from '../components/Footer';

export default function Marketplace() {


  return (
    <>
      
      <section className="overflow-visible md:container md:mx-auto pb-10">
        <div className="justify-center md:container md:mx-auto">
          <MeuCollectionMarkets />
        </div>
      </section>
    </>
  );
}

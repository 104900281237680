import './App.css';

import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import {useState, useEffect} from 'react';
import {meuGetNFTsTicketReplicaScript} from "./cadence/scripts/meu_get_nfts_ticket_replica";
import {meuGetNFTsTicketReplicaCheckScript} from "./cadence/scripts/meu_get_nfts_ticket_replica_check";
import {meuCancleSaleReplicaTx} from "./cadence/transactions/meu_replica_cancle_sale";

import {serverAuthorization} from './serverSigner';
import {meuReplicaPaid2Tx} from "./cadence/transactions/meu_replica_paid2";
import {meuReplicaPaidTx} from "./cadence/transactions/meu_replica_paid";


function MeuCollectionReplicaTickets(props) {
  // console.log(props.address)

  const [nfts, setNFTs] = useState([]);
  const [checks, setChecks] = useState([]);

  useEffect(() => {
    getUserNFTs();
    getUserSaleNFTs();
  }, [])

  const getUserNFTs = async () => {
    try {
      const result = await fcl.send([
        fcl.script(meuGetNFTsTicketReplicaScript),
        fcl.args([
          fcl.arg(props.address, t.Address)
        ])
      ]).then(fcl.decode);
  
      console.log("getUserNFTs >>>> ")
      console.log(result);
      setNFTs(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }

  const getUserSaleNFTs = async () => {
    try {
      const result = await fcl.send([
        fcl.script(meuGetNFTsTicketReplicaCheckScript),
        fcl.args([
          fcl.arg(props.address, t.Address),
          fcl.arg(process.env.REACT_APP_FCL_MEU_ADDRESS, t.Address)
        ])
      ]).then(fcl.decode);
  
      console.log("getUserSaleNFTs >>>> ")
      console.log(result);
      setChecks(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }

  const paid2 = async (id) => {
    try {
      const transactionId = await fcl.send([
          fcl.transaction(meuReplicaPaid2Tx),
          fcl.args([
            fcl.arg(id, t.UInt64),    // 여기서의 id 는 tid 를 가리킴..
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999)
        ]).then(fcl.decode);
    
        console.log(transactionId);
        return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }

  const paid = async (id, rid, price) => {
    try {
      const transactionId = await fcl.send([
          fcl.transaction(meuReplicaPaidTx),
          fcl.args([
            fcl.arg(id, t.UInt64),    // 여기서의 id 는 tid 를 가리킴..
            fcl.arg(rid, t.UInt64),
            fcl.arg(price, t.UFix64) 
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999)
        ]).then(fcl.decode);
    
        console.log(transactionId);
        return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }

  const cancleSaleDeed = async (id, rid) => {
    try {
      const transactionId = await fcl.send([
        fcl.transaction(meuCancleSaleReplicaTx),
        fcl.args([
          fcl.arg(id, t.UInt64),
          fcl.arg(rid, t.UInt64),
        ]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz, serverAuthorization]),
        fcl.limit(9999)
      ]).then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  
  return (
    <div style={{backgroundColor: 'thistle'}}>
      {props.target === 'mine' 
        ? props.address === process.env.REACT_APP_FCL_MEU_ADDRESS ? <><h3>[ Admin's Tickets-Replica.. (Admin's Collections.. ]</h3><h5>[ Address : {props.address} ]</h5></>
        : <><h3>[ Logged in User's Tickets-Replica.. (User's Collections..) ]</h3><h5>[ Address : {props.address} ]</h5></>
        : <><h3>[ Target User's Tickets-Replica.. (User's Collections..) ]</h3><h5>[ Address : {props.address} ]</h5></>
      }
      {nfts.map(nft => (
          <div style={{margin:"50px"}} key={nft.id} className="flex flex-col justify-center justify-items-center items-center">
              <h4 style={{margin:"0"}}>[id ::trid] {nft.id}</h4>
              <h4 style={{margin:"0"}}>[cid] {nft.cid}</h4>
              <h4 style={{margin:"0"}}>[rid] {nft.rid}</h4>
              <h4 style={{margin:"0"}}>[nid] {nft.nid}</h4>
              <h4 style={{margin:"0"}}>[tgtkid] {nft.tgtkid}</h4>
              <h4 style={{margin:"0"}}>[price] {nft.price}</h4>
              <h4 style={{margin:"0"}}>[birthday-date] { new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(parseInt(nft.metadata.birthday) * 1000) }</h4>
              <h4 style={{margin:"0"}}>[birthday-unix] {nft.metadata.birthday}</h4>
              <h4 style={{margin:"0"}}>[soldday-date] {nft.metadata.soldday ? new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(parseInt(nft.metadata.soldday) * 1000) : "not yet" }</h4>
              <h4 style={{margin:"0"}}>[soldday-unix] {nft.metadata.soldday}</h4>
              <h4 style={{margin:"0"}}>[paidday-date] {nft.metadata.paidday ? new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(parseInt(nft.metadata.paidday) * 1000) : "not yet" }</h4>
              <h4 style={{margin:"0"}}>[paidday-unix] {nft.metadata.paidday}</h4>
              {props.address !== process.env.REACT_APP_FCL_MEU_ADDRESS && props.target === 'mine'
                ? nft.rid === nft.tgtkid ? checks[nft.id] ? <>ReSales::
                                                              <h4 style={{margin:"0"}}>[check] SoldOut</h4>
                                                              <button onClick={() => paid(nft.id, nft.rid, nft.price)}>Get Paid</button>
                                                            </>
                                                          : <>ReSales::
                                                              <h4 style={{margin:"0"}}>[check] OnSale..</h4>
                                                              <button onClick={() => cancleSaleDeed(nft.id, nft.rid)}>Cancel the Sale</button>
                                                              <h5>{props.address}</h5>
                                                            </>
                                         : <>MarketSales::<button onClick={() => paid2(nft.id)}>Get Paid2</button></>
                : <></>
              }
          </div>
      ))}
    </div>
  );
}

export default MeuCollectionReplicaTickets;
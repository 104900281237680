import {faCirclePlay, faCircleChevronLeft, faCircleChevronRight, faSquareParking, faWallet, faLink, faFolderPlus, faCubes, faPuzzlePiece, faGavel, faPalette, faIcons, faCloudArrowUp, faTags, faGears, faListCheck, faDumpster, faLocationCrosshairs, faCartPlus, faCommentsDollar, faPlusMinus, faRecycle, faMobileScreen, faDesktop, faGamepad} from "@fortawesome/free-solid-svg-icons"
const notableItems = [
    {
        id: 1,
        name: "Gom Kuma",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/notable/emojipack_001.png`,
        imageAlt: "Image Description..",
        price: "0.8",
        ratings: "4.95",
        reviews: "1209",
        tags: "#bear #office",
    },
    {
        id: 2,
        name: "Goooooooood",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/notable/emojipack_002.png`,
        imageAlt: "Image Description..",
        price: "1.1",
        ratings: "4.97",
        reviews: "1621",
        tags: "#MEU #emojia",
    },
    {
        id: 3,
        name: "KwakKwak2",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/notable/emojipack_003.png`,
        imageAlt: "Image Description..",
        price: "1.4",
        ratings: "4.79",
        reviews: "1327",
        tags: "#duck #animal",
    },
    {
        id: 4,
        name: "UzzaLego",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/notable/emojipack_004.png`,
        imageAlt: "Image Description..",
        price: "1.1",
        ratings: "4.62",
        reviews: "997",
        tags: "#butler #cat",
    },
    {
        id: 5,
        name: "DogDogBangBang",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/notable/emojipack_005.png`,
        imageAlt: "Image Description..",
        price: "2.1",
        ratings: "4.88",
        reviews: "2471",
        tags: "#humor #tacky",
    },
    {
        id: 6,
        name: "Carnman",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/notable/emojipack_111.png`,
        imageAlt: "Image Description..",
        price: "1.9",
        ratings: "4.93",
        reviews: "3612",
        tags: "#PWD #extraordinary",
    },
    {
        id: 7,
        name: "Solo Oksun",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/notable/emojipack_006.png`,
        imageAlt: "Image Description..",
        price: "1.7",
        ratings: "4.81",
        reviews: "1584",
        tags: "#healing #girl",
    },
    {
        id: 8,
        name: "MikuNiku",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/notable/emojipack_007.png`,
        imageAlt: "Image Description..",
        price: "0.9",
        ratings: "4.51",
        reviews: "864",
        tags: "#ani #character",
    },
    {
        id: 9,
        name: "NemoAnachi",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/notable/emojipack_008.png`,
        imageAlt: "Image Description..",
        price: "1.2",
        ratings: "4.67",
        reviews: "1377",
        tags: "#comics #solid",
    },
]

const rankingPriceItems1to5 = [
    {
        rank: 1,
        name: "CryptoKitties",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_001.jpg`,
        imageAlt: "Profile of CryptoKitties",
        priceFloor: 77.7,
        priceNow: 777.3,
        rate: 17.37,
        tagStyle: ["cute", "dapper", "lovely", "pet", "animal", "cat", "powerful", "iloveit", "catchy", "various"],
    },
    {
        rank: 2,
        name: "BlueGirl",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_002.png`,
        imageAlt: "Profile of BlueGirl",
        priceFloor: 2.1,
        priceNow: 531.9,
        rate: -3.19,
        tagStyle: ["cute", "pretty", "lovely", "icy", "blackhair", "asian", "iloveit"],
    },
    {
        rank: 3,
        name: "HeyKaKaoFriendsGroup",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_003.jpg`,
        imageAlt: "Profile of HeyKaKaoFriendsGroup",
        priceFloor: 17.6,
        priceNow: 497.1,
        rate: 4.17,
        tagStyle: ["talk", "yellow", "lovely", "iloveit", "catchy", "various"],
    },
    {
        rank: 4,
        name: "K-POP SunMi",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_004.png`,
        imageAlt: "Profile of K-POP SunMi",
        priceFloor: 81,
        priceNow: 461.9,
        rate: 38.58,
        tagStyle: ["PFP", "k-pop", "dance", "gasina", "jyp"],
    },
    {
        rank: 5,
        name: "Gom Kuma",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_005.gif`,
        imageAlt: "Profile of Gom Kuma",
        priceFloor: 36.6,
        priceNow: 363.2,
        rate: 75.41,
        tagStyle: ["gom", "audio", "alsong", "mp3", "iriver"],
    },
]
const rankingPriceItems6to10 = [
    {
        rank: 6,
        name: "Rat Mouse 2M",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_006.gif`,
        imageAlt: "Profile of Rat Mouse 2M",
        priceFloor: 102.2,
        priceNow: 311.1,
        rate: 0,
        tagStyle: ["heart", "cute", "lovely", "pet", "animal", "mouse"],
    },
    {
        rank: 7,
        name: "Carnman",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_007.gif`,
        imageAlt: "Profile of CryptoPunks",
        priceFloor: 41.7,
        priceNow: 263.7,
        rate: 168.25,
        tagStyle: ["PWD", "heart", "extraordinary", "cute", "love"],
    },
    {
        rank: 8,
        name: "Girl Girl Gen",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_008.jpg`,
        imageAlt: "Profile of Girl Girl Gen",
        priceFloor: 3.7,
        priceNow: 210.5,
        rate: -42.53,
        tagStyle: ["doll", "Annie", "lovely", "Tibber", "gom", "player"],
    },
    {
        rank: 9,
        name: "Caterpie Slime",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_009.gif`,
        imageAlt: "Profile of Caterpie Slime",
        priceFloor: 5.8,
        priceNow: 187.8,
        rate: 62.9,
        tagStyle: ["slime", "cute", "lovely", "pet", "caterpie", "pie", "applepie"],
    },
    {
        rank: 10,
        name: "Ant Space Astronaut",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_010.png`,
        imageAlt: "Profile of Ant Space Astronaut",
        priceFloor: 41.2,
        priceNow: 174.1,
        rate: 3.17,
        tagStyle: ["ant", "and", "astronaut", "eye", "feeler"],
    },
]
const rankingPriceItems11to15 = [
    {
        rank: 11,
        name: "Monkey Montana Mango",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_011.png`,
        imageAlt: "Profile of Monkey Montana Mango",
        priceFloor: 0.5,
        priceNow: 101.2,
        rate: -67.71,
        tagStyle: ["MMM", "monkey", "banana", "red", "yellow", "train"],
    },
    {
        rank: 12,
        name: "CyberLover::Boost",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_012.jpg`,
        imageAlt: "Profile of CyberLover::Boost",
        priceFloor: 89.2,
        priceNow: 93.7,
        rate: 73.5,
        tagStyle: ["picture", "paint", "emoji", "smile", "various"],
    },
    {
        rank: 13,
        name: "Mococo is Love",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_013.gif`,
        imageAlt: "Profile of Mococo is Love",
        priceFloor: 7.7,
        priceNow: 88.8,
        rate: 30.96,
        tagStyle: ["mococo", "LostArk", "iloveit", "catchy", "mococo"],
    },
    {
        rank: 14,
        name: "PrettyHat and Women",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_014.jpg`,
        imageAlt: "Profile of CryptoKitties",
        priceFloor: 10,
        priceNow: 87.4,
        rate: 0,
        tagStyle: ["dapper", "lovely", "powerful", "iloveit"],
    },
    {
        rank: 15,
        name: "MEU Goooooooood",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_015.gif`,
        imageAlt: "Profile of MEU Goooooooood",
        priceFloor: 39,
        priceNow: 77.8,
        rate: 265.94,
        tagStyle: ["MEU", "good", "thanks", "emojia", "MetaUzu"],
    },
]

// trendingItems >> imageSrc >> 360 x 200 px ===> only size..
const trendingItems = [
    {
        nameCreation: "ManyManyMany",
        nameProfile: "Astro M",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_001.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_001.gif`,
        imageProfileAlt: "",
        txtIntro: "100 NFTs. Each owner gets early access + a lifetime discount to our Web3 Marketplace: Americana: Real Objects on the Blockchain.",
        tagStyle: ["MMM", "many", "astro", "various"],
    },
    {
        nameCreation: "8degree GangSan",
        nameProfile: "SunBie84",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_002.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_002.gif`,
        imageProfileAlt: "",
        txtIntro: "Citizen NFT holders are citizens of CityDAO, granting community access and governance.",
        tagStyle: ["degree", "mountain", "river", "nature", "various"],
    },
    {
        nameCreation: "Wald EmoA",
        nameProfile: "mozjiA",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_003.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_003.gif`,
        imageProfileAlt: "",
        txtIntro:
            "Bōryoku Dragonz is an exclusive collection of 1,111 Dragon NFTs first birthed on Solana. It is backed by a top team of NFT collectors, designers, community builders, and artists. The project brings a fresh design to Solana, with daily utility airdrops, a breeding game with a deliberate token burning mechanics. Boryoku Dragonz prides itself as a multi-chain community that completely transcends a simple PFP offering.",
        tagStyle: ["MetaUzu", "EmojiA", "good", "fantastic"],
    },
    {
        nameCreation: "Dino Jino",
        nameProfile: "jenofill",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_004.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_004.gif`,
        imageProfileAlt: "",
        txtIntro: "Buy for the art, stay for the culture | A whole new world awaits | New team and reimagined roadmap | Utility incoming",
        tagStyle: ["yellow", "dinosaur", "AKaMU", "cute", "kong"],
    },
    {
        nameCreation: "Kingdom of CottonBall",
        nameProfile: "Cotton Heroes",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_005.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_005.png`,
        imageProfileAlt: "",
        txtIntro: "May 28th, 2017 - July 18th, 2018. For “Twin Flames” I photographed 100 sets of twins in honor of my fraternal twin, aiming to create body of work focused on the existence of multiple births and the phenomena of twindom through an immersive portrait survey. Twins and multiple siblings provide a lens on the magic and causality of biology.",
        tagStyle: ["king", "dom", "Document", "Object", "Model"],
    },
    {
        nameCreation: "Carnman",
        nameProfile: "Bomber Girl",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_111.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_111.gif`,
        imageProfileAlt: "",
        txtIntro: "I have autism, but instead I can draw 'Carnman'. And reveal this. 'Emojia' is my canvas and palette. All are welcome.",
        tagStyle: ["Extraordinary", "carnman", "bomb", "cute", "Blur"],
    },
    {
        nameCreation: "Astro Yellow",
        nameProfile: "Public Worker",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_006.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_006.png`,
        imageProfileAlt: "",
        txtIntro: "Your token for exclusive access and discounts on the Americana Marketplace.",
        tagStyle: ["star", "planet", "astro", "yellow", "wow"],
    },
    {
        nameCreation: "K-Chicken HCB",
        nameProfile: "JiHyeonC",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_007.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_007.png`,
        imageProfileAlt: "",
        txtIntro: "Welcome to the home of Poolsuite - Executive Member on OpenSea. Discover the best items in this collection.",
        tagStyle: ["K-Culture", "JiHyeonC", "BHBC", "chicken", "dak"],
    },
    {
        nameCreation: "Keep it simple, stupid!",
        nameProfile: "K.I.S.S",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_008.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_008.png`,
        imageProfileAlt: "",
        txtIntro:
            "OneOnes is a collection of 6565 companions that will transport you to the hidden Magical World. The OneOnes is an NFT project built on storytelling and interaction expanding the Magical World created by Migrating Lines in his 1/1 art. From a technical perspective, the OneOnes are minted on the Ethereum blockchain as a unique, non-fungible token (NFT). This project is born by merging the 1/1 art world and the pfp collectible world. There are a total of 8 Core Families of OneOnes and several 1/1 Celestial OneOnes that will be unleashed!",
        tagStyle: ["simple", "classic", "best", "emoji", "good"],
    },
    {
        nameCreation: "MMM is Wonderful",
        nameProfile: "Monkey Apple.inc",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_009.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_009.png`,
        imageProfileAlt: "",
        txtIntro: "CLAY is a fully on-chain 3D generative art project. The code is the art, the art is the code.",
        tagStyle: ["sun", "glasses", "bling", "zoo", "monkey"],
    },
    {
        nameCreation: "Hey, Guys!",
        nameProfile: "I'm a Boy!",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_010.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_010.png`,
        imageProfileAlt: "",
        txtIntro: "Wackies is an animated generative version of her portrait art with a supply of 2,500 unique pieces. Each peice symbolizes Camille's journey and expression of identity through art. Alongside the collection, Camille's company WACK! is a brand on and off of web3.",
        tagStyle: ["boy", "girl", "children", "var", "char"],
    },
    {
        nameCreation: "DeepPlayStation2",
        nameProfile: "Merge, Pull, Push",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_011.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_011.png`,
        imageProfileAlt: "",
        txtIntro: "We smoke, ponder and wonder. We are in a constant pursuit of enlightenment. Let's level up... it will be legendary. ",
        tagStyle: ["deepLearning", "PS2", "game", "char", "DapPlayStay"],
    },
    {
        nameCreation: "We're PD's Note",
        nameProfile: "K-MBCD",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_012.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_012.png`,
        imageProfileAlt: "",
        txtIntro: "Gazers by Matt Kane - Art Blocks Curated",
        tagStyle: ["assemble", "char", "fantasy", "six", "back"],
    },
    {
        nameCreation: "Ah-choo Infinity Stones",
        nameProfile: "(Cat & Dappy)'s DaddyPaPa ",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_013.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_013.png`,
        imageProfileAlt: "",
        txtIntro: "Otherdeed is the key to claiming land in Otherside. Each have a unique blend of environment and sediment — some with resources, some home to powerful artifacts. And on a very few, a Koda roams.",
        tagStyle: ["Axe", "lovelyz", "flag", "round", "gogo"],
    },
    {
        nameCreation: "Hama Tree Palace",
        nameProfile: "Mr.Ha",
        href: "#",
        imageSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/trending_014.png`,
        imageAlt: "",
        imageProfileSrc: `${process.env.PUBLIC_URL}/assets/sample/trending/profile_trending_014.png`,
        imageProfileAlt: "",
        txtIntro: 'DYOR NERDS has a heavy focus on perspective, so often we are looking into our screens at others, yet rarely are we looking inward on ourselves. A vast majority of the NFT space spend countless hours on their computers; looking for just the right project. A big part of the culture is around "DYOR", and we wanted to encapsulate that. We are all NERDS!',
        tagStyle: ["talent", "actor", "movie", "art", "paint"],
    },
]
// easyToUseItems >> imageSrc >> 360 x 200 px ===> only size..
const easyToUseItems = [
    {
        key: "easyToUse-1",
        title: "Set up your Wallet",
        txtContent: "Once you’ve set up your wallet of choice, connect it to MetaUzu by clicking the wallet icon in the top right corner. Blocto is recommended.",
        txtHref: "Learn about the wallets we support.",
        href: "#",
        listIcon: [faWallet, faLink],
    },
    {
        key: "easyToUse-2",
        title: "Create your Collection",
        txtContent: "Click My Collections and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.",
        txtHref: "Go to My Collections",
        href: "#",
        listIcon: [faFolderPlus, faCubes, faPuzzlePiece],
    },
    {
        key: "easyToUse-3",
        title: "Add your NFTs",
        txtContent: "Upload your work (image, video, audio, or 3D art), add a title and description, and customize your NFTs with properties, stats, and unlockable content.",
        txtHref: "Make it Easy in Create",
        href: "#",
        listIcon: [faGavel, faPalette, faIcons, faCloudArrowUp],
    },
    {
        key: "easyToUse-4",
        title: "List them for sale",
        txtContent: "Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs, and we help you sell them !",
        txtHref: "Check out My Auctions",
        href: "#",
        listIcon: [faTags, faGears, faListCheck],
    },
    {
        key: "easyToUse-5",
        title: "Ownership & License",
        txtContent: "You can sell and buy other people's NFT Ownership and Licenses to populate your collection. Your copyright will must be Guaranteed.",
        txtHref: "Explore the Markets",
        href: "#",
        listIcon: [faDumpster, faLocationCrosshairs, faCartPlus, faCommentsDollar, faPlusMinus],
    },
    {
        key: "easyToUse-6",
        title: "Usable in Real Life",
        txtContent: "Own and use. There are no NFTs that are no longer available. It can be used on a Variety of Devices. Apply it to real life and enjoy !",
        txtHref: "Learn about the dApps we support.",
        href: "#",
        listIcon: [faRecycle, faMobileScreen, faDesktop, faGamepad],
    },
]

export {notableItems, rankingPriceItems1to5, rankingPriceItems6to10, rankingPriceItems11to15, trendingItems, easyToUseItems}

// https://docs.onflow.org/cadence/language/accounts/ >> key 제거하거나 할때..

import * as fcl from '@onflow/fcl';
import * as types from '@onflow/types';

import {
  BrowserRouter,
  Routes,
  Route,
  Switch,
  NavLink,
  useNavigate,
} from 'react-router-dom';
import { MyWallet } from '../page/MyWallet';
import { useState, useEffect, useRef } from 'react';

// import {Swiper, SwiperSlide} from "swiper/react"
// import "swiper/swiper-bundle.min.css"
// import "swiper/swiper.min.css"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  Controller,
  Thumbs,
  Scrollbar,
} from 'swiper';

import {
  faCirclePlay,
  faCircleChevronLeft,
  faCircleChevronRight,
  faSquareParking,
  faWallet,
  faLink,
  faFolderPlus,
  faCubes,
  faPuzzlePiece,
  faGavel,
  faPalette,
  faIcons,
  faCloudArrowUp,
  faTags,
  faGears,
  faListCheck,
  faDumpster,
  faLocationCrosshairs,
  faCartPlus,
  faCommentsDollar,
  faPlusMinus,
  faRecycle,
  faMobileScreen,
  faDesktop,
  faGamepad,
} from '@fortawesome/free-solid-svg-icons';
import {
  ChevronDownIcon,
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';

// 아이콘 써드파티 컴포넌트 삽입
import * as icos from '@heroicons/react/24/solid'; // heroicon solid
import * as icoo from '@heroicons/react/24/outline'; // heroicon outline
import * as icom from '@heroicons/react/20/solid'; // heroicon mini (solid)

import {
  faFacebookMessenger,
  faTwitch,
} from '@fortawesome/free-brands-svg-icons';

import MeuCollectionMarkets from '../MeuCollectionMarkets';
import MeuCollectionMarketsDeeds from '../MeuCollectionMarketsDeeds';
import MeuCollectionMarketsReplicas from '../MeuCollectionMarketsReplicas';

import MeuCollectionDeeds from '../MeuCollectionDeeds';
import MeuCollectionDeedTickets from '../MeuCollectionDeedTickets';
import MeuCollectionReplicas from '../MeuCollectionReplicas';
import MeuCollectionReplicaTickets from '../MeuCollectionReplicaTickets';

import { serverAuthorization } from '../serverSigner';
import { meuTestScriptCallTx } from '../cadence/scripts/meu_test_script_call';
import { meuTestTransactionCallTx } from '../cadence/transactions/meu_test_transaction_call';
import _ from 'loadsh';
import { Header } from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MetaFcl from '../api/fcl';

import { swiperBreakpoint, swiperStyle } from '../config/options';
import {
  notableItems,
  rankingPriceItems11to15,
  rankingPriceItems1to5,
  rankingPriceItems6to10,
  trendingItems,
} from '../config/sample';
import Footer from '../components/Footer';
// import {
//   faFacebookMessenger,
//   faTwitch,
// } from '@fortawesome/free-brands-svg-icons'

function Home() {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  // SwiperCore.use([Navigation, Scrollbar, Pagination, Controller])
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const navgationPrevRef = useRef(null);
  const navgationNextRef = useRef(null);

  // MEU Customs..
  const [nameArticleOfCentralNFT, setNameArticleOfCentralNFT] = useState('');
  const [nameAuthorOfCentralNFT, setNameAuthorOfCentralNFT] = useState('');
  const [descOfCentralNFT, setDescOfCentralNFT] = useState('');
  const [priceOfCentralNFT, setPriceOfCentralNFT] = useState();

  const [fileCentral, setFileCentral] = useState();
  const [txtTargetUserAddress, setTxtTargetUserAddress] = useState();
  const [targetUserAddress, setTargetUserAddress] = useState('');
  const [loggedInUserAddress, setLoggedInUserAddress] = useState('');

  SwiperCore.use([Navigation, Scrollbar, Pagination, Controller]);

  useEffect(() => {
    // sets the `user` variable to the person that is logged in through Blocto
    fcl.currentUser().subscribe(setUser);
  }, []);

  const swiperParamsNotable = {
    navigation: true,
    onSwiper: setSwiper, // useState 에 등록된 swiper 를 이용해, swiper.slideTo() 같은 method 사용 가능.
    onSlideChange: (e) => {
      setMainImageIndex(e.activeIndex);
    }, // slide가 이동할때 발생하는 이벤트 핸들러로, 현재 어떤 슬라이드 메인에 나와있는지 index 값을 저장하는 용도로 사용.
    spaceBetween: 20, // px 단위 간격
    navigation: {
      // 좌우 버튼 커스텀
      prevEl: navgationPrevRef.current ? navgationPrevRef.current : undefined, // 이전 버튼 Ref 또는 className
      nextEl: navgationNextRef.current ? navgationNextRef.current : undefined, // 다음 버튼 Ref 또는 className
    },
    scrollbar: {
      // 스크롤바 커스텀
      draggable: true, // 드래그 가능 여부
      el: null, // 스크롤바 Ref 또는 className
    },
    slidesPerView: 1, // 한 화면에 보이는 슬라이드 수
    onBeforeInit: (swiper) => {
      // 초기 설정, 이벤트 핸들러
      swiper.params.navigation.prevEl = navgationPrevRef.current;
      swiper.params.navigation.nextEl = navgationNextRef.current;
      swiper.navigation.update();
    },
    breakpoints: swiperBreakpoint,
    // 추후 pagination 구현할것
  };

  const swiperParamsTrending = {
    navigation: true,
    onSwiper: setSwiper, // useState 에 등록된 swiper 를 이용해, swiper.slideTo() 같은 method 사용 가능.
    onSlideChange: (e) => {
      setMainImageIndex(e.activeIndex);
    }, // slide가 이동할때 발생하는 이벤트 핸들러로, 현재 어떤 슬라이드 메인에 나와있는지 index 값을 저장하는 용도로 사용.
    spaceBetween: 0, // px 단위 간격
    navigation: {
      // 좌우 버튼 커스텀
      prevEl: navgationPrevRef.current ? navgationPrevRef.current : undefined, // 이전 버튼 Ref 또는 className
      nextEl: navgationNextRef.current ? navgationNextRef.current : undefined, // 다음 버튼 Ref 또는 className
    },
    scrollbar: {
      // 스크롤바 커스텀
      draggable: true, // 드래그 가능 여부
      el: null, // 스크롤바 Ref 또는 className
    },
    slidesPerView: 1, // 한 화면에 보이는 슬라이드 수
    onBeforeInit: (swiper) => {
      // 초기 설정, 이벤트 핸들러
      swiper.params.navigation.prevEl = navgationPrevRef.current;
      swiper.params.navigation.nextEl = navgationNextRef.current;
      swiper.navigation.update();
    },
    breakpoints: swiperBreakpoint,
    // 추후 pagination 구현할것
    pagination: {
      // el: ".pagination_bullet", //페이징 태그 클래스 설정
      clickable: true, //버튼 클릭 여부
      type: 'bullets', //페이징 타입 설정(종류: bullets, fraction, progressbar)
      // Bullet Numbering 설정
      // renderBullet: function (index, className) {
      //   return '<span className="' + className + '">' + (index + 1) + "</span>";
      // },
    },
    loop: false,
  };

  const meuTestScriptCall = async () => {
    try {
      const result = await fcl
        .send([
          fcl.script(meuTestScriptCallTx),
          fcl.args([
            fcl.arg(process.env.REACT_APP_FCL_MEU_ADDRESS, types.Address),
          ]),
        ])
        .then(fcl.decode);
      console.log('meuTestScriptCall');
      console.log(result);
    } catch (error) {
      console.log('error ===> ', error);
      // alert("error ===> ", error)
    }
  };

  // pakt87
  const meuTestTransactionCall = async () => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuTestTransactionCallTx),
          fcl.args([
            fcl.arg(1, types.UInt64),
            fcl.arg(10, types.UInt64),
            // fcl.arg("nameAuthor", types.String),
            // fcl.arg("anyons", types.String)
          ]),
          // fcl.payer(fcl.authz),
          // fcl.proposer(fcl.authz),
          // fcl.authorizations([fcl.authz]),
          fcl.payer(serverAuthorization),
          fcl.proposer(serverAuthorization),
          fcl.authorizations([serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);

      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log('error ===> ', error);
      // alert("error ===> ", error)
    }
  };

  /***********************************
   * MEU Customs..
   ***********************************/
  const loadMyCerts = () => {
    console.log('btn loadMyCerts clicked');
    setLoggedInUserAddress(user.addr);
  };
  const loadAdminCerts = () => {
    setLoggedInUserAddress(process.env.REACT_APP_FCL_MEU_ADDRESS);
  };

  return (
    <div className="">
      <div className="relative z-10 max-w-7xl mx-auto mt-16 bg-white overflow-hidden">
        <div>
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <main className="pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">Emojia to enrich your</span>{' '}
                  <span className="block text-meu-500 xl:inline">
                    online communication
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Anyone can create and trade Emoji NFTs, and use a variety of
                  emoticons anywhere in your online life to express your
                  emotions in a rich way.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <NavLink
                      to="/marketplace"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-meu-500 hover:bg-meu-600 md:py-4 md:text-lg md:px-10"
                    >
                      Marketplace
                    </NavLink>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <NavLink
                      to="/atelier"
                      className="w-full flex items-center justify-center px-8 py-3 border border-meu-200 hover:border-meu-300 text-base font-medium rounded-md text-meu-600 bg-yellow-50 hover:bg-yellow-100 md:py-4 md:text-lg md:px-10"
                    >
                      Atelier
                    </NavLink>
                  </div>
                </div>
                <div className="mt-4 lg:mt-5">
                  <a
                    className="mt-3 text-sm text-meu-500 hover:text-meu-600 sm:mt-5 sm:text-base sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0"
                    href="#"
                  >
                    <FontAwesomeIcon
                      icon={faCirclePlay}
                      className="flex-shrink-0 h-3 w-3 sm:h-4 sm:w-4"
                      aria-hidden="true"
                    />
                    <span> Learn more about MetaUzu</span>
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 overflow-hidden">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full scale-105 hover:scale-110 duration-200"
            src={`${process.env.PUBLIC_URL}/assets/sample/home/img_hero.jpg`}
            alt=""
          />
        </div>
      </div>
      \
      <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-28">
        <h2 className="text-2xl font-bold text-center leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Notable Packs
        </h2>
      </div>
      {/* Notable packs List */}
      <Swiper
        {...swiperParamsNotable}
        style={swiperStyle}
        ref={setSwiper}
        className="max-w-7xl mx-auto px-4 sm:px-6 mt-8"
      >
        {notableItems.map((item, index) => (
          <SwiperSlide key={item.imageSrc}>
            <div
              className="wrapper bg-white m-8 antialiased text-gray-900 cursor-pointer hover:scale-105 transition-transform transform hover:-translate-y-4 group"
              onClick={() => {
                console.log(item);
                navigate('/marketplace');
              }}
            >
              <div>
                {/* // 480 x 640 px ===> only size.. */}
                <img
                  src={item.imageSrc}
                  alt={item.imageAlt}
                  className="w-full object-cover object-center rounded-lg shadow-md"
                />
                <div className="relative px-4 -mt-16 ">
                  <div className="bg-white p-6 rounded-lg shadow-lg group-hover:border-meu-500 group-hover:border">
                    <div className="flex items-baseline">
                      <span className="bg-teal-200 text-teal-800 text-xs px-2 inline-block rounded-full  uppercase font-semibold tracking-wide">
                        New
                      </span>
                      <div className="ml-2 text-gray-600 text-xs font-semibold tracking-wider">
                        {item.tags}
                      </div>
                    </div>
                    <h4 className="mt-1 text-xl font-semibold leading-tight truncate">
                      {item.name}
                    </h4>
                    {/* <div className="mt-4">
                      <span className="text-teal-600 text-md font-semibold">4/5 ratings{' '}</span>
                      <span className="text-sm text-gray-600">(based on 234 ratings)</span>
                    </div> */}
                    <div className="mt-0 text-right">
                      <span className="text-2xl text-meu-500 font-semibold">
                        {item.price}
                      </span>
                      <span className="text-gray-800 text-sm font-semibold ml-1.5">
                        {' '}
                        Flow
                      </span>
                    </div>
                    <div className="mt-0 flex items-center justify-end">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-teal-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Rating star</title>
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                      <p className="ml-0.5 text-md font-bold text-gray-900 white">
                        {item.ratings}
                      </p>
                      <span className="mx-1.5 text-gray-600 text-sm">
                        &bull;
                      </span>
                      <a
                        href="#"
                        className="text-sm font-medium text-gray-900 underline hover:no-underline "
                      >
                        {item.reviews} reviews
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div>
          <button ref={navgationPrevRef}>
            <FontAwesomeIcon
              icon={faCircleChevronLeft}
              className="absolute top-1/2 z-10 cursor-pointer flex items-center justify-center h-12 w-12 -mt-8 text-meu-500 hover:text-meu-600 left-1 right-auto"
              aria-hidden="true"
            />
          </button>
          <button ref={navgationNextRef}>
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              className="absolute top-1/2 z-10 cursor-pointer flex items-center justify-center h-12 w-12 -mt-8 text-meu-500 hover:text-meu-600 right-1 left-auto"
              aria-hidden="true"
            />
          </button>
        </div>
      </Swiper>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-16">
        <h2 className="text-2xl font-bold text-center leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Collections of
          <a href="#" className="text-meu-500 hover:text-meu-600">
            <span>
              {' '}
              Top Price
              <ChevronDownIcon
                className="-mt-1 ml-1 h-5 w-5 inline-block"
                aria-hidden="true"
              />
            </span>
          </a>
        </h2>
      </div>
      {/* Ranking list */}
      <div className="max-w-2xl mx-auto space-y-4 sm:space-y-0 sm:grid sm:grid-cols-1 lg:max-w-7xl lg:grid-cols-3 gap-3 p-12">
        <div>
          {rankingPriceItems1to5.map((priceItem, index) => (
            <div
              key={priceItem.imageSrc}
              onClick={() => {
                console.log(priceItem);
              }}
            >
              <div className="relative mb-2 cursor-pointer hover:shadow-lg border-transparent border hover:border-meu-400 hover:border rounded-lg transition-transform transform hover:-translate-y-1">
                <div className="shadow rounded-lg py-3 pr-5 bg-white">
                  <div className="flex h-18 pl-24 flex-row justify-between items-center">
                    <div className="w-full">
                      <div>
                        <h6 className="text-2xl tracking-tight font-bold block truncate">
                          {priceItem.name}
                        </h6>
                      </div>
                      <div className="flex flex-row justify-between items-center">
                        <div>
                          <h4 className="text-2xl font-semibold text-left inline-block text-meu-500">
                            {priceItem.priceNow}
                          </h4>
                          <p className="ml-3 inline-block font-semibold">
                            Flow
                          </p>
                        </div>
                        <div>
                          <ArrowRightCircleIcon
                            className="-mr-3 h-6 w-6 inline-block text-gray-300 font-bold hover:text-meu-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>

                      <div className="flex flex-row justify-end text-base min-w-fit">
                        {priceItem.rate > 0 ? (
                          <>
                            <span className="mr-1">
                              <ArrowTrendingUpIcon
                                className="mx-1 h-6 w-6 inline-block text-red-400 font-bold"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="text-gray-500">
                              <span className="text-red-400 font-bold">
                                {priceItem.rate}%
                              </span>{' '}
                              in 7 days
                            </p>
                          </>
                        ) : priceItem.rate < 0 ? (
                          <>
                            <span className="mr-1">
                              <ArrowTrendingDownIcon
                                className="mx-1 h-6 w-6 inline-block text-blue-400 font-bold"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="text-gray-500">
                              <span className="text-blue-400 font-bold">
                                {priceItem.rate}%
                              </span>{' '}
                              in 7 days
                            </p>
                          </>
                        ) : (
                          <>
                            <span className="mr-1">
                              <FontAwesomeIcon
                                icon={faSquareParking}
                                className="mx-1 h-6 w-6 inline-block text-gray-400 font-bold"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="text-gray-500">
                              <span className="text-gray-400 font-bold">
                                {priceItem.rate}%
                              </span>{' '}
                              in 7 days
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-row text-sm tracking-tight text-gray-600 pl-4">
                      <div className="block truncate pr-6">
                        {priceItem.tagStyle.map((hashTag, index) => (
                          <span key={index + 'hash1'}>#{hashTag} </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="absolute top-3 left-5 h-16 w-16 rounded-full border-2 border-white shadow-md">
                  <img
                    className="rounded-full object-cover object-center"
                    src={priceItem.imageSrc}
                    alt={priceItem.imageAlt}
                  />
                </div>
                <div className="absolute top-2 left-2 h-6 w-6 rounded-lg border-2 border-white bg-teal-200 text-sm text-teal-800 tracking-tight text-center align-text-top font-semibold">
                  {priceItem.rank}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          {rankingPriceItems6to10.map((priceItem, index) => (
            <div
              key={priceItem.imageSrc}
              onClick={() => {
                console.log(priceItem);
                if (priceItem.rank === 7) {
                  navigate('/channel');
                }
              }}
            >
              <div className="relative mb-2 cursor-pointer hover:shadow-lg border-transparent border hover:border-meu-400 hover:border rounded-lg transition-transform transform hover:-translate-y-1">
                <div className="shadow rounded-lg py-3 pr-5 bg-white">
                  <div className="flex h-18 pl-24 flex-row justify-between items-center">
                    <div className="w-full">
                      <div>
                        <h6 className="text-2xl tracking-tight font-bold block truncate">
                          {priceItem.name}
                        </h6>
                      </div>
                      <div className="flex flex-row justify-between items-center">
                        <div>
                          <h4 className="text-2xl font-semibold text-left inline-block text-meu-500">
                            {priceItem.priceNow}
                          </h4>
                          <p className="ml-3 inline-block font-semibold">
                            Flow
                          </p>
                        </div>
                        <div>
                          <ArrowRightCircleIcon
                            className="-mr-3 h-6 w-6 inline-block text-gray-300 font-bold hover:text-meu-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>

                      <div className="flex flex-row justify-end text-base min-w-fit">
                        {priceItem.rate > 0 ? (
                          <>
                            <span className="mr-1">
                              <ArrowTrendingUpIcon
                                className="mx-1 h-6 w-6 inline-block text-red-400 font-bold"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="text-gray-500">
                              <span className="text-red-400 font-bold">
                                {priceItem.rate}%
                              </span>{' '}
                              in 7 days
                            </p>
                          </>
                        ) : priceItem.rate < 0 ? (
                          <>
                            <span className="mr-1">
                              <ArrowTrendingDownIcon
                                className="mx-1 h-6 w-6 inline-block text-blue-400 font-bold"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="text-gray-500">
                              <span className="text-blue-400 font-bold">
                                {priceItem.rate}%
                              </span>{' '}
                              in 7 days
                            </p>
                          </>
                        ) : (
                          <>
                            <span className="mr-1">
                              <FontAwesomeIcon
                                icon={faSquareParking}
                                className="mx-1 h-6 w-6 inline-block text-gray-400 font-bold"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="text-gray-500">
                              <span className="text-gray-400 font-bold">
                                {priceItem.rate}%
                              </span>{' '}
                              in 7 days
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-row text-sm tracking-tight text-gray-600 pl-4">
                      <div className="block truncate pr-6">
                        {priceItem.tagStyle.map((hashTag, index) => (
                          <span key={index + 'hash2'}>#{hashTag} </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="absolute top-3 left-5 h-16 w-16 rounded-full border-2 border-white shadow-md">
                  <img
                    className="rounded-full object-cover object-center"
                    src={priceItem.imageSrc}
                    alt={priceItem.imageAlt}
                  />
                </div>
                <div className="absolute top-2 left-2 h-6 w-6 rounded-lg border-2 border-white bg-teal-200 text-sm text-teal-800 tracking-tight text-center align-text-top font-semibold">
                  {priceItem.rank}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          {rankingPriceItems11to15.map((priceItem, index) => (
            <div
              key={priceItem.imageSrc}
              onClick={() => {
                console.log(priceItem);
              }}
            >
              <div className="relative mb-2 cursor-pointer hover:shadow-lg border-transparent border hover:border-meu-400 hover:border rounded-lg transition-transform transform hover:-translate-y-1">
                <div className="shadow rounded-lg py-3 pr-5 bg-white">
                  <div className="flex h-18 pl-24 flex-row justify-between items-center">
                    <div className="w-full">
                      <div>
                        <h6 className="text-2xl tracking-tight font-bold block truncate">
                          {priceItem.name}
                        </h6>
                      </div>
                      <div className="flex flex-row justify-between items-center">
                        <div>
                          <h4 className="text-2xl font-semibold text-left inline-block text-meu-500">
                            {priceItem.priceNow}
                          </h4>
                          <p className="ml-3 inline-block font-semibold">
                            Flow
                          </p>
                        </div>
                        <div>
                          <ArrowRightCircleIcon
                            className="-mr-3 h-6 w-6 inline-block text-gray-300 font-bold hover:text-meu-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>

                      <div className="flex flex-row justify-end text-base min-w-fit">
                        {priceItem.rate > 0 ? (
                          <>
                            <span className="mr-1">
                              <ArrowTrendingUpIcon
                                className="mx-1 h-6 w-6 inline-block text-red-400 font-bold"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="text-gray-500">
                              <span className="text-red-400 font-bold">
                                {priceItem.rate}%
                              </span>{' '}
                              in 7 days
                            </p>
                          </>
                        ) : priceItem.rate < 0 ? (
                          <>
                            <span className="mr-1">
                              <ArrowTrendingDownIcon
                                className="mx-1 h-6 w-6 inline-block text-blue-400 font-bold"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="text-gray-500">
                              <span className="text-blue-400 font-bold">
                                {priceItem.rate}%
                              </span>{' '}
                              in 7 days
                            </p>
                          </>
                        ) : (
                          <>
                            <span className="mr-1">
                              <FontAwesomeIcon
                                icon={faSquareParking}
                                className="mx-1 h-6 w-6 inline-block text-gray-400 font-bold"
                                aria-hidden="true"
                              />
                            </span>
                            <p className="text-gray-500">
                              <span className="text-gray-400 font-bold">
                                {priceItem.rate}%
                              </span>{' '}
                              in 7 days
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-row text-sm tracking-tight text-gray-600 pl-4">
                      <div className="block truncate pr-6">
                        {priceItem.tagStyle.map((hashTag, index) => (
                          <span key={index + 'hash3'}>#{hashTag} </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="absolute top-3 left-5 h-16 w-16 rounded-full border-2 border-white shadow-md">
                  <img
                    className="rounded-full object-cover object-center"
                    src={priceItem.imageSrc}
                    alt={priceItem.imageAlt}
                  />
                </div>
                <div className="absolute top-2 left-2 h-6 w-6 rounded-lg border-2 border-white bg-teal-200 text-sm text-teal-800 tracking-tight text-center align-text-top font-semibold">
                  {priceItem.rank}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Button:: Go to Rankings */}
      <div className="max-w-2xl mx-auto text-center rounded-md">
        <a
          href="#"
          className="inline-flex items-center justify-center px-5 py-3 -mt-2 border border-transparent text-base font-bold rounded-md text-white bg-meu-500 hover:bg-meu-600"
        >
          Go to Rankings
        </a>
      </div>
      {/* Title:: Trending Collections */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-32">
        <h2 className="text-2xl font-bold text-center leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Trending
          <a href="#" className="text-meu-500 hover:text-meu-600">
            <span>
              {' '}
              Collections
              <ChevronDownIcon
                className="-mt-1 ml-1 h-5 w-5 inline-block"
                aria-hidden="true"
              />
            </span>
          </a>
        </h2>
      </div>
      {/* Trending Collections List */}
      <Swiper
        {...swiperParamsTrending}
        style={swiperStyle}
        ref={setSwiper}
        className="max-w-7xl mx-auto px-4 sm:px-6 mt-8"
      >
        {trendingItems.map((item, index) => (
          <SwiperSlide
            key={item.imageSrc}
            onClick={() => {
              console.log(item);
              if (item.nameCreation === 'Carnman') {
                navigate('/channel');
              }
            }}
          >
            <div className="wrapper bg-white m-8 antialiased text-gray-900 transition-transform transform hover:-translate-y-2 hover:scale-105 group">
              {/* <div className="max-w-xs rounded-lg overflow-hidden shadow-lg my-2"> */}
              <div className="border rounded-lg cursor-pointer group-hover:border-meu-500">
                <img
                  className="w-full rounded-t-lg object-cover object-center shadow-md"
                  alt={item.imageAlt}
                  src={item.imageSrc} // 360 x 200 px ===> only size..
                />
                <div className="px-6 py-4">
                  <div className="text-center -mt-12 group-hover:animate-bounce">
                    <span className="border-2 border-gray-200 rounded-full mx-auto inline-block bg-white">
                      <img
                        className="rounded-full w-14 h-14"
                        src={item.imageProfileSrc}
                        alt={item.imageProfileAlt}
                      />
                    </span>
                  </div>
                  <div className="font-bold text-xl mb-2 text-center">
                    {item.nameCreation}
                  </div>
                  <div className="text-center font-semibold text-slate-300">
                    by{' '}
                    <span className="text-meu-500 hover:text-meu-600">
                      {item.nameProfile}
                    </span>
                  </div>
                  {/* <p className="h-20 mt-5 pt-1 text-grey-darker text-base text-center line-clamp-3 hover:line-clamp-none">{item.txtIntro}</p> */}
                  <p className="h-20 mt-5 pt-1 text-etc-gray font-medium text-base text-center tracking-tight line-clamp-3 overflow-hidden">
                    {item.txtIntro}
                  </p>
                </div>
                <div className="px-6 py-4 block truncate">
                  {item.tagStyle.map((hashTag, index) => (
                    <span
                      key={index + 'hash4'}
                      className="inline-block bg-slate-200 rounded-lg px-2 py-1 text-sm font-semibold text-slate-500 mr-2 hover:bg-teal-200 hover:text-teal-800"
                    >
                      #{hashTag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div>
          <button ref={navgationPrevRef}>
            {/* <FontAwesomeIcon icon={faCircleChevronLeft} className="h-12 w-12" aria-hidden="true" /> */}
            <FontAwesomeIcon
              icon={faCircleChevronLeft}
              className="absolute top-1/2 z-10 cursor-pointer flex items-center justify-center h-12 w-12 -mt-8 text-meu-500 hover:text-meu-600 left-1 right-auto"
              aria-hidden="true"
            />
          </button>
          <button ref={navgationNextRef}>
            {/* <FontAwesomeIcon icon={faCircleChevronRight} className="h-12 w-12" aria-hidden="true" /> */}
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              className="absolute top-1/2 z-10 cursor-pointer flex items-center justify-center h-12 w-12 -mt-8 text-meu-500 hover:text-meu-600 right-1 left-auto"
              aria-hidden="true"
            />
          </button>
        </div>
        {/* <div className="swiper-pagination pagination_bullet"></div>
        <div className="swiper-pagination pagination_progress"></div> */}
      </Swiper>
      {/* Create and sell your Emojis .. */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-14 pt-14 bg-meu-50 rounded-t-2xl">
        <h2 className="text-2xl font-bold text-center leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Create and sell your Emojis
        </h2>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 pt-20 bg-meu-50 pb-20 rounded-b-2xl">
        <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 ">
          {/* 1. Set up your Wallet */}
          <li className="flex items-center flex-col">
            <div className=" relative w-full">
              <div className="absolute w-12 h-12 left-1/2 -ml-6">
                {/* <div className="flex flex-row w-full h-full">
                  <div className="w-1/2 h-full overflow-hidden">
                    <icos.WalletIcon className="h-12 w-12 text-meu-500 " />
                  </div>
                  <div className="w-1/2 h-full overflow-hidden">
                    <icos.WalletIcon className="h-12 w-12 text-blue-500 -ml-6" />
                  </div>
                </div> */}
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-2.5 overflow-hidden">
                    <icos.WalletIcon className="h-12 w-12 text-purple-500 " />
                  </div>
                  <div className="w-full h-2 overflow-hidden">
                    <icos.WalletIcon className="h-12 w-12 text-meu-500 -mt-2.5" />
                  </div>
                  <div className="w-full h-7 overflow-hidden">
                    <icos.WalletIcon className="h-12 w-12 text-amber-400 -mt-[18px]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 text-center font-semibold tracking-tight">
              Set up your wallet
            </div>
            <div className="text-etc-gray font-medium text-base text-center tracking-tight mt-3 px-4">
              To use your Blocto wallet,{' '}
              <span
                onClick={() => {
                  fcl.authenticate();
                }}
                className="hover:text-amber-600 text-amber-500 font-semibold hover:cursor-pointer hover:underline hover:underline-offset-4"
              >
                Sign up or Sign in.
              </span>{' '}
              Email verification will be required. Then, click{' '}
              <NavLink
                to="mywallet"
                className="hover:text-amber-600 text-amber-500 font-semibold hover:cursor-pointer hover:underline hover:underline-offset-4"
              >
                MyWallet
              </NavLink>{' '}
              in the upper right corner to go to your own menu.
            </div>
          </li>
          {/* 2. Create your Collection */}
          <li className="flex items-center flex-col">
            <div className=" relative w-full">
              <div className="absolute w-12 h-12 left-1/2 -ml-6">
                <div className="flex flex-row w-full h-full">
                  <div className="w-1/2 h-full overflow-hidden">
                    <div className="w-full h-7 overflow-hidden">
                      <icos.RectangleGroupIcon className="h-12 w-12 text-purple-500 " />
                    </div>
                    <div className="w-full h-5 overflow-hidden">
                      <icos.RectangleGroupIcon className="h-12 w-12 text-meu-500 -mt-7" />
                    </div>
                  </div>
                  <div className="w-1/2 h-full overflow-hidden">
                    <icos.RectangleGroupIcon className="h-12 w-12 text-amber-500 -ml-6" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 text-center font-semibold tracking-tight">
              Create your collection
            </div>
            <div className="text-etc-gray font-medium text-base text-center tracking-tight mt-3 px-4">
              Connect your wallet to MetaUzu. Add social links, a description,
              profile & banner images, and set secondary sales commissions and
              more.
            </div>
          </li>
          {/* 3. Add your NFTs */}
          <li className="flex items-center flex-col">
            <div className=" relative w-full">
              <div className="absolute w-12 h-12 left-1/2 -ml-6">
                <div className="flex flex-row w-full h-full">
                  <div className="w-1/3 h-full overflow-hidden">
                    <icos.PhotoIcon className="h-12 w-12 text-purple-500 " />
                  </div>
                  <div className="w-1/3 h-full overflow-hidden">
                    <icos.PhotoIcon className="h-12 w-12 text-meu-500 -ml-4" />
                  </div>
                  <div className="w-1/3 h-full overflow-hidden">
                    <icos.PhotoIcon className="h-12 w-12 text-amber-500 -ml-8" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 text-center font-semibold tracking-tight">
              Add your NFTs
            </div>
            <div className="text-etc-gray font-medium text-base text-center tracking-tight mt-3 px-4">
              Upload your work (Emoji), add a title and description, and
              customize your NFTs with properties. In your{' '}
              <NavLink
                to="atelier"
                className="hover:text-amber-600 text-amber-500 font-semibold hover:cursor-pointer hover:underline hover:underline-offset-4"
              >
                Atelier.
              </NavLink>
            </div>
          </li>
          {/* 4. Play your Emotions */}
          <li className="flex items-center flex-col">
            <div className=" relative w-full">
              <div className="absolute w-12 h-12 left-1/2 -ml-6">
                <div className="flex flex-row w-full h-full p-1.5">
                  <div className="w-1/3 h-full overflow-hidden">
                    <icos.PlayIcon className="h-9 w-9 text-white bg-purple-500 rounded-l-lg" />
                  </div>
                  <div className="w-1/3 h-full overflow-hidden">
                    <icos.PlayIcon className="h-9 w-9 text-white bg-meu-500 -ml-3" />
                  </div>
                  <div className="w-1/3 h-full overflow-hidden">
                    <icos.PlayIcon className="h-9 w-9 text-white bg-amber-500 -ml-6 rounded-r-lg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 text-center font-semibold tracking-tight">
              Play your Emotions
            </div>
            <div className="text-etc-gray font-medium text-base text-center tracking-tight mt-3 px-4">
              Your emoji will start to be sold to users and you will be able to
              earn money. And all that remains is to convey emotions through
              MetaUzu. Let's Play & joy.
            </div>
          </li>
        </ul>
      </div>
      {/* Meet MetaUzu .. Youtube .. */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-24">
        <h2 className="text-4xl font-bold text-center leading-7 text-gray-900 sm:text-5xl sm:truncate">
          <span className="text-meu-500">Play</span> MetaUzu
          {/* <span className="text-amber-500">Play</span> <span className="text-meu-500">MetaUzu</span> */}
        </h2>
        <div className="text-etc-gray font-medium text-base text-center mt-5 mb-14">
          The NFT marketplace with everything for everyone
        </div>
        <div className="w-full">
          <div className="max-w-4xl mx-auto">
            <div className="aspect-w-16 aspect-h-9 border-2 border-purple-400 rounded-2xl overflow-hidden">
              <iframe
                src="https://www.youtube.com/embed/-vrmxz_JeGQ"
                title="YouTube video player"
                width="560"
                height="315"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* Button:: Go to Rankings */}
      <div className="max-w-2xl mx-4 sm:mx-auto text-center rounded-md mt-10 sm:mt-20 mb-28">
        <NavLink
          to="marketplace"
          className="block sm:inline-flex items-center justify-center px-5 py-4 mt-0 sm:-mt-2 border border-transparent text-base font-bold rounded-md text-white bg-amber-500/80 hover:bg-amber-600/80 sm:mr-10"
        >
          Get the Application
        </NavLink>
        <NavLink
          to="marketplace"
          className="block sm:inline-flex items-center justify-center px-5 py-4 mt-5 sm:-mt-2 border border-transparent text-base font-bold rounded-md text-white bg-purple-400 hover:bg-purple-500"
        >
          Explore the Marketplace
        </NavLink>
      </div>
      {/* Partners, Sponser, Backer .. */}
      <div className="mx-auto mt-20 px-10 py-5 text-center bg-meu-400">
        <a
          href="#"
          className="inline-flex items-center justify-center px-7 py-3 text-xl font-bold text-gray-600  hover:text-white"
        >
          <span>Flowverse</span>
        </a>
        <a
          href="#"
          className="inline-flex items-center justify-center px-7 py-3 text-xl font-bold text-gray-600  hover:text-white"
        >
          <span>SAMSUNG</span>
        </a>
        <a
          href="#"
          className="inline-flex items-center justify-center px-7 py-3 text-xl font-bold text-gray-600  hover:text-white"
        >
          <span>
            <FontAwesomeIcon
              icon={faFacebookMessenger}
              className=" h-5 mr-1.5 -mb-0.5"
              aria-hidden="true"
            />
            Messenger
          </span>
        </a>
        <a
          href="#"
          className="inline-flex items-center justify-center px-7 py-3 text-xl font-bold text-gray-600  hover:text-white"
        >
          <span>Dapper</span>
        </a>
        <a
          href="#"
          className="inline-flex items-center justify-center px-7 py-3 text-xl font-bold text-gray-600  hover:text-white"
        >
          <span>coinbase</span>
        </a>
        <a
          href="#"
          className="inline-flex items-center justify-center px-7 py-3 text-xl font-bold text-gray-600  hover:text-white"
        >
          <span># HASHED</span>
        </a>
        <a
          href="#"
          className="inline-flex items-center justify-center px-7 py-3 text-xl font-bold text-gray-600  hover:text-white"
        >
          <span>
            <FontAwesomeIcon
              icon={faTwitch}
              className=" h-5 mr-1.5 -mb-0.5"
              aria-hidden="true"
            />
            Twitch
          </span>
        </a>
      </div>
      <Footer />
    </div>
  );
}

export default Home;

import './App.css';

import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import {useState, useEffect} from 'react';
import {meuGetNFTsDeedScript} from "./cadence/scripts/meu_get_nfts_deeds.js";
import {meuDeedSetPriceTx} from "./cadence/transactions/meu_deed_set_price"
import {deedListForSaleTx} from "./cadence/transactions/meu_deed_list_for_sale";

import {serverAuthorization} from './serverSigner';

function MeuCollectionDeeds(props) {
  // console.log(props.address)

  const [nfts, setNFTs] = useState([]);
  const [prices, setPrices] = useState([]);
  const [pricesOfDeed, setPricesOfDeed] = useState([]);

  useEffect(() => {
    getUserNFTs();
  }, [])

  const getUserNFTs = async () => {
    try {
      const result = await fcl.send([
          fcl.script(meuGetNFTsDeedScript),
          fcl.args([
              fcl.arg(props.address, t.Address),
              fcl.arg(process.env.REACT_APP_FCL_MEU_ADDRESS, t.Address)
          ])
      ]).then(fcl.decode);

      console.log(result);
      setNFTs(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }

  const setPriceOfDeed = async (cid, price) => {
    try {
      const transactionId = await fcl.send([
        fcl.transaction(meuDeedSetPriceTx),
        fcl.args([
          fcl.arg(cid, t.UInt64),
          fcl.arg(price, t.UFix64),
        ]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz, serverAuthorization]),
        fcl.limit(9999)
      ]).then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }

  const deedListForSale = async (id, cid, price) => {
    try {
      const transactionId = await fcl.send([
        fcl.transaction(deedListForSaleTx),
        fcl.args([
          fcl.arg(id, t.UInt64),
          fcl.arg(cid, t.UInt64),
          fcl.arg(price, t.UFix64)
        ]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz, serverAuthorization]),
        fcl.limit(9999)
      ]).then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
 
  return (
    <div style={{backgroundColor: 'lightgray'}}>
      {props.target === 'mine' 
        ? props.address === process.env.REACT_APP_FCL_MEU_ADDRESS ? <><h3>[ Admin's Products-Deed.. (Admin's Collections.. ]</h3><h5>[ Address : {props.address} ]</h5></>
        : <><h3>[ Logged in User's Products-Deed.. (User's Collections..) ]</h3><h5>[ Address : {props.address} ]</h5></>
        : <><h3>[ Target User's Products-Deed.. (User's Collections..) ]</h3><h5>[ Address : {props.address} ]</h5></>
      }
      
      {nfts.map(nft => (
          <div style={{margin:"50px"}} key={nft.nft.id} className="flex flex-col justify-center justify-items-center items-center">
              <img style={{width: "100px"}} src={`https://ipfs.io/ipfs/${nft.nft.ipfsHash}`} /><br/>
              <h3 style={{margin:"0"}}>[id] {nft.nft.id}</h3>
              <h3 style={{margin:"0"}}>[cid] {nft.nft.cid}</h3>
              currentPrice of Replica::<input style={{margin:"0"}} type="text" value={nft.price} disabled={true}/><br/>
              {props.target === 'mine' && props.address !== process.env.REACT_APP_FCL_MEU_ADDRESS 
                ? <>
                    setPrice of Replica::<input style={{margin:"0"}} type="text" onChange={(e) => {prices[nft.nft.id]=(e.target.value)} } />
                    <button onClick={() => setPriceOfDeed(nft.nft.cid, prices[nft.nft.id])}>Set Price</button><br/><br/>
                    setPrice of Deed(float)::<input type="text" onChange={(e) => {pricesOfDeed[nft.nft.id]=(e.target.value)} } />
                    <button onClick={() => deedListForSale(nft.nft.id, nft.nft.cid, pricesOfDeed[nft.nft.id])}>Lift NFT for Sale</button>
                  </>
                : <></> }
          </div>
      ))}
    </div>
  );
}

export default MeuCollectionDeeds;
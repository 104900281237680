import * as fcl from '@onflow/fcl';
import * as types from '@onflow/types';

import { useState, useEffect, useContext } from 'react';
import { Context } from '../Context';

import * as icos from '@heroicons/react/24/solid'
import * as icoo from "@heroicons/react/24/outline"

import { meuGetCollectionCheckTx } from '../cadence/scripts/meu_get_collections_check_v1';
import { meuDeleteAllCollectionTx } from '../cadence/transactions/meu_delete_all_collection';
import { meuCreateAllCollectionTx } from '../cadence/transactions/meu_create_all_collection';

import {serverAuthorization} from '../serverSigner';

// const namesCollectionAdmin=[
//   "MetaUzuCollection_Emojia_Central",
  
//   "MetaUzuCollection_Emojia_Deed",
//   "MetaUzuCollection_Market_Deed",
//   "MetaUzuCollection_Emojia_Ticket_Deed",

//   "MetaUzuCollection_Emojia_Replica",
//   "MetaUzuCollection_Market_Replica",
//   "MetaUzuCollection_Emojia_Ticket_Replica",
// ]

// const namesCollectionUser=[
//   "MetaUzuCollection_Emojia_Deed",
//   "MetaUzuCollection_Market_Deed",
//   "MetaUzuCollection_Emojia_Ticket_Deed",

//   "MetaUzuCollection_Emojia_Replica",
//   "MetaUzuCollection_Market_Replica",
//   "MetaUzuCollection_Emojia_Ticket_Replica",
// ]

export function Check() {
  /*******************************************************
  * 화면 공통사용 함수 선언
  *******************************************************/
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  const MARKET_ADDRESS = process.env.REACT_APP_FCL_MEU_ADDRESS;   // admin's address

  const { user, setUser } = useContext(Context);

  const [ arrCollectionAdmin, setArrCollectionAdmin] = useState([])
  const [ arrCollectionUser, setArrCollectionUser ] = useState([])
  const [ isCollectionUser, setIsCollectionUser ] = useState(false)
  const [ isCollectionAdmin, setIsCollectionAdmin ] = useState(false)
  
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [txtConfirm, setTxtConfirm] = useState("")

  const [isConfirmDeleteAdmin, setIsConfirmDeleteAdmin] = useState(false)
  const [txtConfirmAdmin, setTxtConfirmAdmin] = useState("")
  
  // Collection Path 관련 Docs >> https://docs.onflow.org/cadence/language/accounts/#path-functions
  const scriptGetCollectionCheckAdmin = async () => {
    try {
      const result = await fcl.send([
        fcl.script(meuGetCollectionCheckTx),
        fcl.args([
          // fcl.arg(user.addr, types.Address),
          fcl.arg(MARKET_ADDRESS, types.Address),
          // fcl.arg(namesCollectionAdmin, types.Array(types.String)),
          
        ]),
      ]).then(fcl.decode);
        
      console.log('scriptGetCollectionCheckAdmin result ===> ', result);
      setArrCollectionAdmin(prevList => [...result])

      let exists = true
      for (let i = 1; i < result.length; i++){
        exists = exists && result[i].exists
      }
      setIsCollectionAdmin(exists)

    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };
  const scriptGetCollectionCheckUser = async () => {
    try {
      const result = await fcl.send([
        fcl.script(meuGetCollectionCheckTx),
        fcl.args([
          // fcl.arg(user.addr, types.Address),
          fcl.arg(user.addr, types.Address),
          // fcl.arg(namesCollectionUser, types.Array(types.String)),
          
        ]),
      ]).then(fcl.decode);
        
      console.log('scriptGetCollectionCheckUser result ===> ', result);
      setArrCollectionUser(prevList => [...result])

      let exists = true
      for (let i = 1; i < result.length; i++){
        exists = exists && result[i].exists
      }
      setIsCollectionUser(exists)

    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };
  const deleteAllCollectionUser = async () => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuDeleteAllCollectionTx),
          fcl.args([]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };
  const createAllCollectionAdmin = async () => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuCreateAllCollectionTx),
          fcl.args([fcl.arg(true, types.Bool)]),
          fcl.payer(serverAuthorization),
          fcl.proposer(serverAuthorization),
          fcl.authorizations([serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };
  const deleteAllCollectionAdmin = async () => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuDeleteAllCollectionTx),
          fcl.args([]),
          fcl.payer(serverAuthorization),
          fcl.proposer(serverAuthorization),
          fcl.authorizations([serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };

  /*******************************************************
  * useEffect 함수 사용 부분
  *******************************************************/
  /*** 최초 페이지 로딩 후, 1회만 실행 ***/
  useEffect(() => {
    scriptGetCollectionCheckAdmin()
    scriptGetCollectionCheckUser()
  }, [user]);
  

  /*******************************************************
  * 최종 페이지 리턴 :: user 값의 여부에 따라, 페이지 리턴
  *******************************************************/
  /*** user 값이 없을 경우, / 페이지로 이동 ***/
  if (!user?.addr) {
    // location.href = '/';
    return <>
      <div className="bg-white p-12 py-8 w-full flex flex-col justify-center items-center">
        <span className="font-semibold">To completely use MEU's services, please Sign in.</span>
        <button className="py-2 px-4 sm:px-8 ml-4 bg-meu-500 hover:bg-meu-600 text-white rounded-md mt-5"
          onClick={() => {
            // location.href = '/mywallet'
            fcl.authenticate();
          }}
        >
          Sign In
        </button>
      </div>
    </>;
  }
  /*** user 값이 있는 경우, 해당페이지 출력 ***/
  if (user.addr)
    return (
      <>
        <ul className="flex flex-col justify-center items-center w-full bg-meu-50 max-w-3xl mx-auto mt-10 py-10 rounded-md text-sm">
          <li className="text-lg font-semibold">[ Administrator(Market) Collections Status ]</li>
          <li>&nbsp;</li>
          {arrCollectionAdmin.map((collection, index) => {
            return (
              <li key={index} className="flex w-full justify-center">
                <div className="w-3/4 text-center">{collection.name}</div>
                <div className={classNames(!collection.exists ? "text-rose-500" : "" , "w-1/4 text-center")}>{collection.exists ? "Exists" : "None" }</div>
              </li>
            )
          })}
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li className="text-lg font-semibold">[ User Collections Status ]</li>
          <li>&nbsp;</li>
          {arrCollectionUser.map((collection, index) => {
            return (
              <li key={index} className="flex w-full justify-center">
                <div className="w-3/4 text-center">{collection.name}&nbsp;{index===0 && "(Not needed)"}</div>
                <div className={classNames((!collection.exists && (index!==0)) ? "text-rose-500" : "" , "w-1/4 text-center")}>{collection.exists ? "Exists" : "None" }</div>
              </li>
            )
          })}
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li className="py-2 bg-meu-500 text-white w-full text-center text-lg cursor-pointer hover:bg-meu-600"
            onClick={() => {
              scriptGetCollectionCheckAdmin()
              scriptGetCollectionCheckUser()
            }}
          >
            Reload
          </li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>If you wish to delete, type " Yes, okay. " below.</li>
          <li className="w-full inset-y-0">
            <input type={"text"} placeholder={"Yes, okay."} value={txtConfirm} className="p-2 py-1.5 w-full text-sm text-center  bg-meu-50 placeholder-meu-300 text-gray-500 focus:ring-meu-500 border border-meu-500  focus:border-meu-500"
              onChange={(e) => {
                const { placeholder, value } = e.target
                setTxtConfirm(value)
                if (e.target.value === placeholder) {
                  setIsConfirmDelete(true)
                } else {
                  setIsConfirmDelete(false)
                }
              }}
            />
          </li>
          <li>&nbsp;</li>
          <li className="w-full">
            <button className="py-2 bg-rose-500 text-white w-full text-center text-lg cursor-pointer hover:bg-rose-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
              disabled={!isConfirmDelete || !isCollectionUser}
              onClick={() => {
              deleteAllCollectionUser()
            }}>
              Delete User's All collections
            </button>
            <p className="text-center text-purple-500/80">
              { !isCollectionUser && "Since the collection does not exist, it cannot be deleted."}
            </p>
          </li>
          </ul>
          { (user?.addr === "0x7d4f6586d2f3e790") && <>
          <ul className="flex flex-col justify-center items-center w-full bg-indigo-50 max-w-3xl mx-auto mt-10 py-10 rounded-md text-sm">
            <li className="text-center w-full text-xl text-purple-500 font-semibold">[ Admin's Menu of Collections ]</li>
            <li>&nbsp;</li>
            <li className="w-full">
              <button className="w-full cursor-pointer bg-purple-500 text-white hover:bg-purple-600 text-center text-lg py-2 disabled:bg-gray-400 disabled:cursor-not-allowed"
                disabled={isCollectionAdmin}
                onClick={() => {
                  createAllCollectionAdmin()
              }}>
                Create Admin's All collections
              </button>
              <p className="text-center text-purple-500/80">
                { isCollectionAdmin && "The Admin's collection already exists."}
              </p>
            </li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li>If you wish to delete, type " I'm ready to clear the admin's collections. " below.</li>
            <li className="w-full inset-y-0">
              <input type={"text"} placeholder={"I'm ready to clear the admin's collections."} value={txtConfirmAdmin} className="p-2 py-1.5 w-full text-sm text-center  bg-purple-50 placeholder-purple-300 text-gray-500 focus:ring-purple-500 border border-purple-500  focus:border-purple-500"
                onChange={(e) => {
                  const { placeholder, value } = e.target
                  setTxtConfirmAdmin(value)
                  if (e.target.value === placeholder) {
                    setIsConfirmDeleteAdmin(true)
                  } else {
                    setIsConfirmDeleteAdmin(false)
                  }
                }}
              />
            </li>
            <li>&nbsp;</li>
            <li className="w-full">
            <button className="py-2 bg-rose-500 text-white w-full text-center text-lg cursor-pointer hover:bg-rose-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
              disabled={!isConfirmDeleteAdmin || !isCollectionAdmin}
              onClick={() => {
              deleteAllCollectionAdmin()
            }}>
              Delete Admin's All collections
            </button>
            <p className="text-center text-purple-500/80">
              { !isCollectionAdmin && "Since the collection does not exist, it cannot be deleted."}
            </p>
          </li>
            </ul>  
          </>}
          

        
        
        
      </>
    );
}

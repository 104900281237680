// https://docs.onflow.org/cadence/language/accounts/ >> key 제거하거나 할때..

import * as fcl from '@onflow/fcl';
import * as types from '@onflow/types';

import {
  BrowserRouter,
  Routes,
  Route,
  Switch,
  NavLink,
} from 'react-router-dom';
import MyWallet from './page/MyWallet';
import { useState, useEffect, useRef } from 'react';

// import {Swiper, SwiperSlide} from "swiper/react"
// import "swiper/swiper-bundle.min.css"
// import "swiper/swiper.min.css"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  Controller,
  Thumbs,
  Scrollbar,
} from 'swiper';

import { meuTestScriptCallTx } from './cadence/scripts/meu_test_script_call';
import _ from 'loadsh';
import { Header } from './components/Header';
import { Blinder } from './components/Blinder';

import { swiperBreakpoint, swiperStyle } from './config/options';
import {
  notableItems,
  rankingPriceItems11to15,
  rankingPriceItems1to5,
  rankingPriceItems6to10,
  trendingItems,
} from './config/sample';
import Footer from './components/Footer';
import Home from './page/Home';
import Marketplace from './page/Marketplace';
import { Item } from './page/Item';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Example from './page/Example';
// import { Create } from './page/Create';
import { Atelier } from './page/Atelier';

import { Check } from './page/Check';

import ScrollToTop from "./components/ScrollToTop"
import Channel from './page/Channel';

library.add(
  fab,
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
);


function App() {
  const [user, setUser] = useState(null);
  // SwiperCore.use([Navigation, Scrollbar, Pagination, Controller])
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const navgationPrevRef = useRef(null);
  const navgationNextRef = useRef(null);

  // MEU Customs..
  const [nameArticleOfCentralNFT, setNameArticleOfCentralNFT] = useState('');
  const [nameAuthorOfCentralNFT, setNameAuthorOfCentralNFT] = useState('');
  const [descOfCentralNFT, setDescOfCentralNFT] = useState('');
  const [priceOfCentralNFT, setPriceOfCentralNFT] = useState();

  const [fileCentral, setFileCentral] = useState();
  const [txtTargetUserAddress, setTxtTargetUserAddress] = useState();
  const [targetUserAddress, setTargetUserAddress] = useState('');
  const [loggedInUserAddress, setLoggedInUserAddress] = useState('');

  SwiperCore.use([Navigation, Scrollbar, Pagination, Controller]);

  useEffect(() => {
    // sets the `user` variable to the person that is logged in through Blocto
    fcl.currentUser().subscribe(setUser);
  }, []);

  const swiperParamsNotable = {
    navigation: true,
    onSwiper: setSwiper, // useState 에 등록된 swiper 를 이용해, swiper.slideTo() 같은 method 사용 가능.
    onSlideChange: (e) => {
      setMainImageIndex(e.activeIndex);
    }, // slide가 이동할때 발생하는 이벤트 핸들러로, 현재 어떤 슬라이드 메인에 나와있는지 index 값을 저장하는 용도로 사용.
    spaceBetween: 20, // px 단위 간격
    navigation: {
      // 좌우 버튼 커스텀
      prevEl: navgationPrevRef.current ? navgationPrevRef.current : undefined, // 이전 버튼 Ref 또는 className
      nextEl: navgationNextRef.current ? navgationNextRef.current : undefined, // 다음 버튼 Ref 또는 className
    },
    scrollbar: {
      // 스크롤바 커스텀
      draggable: true, // 드래그 가능 여부
      el: null, // 스크롤바 Ref 또는 className
    },
    slidesPerView: 1, // 한 화면에 보이는 슬라이드 수
    onBeforeInit: (swiper) => {
      // 초기 설정, 이벤트 핸들러
      swiper.params.navigation.prevEl = navgationPrevRef.current;
      swiper.params.navigation.nextEl = navgationNextRef.current;
      swiper.navigation.update();
    },
    breakpoints: swiperBreakpoint,
    // 추후 pagination 구현할것
  };

  const swiperParamsTrending = {
    navigation: true,
    onSwiper: setSwiper, // useState 에 등록된 swiper 를 이용해, swiper.slideTo() 같은 method 사용 가능.
    onSlideChange: (e) => {
      setMainImageIndex(e.activeIndex);
    }, // slide가 이동할때 발생하는 이벤트 핸들러로, 현재 어떤 슬라이드 메인에 나와있는지 index 값을 저장하는 용도로 사용.
    spaceBetween: 0, // px 단위 간격
    navigation: {
      // 좌우 버튼 커스텀
      prevEl: navgationPrevRef.current ? navgationPrevRef.current : undefined, // 이전 버튼 Ref 또는 className
      nextEl: navgationNextRef.current ? navgationNextRef.current : undefined, // 다음 버튼 Ref 또는 className
    },
    scrollbar: {
      // 스크롤바 커스텀
      draggable: true, // 드래그 가능 여부
      el: null, // 스크롤바 Ref 또는 className
    },
    slidesPerView: 1, // 한 화면에 보이는 슬라이드 수
    onBeforeInit: (swiper) => {
      // 초기 설정, 이벤트 핸들러
      swiper.params.navigation.prevEl = navgationPrevRef.current;
      swiper.params.navigation.nextEl = navgationNextRef.current;
      swiper.navigation.update();
    },
    breakpoints: swiperBreakpoint,
    // 추후 pagination 구현할것
    pagination: {
      // el: ".pagination_bullet", //페이징 태그 클래스 설정
      clickable: true, //버튼 클릭 여부
      type: 'bullets', //페이징 타입 설정(종류: bullets, fraction, progressbar)
      // Bullet Numbering 설정
      // renderBullet: function (index, className) {
      //   return '<span className="' + className + '">' + (index + 1) + "</span>";
      // },
    },
    loop: false,
  };

  const meuTestScriptCall = async () => {
    try {
      const result = await fcl
        .send([
          fcl.script(meuTestScriptCallTx),
          fcl.args([fcl.arg(process.env.REACT_APP_FCL_MEU_ADDRESS, types.Address)]),
        ])
        .then(fcl.decode);
      console.log('meuTestScriptCall');
      console.log(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
    
  };

  // pakt87

  /***********************************
   * MEU Customs..
   ***********************************/
  const loadMyCerts = () => {
    console.log('btn loadMyCerts clicked');
    setLoggedInUserAddress(user.addr);
  };
  const loadAdminCerts = () => {
    setLoggedInUserAddress(process.env.REACT_APP_FCL_MEU_ADDRESS);
  };

  return (
    <div className="App relative h-full">
      <BrowserRouter>
        {/* ScrollToTop 이 제대로 작동이 안되고 있음.. 추후 손봐야할듯.. */}
        <ScrollToTop />       
        <Blinder />
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/item" element={<Item />} />
          <Route path="/mywallet" element={<MyWallet />} />
          <Route path="/example" element={<Example />} />
          <Route path="/atelier" element={<Atelier />} />
          <Route path="/channel" element={<Channel />} />
          <Route path="/check" element={<Check />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

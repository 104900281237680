import './App.css';

import * as fcl from '@onflow/fcl';
import * as t from '@onflow/types';
import { useState, useEffect } from 'react';
import { meuGetNFTsReplicaScript } from './cadence/scripts/meu_get_nfts_replicas';
import { meuReplicaListForSaleTx } from './cadence/transactions/meu_replica_list_for_sale';
import { serverAuthorization } from './serverSigner';

function MeuCollectionReplicas(props) {
  const [nfts, setNFTs] = useState([]);
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    getUserNFTs();
  }, []);

  const getUserNFTs = async () => {
    try {
      const result = await fcl
        .send([
          fcl.script(meuGetNFTsReplicaScript),
          fcl.args([
            fcl.arg(props.address, t.Address),
            // fcl.arg(process.env.REACT_APP_FCL_MEU_ADDRESS, t.Address)
          ]),
        ])
        .then(fcl.decode);
  
      console.log(result);
      setNFTs(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };

  const replicaListForSale = async (id, cid, nid, price) => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuReplicaListForSaleTx),
          fcl.args([
            fcl.arg(id, t.UInt64),
            fcl.arg(cid, t.UInt64),
            fcl.arg(nid, t.UInt64),
            fcl.arg(price, t.UFix64),
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };

  return (
    <div style={{ backgroundColor: 'turquoise' }}>
      {props.target === 'mine' ? (
        props.address === process.env.REACT_APP_FCL_MEU_ADDRESS ? (
          <>
            <h3>[ Admin's Products-Replica.. (Admin's Collections.. ]</h3>
            <h5>[ Address : {props.address} ]</h5>
          </>
        ) : (
          <>
            <h3>
              [ Logged in User's Products-Replica.. (User's Collections..) ]
            </h3>
            <h5>[ Address : {props.address} ]</h5>
          </>
        )
      ) : (
        <>
          <h3>[ Target User's Products-Replica.. (User's Collections..) ]</h3>
          <h5>[ Address : {props.address} ]</h5>
        </>
      )}

      {nfts.map((nft) => (
        <div style={{ margin: '50px' }} key={nft.id} className="flex flex-col justify-center justify-items-center items-center">
          <img
            style={{ width: '100px' }}
            src={`https://ipfs.io/ipfs/${nft.ipfsHash}`}
          />
          <br />
          <h3 style={{ margin: '0' }}>[id ==rid] {nft.id}</h3>
          <h3 style={{ margin: '0' }}>[cid] {nft.cid}</h3>
          <h3 style={{ margin: '0' }}>[nid] {nft.nid}</h3>
          <h3 style={{ margin: '0' }}>[replicator] {nft.replicator}</h3>
          <h4 style={{ margin: '0' }}>
            [birthday-date]{' '}
            {new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }).format(parseInt(nft.metadata.birthday) * 1000)}
          </h4>
          <h4 style={{ margin: '0' }}>
            [birthday-unix] {nft.metadata.birthday}
          </h4>
          {props.target === 'mine' &&
          props.address !== process.env.REACT_APP_FCL_MEU_ADDRESS ? (
            <>
              SalePrice::
              <input
                style={{ margin: '0' }}
                type="text"
                onChange={(e) => {
                  prices[nft.id] = e.target.value;
                }}
              />
              <button
                onClick={() =>
                  replicaListForSale(nft.id, nft.cid, nft.nid, prices[nft.id])
                }
              >
                Lift for Sale
              </button>
            </>
          ) : (
            <></>
          )}

          {/* price::<input style={{margin:"0"}} type="text" value={nft.metadataAny["price"]} disabled={true}/><br/> */}
          {/* setPrice::<input style={{margin:"0"}} type="text" onChange={(e) => {prices[nft.id]=(e.target.value)} } /> */}
          {/* <button onClick={() => setPriceOfDeed(nft.cid, prices[nft.id])}>Set Price</button> */}
        </div>
      ))}
    </div>
  );
}

export default MeuCollectionReplicas;

import './App.css';

import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import {useState, useEffect} from 'react';
import {meuGetNFTsTicketDeedScript} from "./cadence/scripts/meu_get_nfts_ticket_deed";
import {meuGetNFTsTicketDeedCheckScript} from "./cadence/scripts/meu_get_nfts_ticket_deed_check";
import {meuCancleSaleDeedTx} from "./cadence/transactions/meu_deed_cancle_sale";

import {serverAuthorization} from './serverSigner';
import {meuDeedPaidTx} from "./cadence/transactions/meu_deed_paid";


function MeuCollectionDeedTickets(props) {
  // console.log(props.address)

  const [nfts, setNFTs] = useState([]);
  const [checks, setChecks] = useState([]);

  useEffect(() => {
    getUserNFTs();
    getUserSaleNFTs();
  }, [])

  const getUserNFTs = async () => {
    try {
      const result = await fcl.send([
        fcl.script(meuGetNFTsTicketDeedScript),
        fcl.args([
          fcl.arg(props.address, t.Address)
        ])
      ]).then(fcl.decode);
  
      console.log(result);
      setNFTs(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }

  const getUserSaleNFTs = async () => {
    try {
      const result = await fcl.send([
        fcl.script(meuGetNFTsTicketDeedCheckScript),
        fcl.args([
          fcl.arg(props.address, t.Address),
          fcl.arg(process.env.REACT_APP_FCL_MEU_ADDRESS, t.Address)
        ])
      ]).then(fcl.decode);
  
      console.log(result);
      setChecks(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }

  const paid = async (id, cid, did, price) => {
    try {
      const transactionId = await fcl.send([
          fcl.transaction(meuDeedPaidTx),
          fcl.args([
            fcl.arg(id, t.UInt64),    // 여기서의 id 는 tid 를 가리킴..
            fcl.arg(cid, t.UInt64),     
            fcl.arg(did, t.UInt64),
            fcl.arg(price, t.UFix64) 
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999)
        ]).then(fcl.decode);
    
        console.log(transactionId);
        return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }

  const cancleSaleDeed = async (id, did) => {
    try {
      const transactionId = await fcl.send([
        fcl.transaction(meuCancleSaleDeedTx),
        fcl.args([
          fcl.arg(id, t.UInt64),
          fcl.arg(did, t.UInt64),
        ]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz, serverAuthorization]),
        fcl.limit(9999)
      ]).then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
  
  return (
    <div style={{backgroundColor: 'lightyellow'}}>
      {props.target === 'mine' 
        ? props.address === process.env.REACT_APP_FCL_MEU_ADDRESS ? <><h3>[ Admin's Tickets-Deed.. (Admin's Collections.. ]</h3><h5>[ Address : {props.address} ]</h5></>
        : <><h3>[ Logged in User's Tickets-Deed.. (User's Collections..) ]</h3><h5>[ Address : {props.address} ]</h5></>
        : <><h3>[ Target User's Tickets-Deed.. (User's Collections..) ]</h3><h5>[ Address : {props.address} ]</h5></>
      }
      {nfts.map(nft => (
          <div style={{margin:"50px"}} key={nft.id} className="flex flex-col justify-center justify-items-center items-center">
              <h4 style={{margin:"0"}}>[id ::tid] {nft.id}</h4>
              <h4 style={{margin:"0"}}>[cid] {nft.cid}</h4>
              <h4 style={{margin:"0"}}>[did] {nft.did}</h4>
              <h4 style={{margin:"0"}}>[price] {nft.price}</h4>
              <h4 style={{margin:"0"}}>[birthday-date] { new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(parseInt(nft.metadata.birthday) * 1000) }</h4>
              <h4 style={{margin:"0"}}>[birthday-unix] {nft.metadata.birthday}</h4>
              {checks[nft.id]   // true:sailed.. false:notYet..
                ? <>
                    <h4 style={{margin:"0"}}>[check] SoldOut</h4>
                    <button onClick={() => paid(nft.id, nft.cid, nft.did, nft.price)}>Get Paid</button>
                  </>
                : <>
                    <h4 style={{margin:"0"}}>[check] OnSale..</h4>
                    <button onClick={() => cancleSaleDeed(nft.id, nft.did)}>Cancel the Sale</button>
                    <h5>{props.address}</h5>
                  </>
              }
          </div>
      ))}
    </div>
  );
}

export default MeuCollectionDeedTickets;
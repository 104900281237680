// https://docs.onflow.org/cadence/language/accounts/ >> key 제거하거나 할때..

import * as fcl from '@onflow/fcl';
import * as types from '@onflow/types';

import { BrowserRouter, Routes, Switch } from 'react-router-dom';
import { MyWallet } from './MyWallet';
import { useState, useEffect } from 'react';

import MeuCollectionMarkets from '../MeuCollectionMarkets_example';
import MeuCollectionMarketsDeeds from '../MeuCollectionMarketsDeeds';
import MeuCollectionMarketsReplicas from '../MeuCollectionMarketsReplicas';

import MeuCollectionDeeds from '../MeuCollectionDeeds';
import MeuCollectionDeedTickets from '../MeuCollectionDeedTickets';
import MeuCollectionReplicas from '../MeuCollectionReplicas';
import MeuCollectionReplicaTickets from '../MeuCollectionReplicaTickets';

import { meuMintCentralDeedNFT } from '../cadence/transactions/meu_mint_central_deed';
import { serverAuthorization } from '../serverSigner';
import { meuDeleteAllCollectionTx } from '../cadence/transactions/meu_delete_all_collection';
import { meuCreateAllCollectionTx } from '../cadence/transactions/meu_create_all_collection';

import { meuTestScriptCallTx } from '../cadence/scripts/meu_test_script_call';
import { meuTestTransactionCallTx } from '../cadence/transactions/meu_test_transaction_call';
import _ from 'loadsh';
import { Header } from '../components/Header';

/*** 기존 infura 방식.. (infura에서, deprecated 되었음.. ) ***/
// import { create } from 'ipfs-http-client';
// const client = create('https://ipfs.infura.io:5001/api/v0');
/*** 새로운 nft.storage 방식.. ***/
// const { NFTStorage, File } = require('nft.storage')  // CommonJs 방식..
// import { NFTStorage, File } from 'nft.storage'       // ES모듈 방식.. (webpack 5이상에서만 사용가능..)
import { NFTStorage, File, Blob } from 'nft.storage/dist/bundle.esm.min.js' // 현재 프로젝트가 webpack4 버전이라, 해당방식으로 사용함..
const client = new NFTStorage({
  // endpoint: 'https://api.nft.storage',   // <== 이부분을 넣어주면, 내 계정에서 관리를 안함.. 전체공개된스토리지? 쯤으로 이동되는것 같음..
                                            // 빼면, 내 계정에서 관리가 가능해짐..
  token: process.env.REACT_APP_NFT_STORAGE_API_KEY_1,
})

function Example() {
  const [user, setUser] = useState(null);

  // MEU Customs..
  const [nameArticleOfCentralNFT, setNameArticleOfCentralNFT] = useState('');
  const [nameAuthorOfCentralNFT, setNameAuthorOfCentralNFT] = useState('');
  const [descOfCentralNFT, setDescOfCentralNFT] = useState('');
  const [priceOfCentralNFT, setPriceOfCentralNFT] = useState();

  const [fileCentral, setFileCentral] = useState();
  const [txtTargetUserAddress, setTxtTargetUserAddress] = useState();
  const [targetUserAddress, setTargetUserAddress] = useState('');
  const [loggedInUserAddress, setLoggedInUserAddress] = useState('');

  useEffect(() => {
    // sets the `user` variable to the person that is logged in through Blocto
    fcl.currentUser().subscribe(setUser);
  }, []);

  const logIn = () => {
    // log in through Blocto
    fcl.authenticate();
  };

  const logOut = () => {
    // log in through Blocto

    fcl.unauthenticate();
    setUser(null);
  };

  const mintCentralDeed = async () => {
    try {
      /*** 기존 infura 방식.. (infura에서, deprecated 되었음.. ) ***/
      // const added = await client.add(fileCentral);
      // const hash = added.path;
      /*** 새로운 nft.storage 방식.. ***/
      console.log("..Step #1 : Storing image file...")
      const json = await client.store({
        name: nameArticleOfCentralNFT,
        description: descOfCentralNFT,
        image: fileCentral
      })
      const requestURL = `https://${json.ipnft}.ipfs.nftstorage.link/metadata.json`
      const request = new XMLHttpRequest()
      request.open('GET', requestURL)
      request.responseType = 'json'
      request.send()

      console.log("..Step #2 : Receiving json file...")
      request.onload = async () => {
        const metadata = request.response
        const hash = metadata.image.replace("ipfs://", "")

        console.log("..Step #3 : Received json file.. Waiting for Flow Blockchain's signature..")
        const transactionId = await fcl.send([
          fcl.transaction(meuMintCentralDeedNFT),
          fcl.args([
            fcl.arg(hash, types.String),
            fcl.arg(nameArticleOfCentralNFT, types.String),
            fcl.arg(nameAuthorOfCentralNFT, types.String),
            fcl.arg(descOfCentralNFT, types.String),
            fcl.arg(priceOfCentralNFT, types.UFix64),
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999),
        ]).then(fcl.decode);
        console.log("..Step #4 : Waiting for transaction to be sealed..")
        console.log("transactionId ===> ", transactionId);
        return fcl.tx(transactionId).onceSealed();
      }
      
    } catch (error) {
      console.log('Error uploading file: ', error);
    }
  };

  const createAllCollectionAdmin = async () => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuCreateAllCollectionTx),
          fcl.args([fcl.arg(true, types.Bool)]),
          fcl.payer(serverAuthorization),
          fcl.proposer(serverAuthorization),
          fcl.authorizations([serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };

  const createAllCollectionUser = async () => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuCreateAllCollectionTx),
          fcl.args([fcl.arg(false, types.Bool)]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };

  const deleteAllCollectionAdmin = async () => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuDeleteAllCollectionTx),
          fcl.args([]),
          fcl.payer(serverAuthorization),
          fcl.proposer(serverAuthorization),
          fcl.authorizations([serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };

  const deleteAllCollectionUser = async () => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuDeleteAllCollectionTx),
          fcl.args([]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };

  const meuTestScriptCall = async () => {
    try {
      const result = await fcl
        .send([
          fcl.script(meuTestScriptCallTx),
          fcl.args([fcl.arg(process.env.REACT_APP_FCL_MEU_ADDRESS, types.Address)]),
        ])
        .then(fcl.decode);
      console.log('meuTestScriptCall');
      console.log(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };

  // pakt87
  const meuTestTransactionCall = async () => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuTestTransactionCallTx),
          fcl.args([
            fcl.arg(1, types.UInt64),
            fcl.arg(10, types.UInt64),
            // fcl.arg("nameAuthor", types.String),
            // fcl.arg("anyons", types.String)
          ]),
          // fcl.payer(fcl.authz),
          // fcl.proposer(fcl.authz),
          // fcl.authorizations([fcl.authz]),
          fcl.payer(serverAuthorization),
          fcl.proposer(serverAuthorization),
          fcl.authorizations([serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  };

  /***********************************
   * MEU Customs..
   ***********************************/
  const loadMyCerts = () => {
    console.log('btn loadMyCerts clicked');
    setLoggedInUserAddress(user.addr);
  };
  const loadAdminCerts = () => {
    setLoggedInUserAddress(process.env.REACT_APP_FCL_MEU_ADDRESS);
  };

  /*** user 값이 없을 경우, / 페이지로 이동 ***/
  if (!user?.addr) {
    // location.href = '/';
    return <>
      <div className="bg-white p-12 py-8 w-full flex flex-col justify-center items-center">
        <span className="font-semibold">To completely use MEU's services, please Sign in.</span>
        <button className="py-2 px-4 sm:px-8 ml-4 bg-meu-500 hover:bg-meu-600 text-white rounded-md mt-5"
          onClick={() => {
            // location.href = '/mywallet'
            fcl.authenticate();
          }}
        >
          Sign In
        </button>
      </div>
    </>;
  }

  if (user?.addr !== "0x7d4f6586d2f3e790") {
    return (
      <div className="bg-white p-12 py-8 w-full flex flex-col justify-center items-center">
        <span className="font-semibold">You do not have permission for this page.</span>
      </div>
    )
  }
  return (
    <div className="w-full block text-center">
      <h3>
        <a href="https://docs.onflow.org/" target="_blank">
          Docs
        </a>
        <br />
        <a href="https://testnet.flowscan.org/" target="_blank">
          Explorer
        </a>
        <br />
        <a
          href="https://testnet-faucet.onflow.org/fund-account"
          target="_blank"
        >
          Faucet
        </a>
        <br />
        <a href="https://play.onflow.org/local-project/" target="_blank">
          PlayGround
        </a>
        <br />
        <a
          href="https://flow-view-source.com/testnet/account/0x01/"
          target="_blank"
        >
          ViewSource
        </a>
        <br />
      </h3>

      <div>
        <h4>[ Test 용 사용자계정들.. ]</h4>
        meu.000@mailinator.com&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0x4e54e04bb645827b&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a
          href="https://www.mailinator.com/v4/public/inboxes.jsp?to=meu.000"
          target="_blank"
        >
          eMail
        </a>
        <br />
        meu.001@mailinator.com&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0xc7abe7300faa6f3a&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a
          href="https://www.mailinator.com/v4/public/inboxes.jsp?to=meu.001"
          target="_blank"
        >
          eMail
        </a>
        <br />
      </div>

      <h2 style={{ color: 'red' }}>
        <br />
        ## MEU ##
      </h2>
      <br />
      <details style={{ color: 'plum' }}>
        <summary>
          <h4 style={{ display: 'inline', color: 'plum' }}>
            [콜렉션 추가/삭제기능]
          </h4>
        </summary>
        <div>
          <br />
          <button
            style={{ background: 'cyan', color: 'black' }}
            onClick={() => createAllCollectionAdmin()}
          >
            Create All Collection (Admin)
          </button>
          <button
            style={{ background: 'cyan', color: 'black' }}
            onClick={() => createAllCollectionUser()}
          >
            Create All Collection (LoggindUser)
          </button>
          <br />
          <br />
          <button
            style={{ background: 'red', color: 'white' }}
            onClick={() => deleteAllCollectionAdmin()}
          >
            Delete All Collection (Admin)
          </button>
          <button
            style={{ background: 'red', color: 'white' }}
            onClick={() => deleteAllCollectionUser()}
          >
            Delete All Collection (LoggindUser)
          </button>
          <br />
        </div>
      </details>
      <br />
      <details style={{ color: 'plum' }}>
        <summary>
          <h4 style={{ display: 'inline', color: 'plum' }}>[시험용 버튼들]</h4>
        </summary>
        <br />
        <div>
          <button onClick={() => meuTestScriptCall()}>스크립트 시험</button>
          <button onClick={() => meuTestTransactionCall()}>
            트랜잭션 시험
          </button>
        </div>
      </details>
      <div>
        <h4 style={{ marginBottom: '0', color: 'blue' }}>
          0. [로그인 & 로그아웃]
        </h4>
        <button onClick={() => logIn()}>Log In</button>
        <button onClick={() => logOut()}>Log Out</button>
      </div>
      <div>
        <h4 style={{ marginBottom: '0', color: 'tomato' }}>
          1. [관리자계정에 CentralNFT 생성 & 사용자계정에 DeedNFT 생성 & 생성된
          2개의 NFT 연결]
        </h4>
        <input
          type="file"
          onChange={(e) => setFileCentral(e.target.files[0])}
        />
        <br />
        nameArticle :{' '}
        <input
          type="text"
          onChange={(e) => setNameArticleOfCentralNFT(e.target.value)}
        />
        nameAuthor :{' '}
        <input
          type="text"
          onChange={(e) => setNameAuthorOfCentralNFT(e.target.value)}
        />
        <br />
        priceOfReplica(float)::
        <input
          type="text"
          onChange={(e) => setPriceOfCentralNFT(e.target.value)}
        />
        description :{' '}
        <input
          type="text"
          onChange={(e) => setDescOfCentralNFT(e.target.value)}
        />
        <br />
        [Mint Central & Deed] :{' '}
        <button onClick={() => mintCentralDeed()} className={'border-4 '}>
          Mint : MultiSign+MintCentral
        </button>
        <br />
      </div>
      <div>
        <h4 style={{ marginBottom: '0', color: 'hotpink' }}>
          2. [타겟, 로그인사용자, 관리자 별 MyCollection 보기]
        </h4>
        Target Address :{' '}
        <input
          type="text"
          onChange={(e) => {
            setTxtTargetUserAddress(e.target.value);
          }}
        />
        <button
          className="bg-yellow"
          onClick={() => setTargetUserAddress(txtTargetUserAddress)}
        >
          Search...
        </button><br/>
        <button onClick={() => loadMyCerts()}>View Loggind User's Deeds</button><br/>
        <button onClick={() => loadAdminCerts()}>Admin's Deeds</button>
      </div>
      <br />
      <br />
      <MeuCollectionMarkets
        address={process.env.REACT_APP_FCL_MEU_ADDRESS}
      ></MeuCollectionMarkets>
      <MeuCollectionMarketsDeeds
        address={process.env.REACT_APP_FCL_MEU_ADDRESS}
      ></MeuCollectionMarketsDeeds>
      <MeuCollectionMarketsReplicas
        address={process.env.REACT_APP_FCL_MEU_ADDRESS}
      ></MeuCollectionMarketsReplicas>

      {user &&
      user.addr &&
      loggedInUserAddress &&
      loggedInUserAddress !== '' ? (
        <MeuCollectionDeeds
          address={loggedInUserAddress}
          target="mine"
        ></MeuCollectionDeeds>
      ) : null}
      {user && user.addr && targetUserAddress && targetUserAddress !== '' ? (
        <MeuCollectionDeeds
          address={targetUserAddress}
          target="target"
        ></MeuCollectionDeeds>
      ) : null}
      {user &&
      user.addr &&
      loggedInUserAddress &&
      loggedInUserAddress !== '' ? (
        <MeuCollectionDeedTickets
          address={loggedInUserAddress}
          target="mine"
        ></MeuCollectionDeedTickets>
      ) : null}
      {user && user.addr && targetUserAddress && targetUserAddress !== '' ? (
        <MeuCollectionDeedTickets
          address={targetUserAddress}
          target="target"
        ></MeuCollectionDeedTickets>
      ) : null}

      {user &&
      user.addr &&
      loggedInUserAddress &&
      loggedInUserAddress !== '' ? (
        <MeuCollectionReplicas
          address={loggedInUserAddress}
          target="mine"
        ></MeuCollectionReplicas>
      ) : null}
      {user && user.addr && targetUserAddress && targetUserAddress !== '' ? (
        <MeuCollectionReplicas
          address={targetUserAddress}
          target="target"
        ></MeuCollectionReplicas>
      ) : null}
      {user &&
      user.addr &&
      loggedInUserAddress &&
      loggedInUserAddress !== '' ? (
        <MeuCollectionReplicaTickets
          address={loggedInUserAddress}
          target="mine"
        ></MeuCollectionReplicaTickets>
      ) : null}
      {user && user.addr && targetUserAddress && targetUserAddress !== '' ? (
        <MeuCollectionReplicaTickets
          address={targetUserAddress}
          target="target"
        ></MeuCollectionReplicaTickets>
      ) : null}
    </div>
  );
}

export default Example;

export const meuCreateAllCollectionTx = `
// Official
import FlowToken from 0xFLOW
import FungibleToken from 0xFT
import NonFungibleToken from 0xNFT
// MEU
import CentralNFT from 0xMEU
import DeedNFT from 0xMEU
import DeedMarket from 0xMEU
import DeedMarketTicket from 0xMEU
import ReplicaNFT from 0xMEU
import ReplicaMarket from 0xMEU
import ReplicaMarketTicket from 0xMEU

transaction(isAdmin: Bool) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthAnyone: AuthAccount) {
    
    if(isAdmin){

      // MEU _ CentralNFT		Collection	/storage/MetaUzuCollection_Emojia_Central
      if acctAuthAnyone.borrow<&CentralNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Central) == nil {
        acctAuthAnyone.save(<- CentralNFT.createEmptyCollection(), to: /storage/MetaUzuCollection_Emojia_Central)
        acctAuthAnyone.link<&CentralNFT.Collection{CentralNFT.CollectionPublic, NonFungibleToken.CollectionPublic}>(/public/MetaUzuCollection_Emojia_Central, target: /storage/MetaUzuCollection_Emojia_Central)
        acctAuthAnyone.link<&CentralNFT.Collection>(/private/MetaUzuCollection_Emojia_Central, target: /storage/MetaUzuCollection_Emojia_Central)
      }

      // MEU _ DeedNFT			Collection	/storage/MetaUzuCollection_Emojia_Deed
      if acctAuthAnyone.borrow<&DeedNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Deed) == nil {
        acctAuthAnyone.save(<- DeedNFT.createEmptyCollection(), to: /storage/MetaUzuCollection_Emojia_Deed)
        acctAuthAnyone.link<&DeedNFT.Collection{DeedNFT.CollectionPublic, NonFungibleToken.CollectionPublic}>(/public/MetaUzuCollection_Emojia_Deed, target: /storage/MetaUzuCollection_Emojia_Deed)
        acctAuthAnyone.link<&DeedNFT.Collection>(/private/MetaUzuCollection_Emojia_Deed, target: /storage/MetaUzuCollection_Emojia_Deed)
      }

      // MEU _ DeedMarket		SaleCollection	/storage/MetaUzuCollection_Market_Deed
      if acctAuthAnyone.borrow<&DeedMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Deed) == nil {
        // Flow 토큰 연결하기 위해, 변수생성 (deed <-> deedmarket)
        let MetaUzuCollection_Emojia_Deed = acctAuthAnyone.getCapability<&DeedNFT.Collection>(/private/MetaUzuCollection_Emojia_Deed)
        let FlowTokenVault = acctAuthAnyone.getCapability<&FlowToken.Vault{FungibleToken.Receiver}>(/public/flowTokenReceiver)
        // 스토리지 생성 및 연결
        acctAuthAnyone.save(<- DeedMarket.createSaleCollection(MyNFTCollection: MetaUzuCollection_Emojia_Deed, FlowTokenVault: FlowTokenVault), to: /storage/MetaUzuCollection_Market_Deed)
        acctAuthAnyone.link<&DeedMarket.SaleCollection{DeedMarket.SaleCollectionPublic}>(/public/MetaUzuCollection_Market_Deed, target: /storage/MetaUzuCollection_Market_Deed)
      }

      // MEU _ DeedMarketTicket		Collection	/storage/MetaUzuCollection_Emojia_Ticket_Deed   
      if acctAuthAnyone.borrow<&DeedMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Deed) == nil {
        acctAuthAnyone.save(<- DeedMarketTicket.createEmptyCollection(), to: /storage/MetaUzuCollection_Emojia_Ticket_Deed)
        acctAuthAnyone.link<&DeedMarketTicket.Collection{DeedMarketTicket.CollectionPublic, NonFungibleToken.CollectionPublic}>(/public/MetaUzuCollection_Emojia_Ticket_Deed, target: /storage/MetaUzuCollection_Emojia_Ticket_Deed)
        acctAuthAnyone.link<&DeedMarketTicket.Collection>(/private/MetaUzuCollection_Emojia_Ticket_Deed, target: /storage/MetaUzuCollection_Emojia_Ticket_Deed)
      }

      // MEU _ ReplicaNFT			Collection	/storage/MetaUzuCollection_Emojia_Replica
      if acctAuthAnyone.borrow<&ReplicaNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Replica) == nil {
        acctAuthAnyone.save(<- ReplicaNFT.createEmptyCollection(), to: /storage/MetaUzuCollection_Emojia_Replica)
        acctAuthAnyone.link<&ReplicaNFT.Collection{ReplicaNFT.CollectionPublic, NonFungibleToken.CollectionPublic}>(/public/MetaUzuCollection_Emojia_Replica, target: /storage/MetaUzuCollection_Emojia_Replica)
        acctAuthAnyone.link<&ReplicaNFT.Collection>(/private/MetaUzuCollection_Emojia_Replica, target: /storage/MetaUzuCollection_Emojia_Replica)
      }

      // MEU _ ReplicaMarket		SaleCollection	/storage/MetaUzuCollection_Market_Replica
      if acctAuthAnyone.borrow<&ReplicaMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Replica) == nil {
        // Flow 토큰 연결하기 위해, 변수생성 (deed <-> ReplicaMarket)
        let MetaUzuCollection_Emojia_Replica = acctAuthAnyone.getCapability<&ReplicaNFT.Collection>(/private/MetaUzuCollection_Emojia_Replica)
        let FlowTokenVault = acctAuthAnyone.getCapability<&FlowToken.Vault{FungibleToken.Receiver}>(/public/flowTokenReceiver)
        // 스토리지 생성 및 연결
        acctAuthAnyone.save(<- ReplicaMarket.createSaleCollection(MyNFTCollection: MetaUzuCollection_Emojia_Replica, FlowTokenVault: FlowTokenVault), to: /storage/MetaUzuCollection_Market_Replica)
        acctAuthAnyone.link<&ReplicaMarket.SaleCollection{ReplicaMarket.SaleCollectionPublic}>(/public/MetaUzuCollection_Market_Replica, target: /storage/MetaUzuCollection_Market_Replica)
      }

      // MEU _ ReplicaMarketTicket		Collection	/storage/MetaUzuCollection_Emojia_Ticket_Replica   
      if acctAuthAnyone.borrow<&ReplicaMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Replica) == nil {
        acctAuthAnyone.save(<- ReplicaMarketTicket.createEmptyCollection(), to: /storage/MetaUzuCollection_Emojia_Ticket_Replica)
        acctAuthAnyone.link<&ReplicaMarketTicket.Collection{ReplicaMarketTicket.CollectionPublic, NonFungibleToken.CollectionPublic}>(/public/MetaUzuCollection_Emojia_Ticket_Replica, target: /storage/MetaUzuCollection_Emojia_Ticket_Replica)
        acctAuthAnyone.link<&ReplicaMarketTicket.Collection>(/private/MetaUzuCollection_Emojia_Ticket_Replica, target: /storage/MetaUzuCollection_Emojia_Ticket_Replica)
      }

    } else {

      // MEU _ DeedNFT			Collection	/storage/MetaUzuCollection_Emojia_Deed
      if acctAuthAnyone.borrow<&DeedNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Deed) == nil {
        acctAuthAnyone.save(<- DeedNFT.createEmptyCollection(), to: /storage/MetaUzuCollection_Emojia_Deed)
        acctAuthAnyone.link<&DeedNFT.Collection{DeedNFT.CollectionPublic, NonFungibleToken.CollectionPublic}>(/public/MetaUzuCollection_Emojia_Deed, target: /storage/MetaUzuCollection_Emojia_Deed)
        acctAuthAnyone.link<&DeedNFT.Collection>(/private/MetaUzuCollection_Emojia_Deed, target: /storage/MetaUzuCollection_Emojia_Deed)
      }

      // MEU _ DeedMarket		SaleCollection	/storage/MetaUzuCollection_Market_Deed
      if acctAuthAnyone.borrow<&DeedMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Deed) == nil {
        // Flow 토큰 연결하기 위해, 변수생성 (deed <-> deedmarket)
        let MetaUzuCollection_Emojia_Deed = acctAuthAnyone.getCapability<&DeedNFT.Collection>(/private/MetaUzuCollection_Emojia_Deed)
        let FlowTokenVault = acctAuthAnyone.getCapability<&FlowToken.Vault{FungibleToken.Receiver}>(/public/flowTokenReceiver)
        // 스토리지 생성 및 연결
        acctAuthAnyone.save(<- DeedMarket.createSaleCollection(MyNFTCollection: MetaUzuCollection_Emojia_Deed, FlowTokenVault: FlowTokenVault), to: /storage/MetaUzuCollection_Market_Deed)
        acctAuthAnyone.link<&DeedMarket.SaleCollection{DeedMarket.SaleCollectionPublic}>(/public/MetaUzuCollection_Market_Deed, target: /storage/MetaUzuCollection_Market_Deed)
      }

      // MEU _ DeedMarketTicket		Collection	/storage/MetaUzuCollection_Emojia_Ticket_Deed   
      if acctAuthAnyone.borrow<&DeedMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Deed) == nil {
        acctAuthAnyone.save(<- DeedMarketTicket.createEmptyCollection(), to: /storage/MetaUzuCollection_Emojia_Ticket_Deed)
        acctAuthAnyone.link<&DeedMarketTicket.Collection{DeedMarketTicket.CollectionPublic, NonFungibleToken.CollectionPublic}>(/public/MetaUzuCollection_Emojia_Ticket_Deed, target: /storage/MetaUzuCollection_Emojia_Ticket_Deed)
        acctAuthAnyone.link<&DeedMarketTicket.Collection>(/private/MetaUzuCollection_Emojia_Ticket_Deed, target: /storage/MetaUzuCollection_Emojia_Ticket_Deed)
      }

      // MEU _ ReplicaNFT			Collection	/storage/MetaUzuCollection_Emojia_Replica
      if acctAuthAnyone.borrow<&ReplicaNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Replica) == nil {
        acctAuthAnyone.save(<- ReplicaNFT.createEmptyCollection(), to: /storage/MetaUzuCollection_Emojia_Replica)
        acctAuthAnyone.link<&ReplicaNFT.Collection{ReplicaNFT.CollectionPublic, NonFungibleToken.CollectionPublic}>(/public/MetaUzuCollection_Emojia_Replica, target: /storage/MetaUzuCollection_Emojia_Replica)
        acctAuthAnyone.link<&ReplicaNFT.Collection>(/private/MetaUzuCollection_Emojia_Replica, target: /storage/MetaUzuCollection_Emojia_Replica)
      }

      // MEU _ ReplicaMarket		SaleCollection	/storage/MetaUzuCollection_Market_Replica
      if acctAuthAnyone.borrow<&ReplicaMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Replica) == nil {
        // Flow 토큰 연결하기 위해, 변수생성 (deed <-> ReplicaMarket)
        let MetaUzuCollection_Emojia_Replica = acctAuthAnyone.getCapability<&ReplicaNFT.Collection>(/private/MetaUzuCollection_Emojia_Replica)
        let FlowTokenVault = acctAuthAnyone.getCapability<&FlowToken.Vault{FungibleToken.Receiver}>(/public/flowTokenReceiver)
        // 스토리지 생성 및 연결
        acctAuthAnyone.save(<- ReplicaMarket.createSaleCollection(MyNFTCollection: MetaUzuCollection_Emojia_Replica, FlowTokenVault: FlowTokenVault), to: /storage/MetaUzuCollection_Market_Replica)
        acctAuthAnyone.link<&ReplicaMarket.SaleCollection{ReplicaMarket.SaleCollectionPublic}>(/public/MetaUzuCollection_Market_Replica, target: /storage/MetaUzuCollection_Market_Replica)
      }

      // MEU _ ReplicaMarketTicket		Collection	/storage/MetaUzuCollection_Emojia_Ticket_Replica   
      if acctAuthAnyone.borrow<&ReplicaMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Replica) == nil {
        acctAuthAnyone.save(<- ReplicaMarketTicket.createEmptyCollection(), to: /storage/MetaUzuCollection_Emojia_Ticket_Replica)
        acctAuthAnyone.link<&ReplicaMarketTicket.Collection{ReplicaMarketTicket.CollectionPublic, NonFungibleToken.CollectionPublic}>(/public/MetaUzuCollection_Emojia_Ticket_Replica, target: /storage/MetaUzuCollection_Emojia_Ticket_Replica)
        acctAuthAnyone.link<&ReplicaMarketTicket.Collection>(/private/MetaUzuCollection_Emojia_Ticket_Replica, target: /storage/MetaUzuCollection_Emojia_Ticket_Replica)
      }
      
    }

    

    
    
  }

  execute {
    log("A user stored a Collection and a SaleCollection inside their account")
  }
}

`
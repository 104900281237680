const swiperBreakpoint = {
    320: {
        slidesPerView: 1,
    },
    400: {
        slidesPerView: 1.3,
    },
    640: {
        slidesPerView: 2,
    },
    991: {
        slidesPerView: 3,
    },
    1280: {
        slidesPerView: 3,
    },
}
const swiperStyle = {
    // posiotion: "relative",
    // width: "752px",
    // height: "752px",
    // el: "hello-pakt87"
}

export {swiperBreakpoint, swiperStyle}

export const meuReplicaPaid2Tx = `
// Official
import FlowToken from 0xFLOW
import NonFungibleToken from 0xNFT
// MEU
import ReplicaMarket from 0xMEU
import ReplicaMarketTicket from 0xMEU

transaction(id: UInt64) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthUser: AuthAccount, acctAuthAdmin: AuthAccount) {
    
    // (소유자==사용자) 레플리카티켓 콜렉션 borrow..
    let cltnBorTkReplicaUser = acctAuthUser.borrow<&ReplicaMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Replica)!
    let tkReplicaOfUser = cltnBorTkReplicaUser.borrowEntireNFT(id: id)     // 해당 티켓값 가져오기
    
    // 관리자 레플리카티켓 콜렉션 borrow..
    let cltnBorTkReplicaAdmin = acctAuthAdmin.borrow<&ReplicaMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Replica)!
    let tkReplicaOfAdmin = cltnBorTkReplicaAdmin.borrowEntireNFT(id: (tkReplicaOfUser?.tgtkid)! )     // 해당 티켓값 가져오기

    // 유효한 티켓인지 비교..
    if(tkReplicaOfUser?.tgtkid == tkReplicaOfAdmin?.id && tkReplicaOfUser?.id == tkReplicaOfAdmin?.tgtkid && tkReplicaOfUser?.price == tkReplicaOfAdmin?.price){
      if(tkReplicaOfUser?.cid == tkReplicaOfAdmin?.cid && tkReplicaOfUser?.rid == tkReplicaOfAdmin?.rid && tkReplicaOfUser?.nid == tkReplicaOfAdmin?.nid){
        // 관리자 플로우지갑 콜렉션에서 출금대금 셋업..
        let payment <- acctAuthAdmin.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)!.withdraw(amount: (tkReplicaOfAdmin?.price)! ) as! @FlowToken.Vault
        // (소유자==사용자) 레플리카티켓 삭제..
        let tkReplicaOfDelete <- cltnBorTkReplicaUser.withdraw(withdrawID: (tkReplicaOfUser?.id)! )
        destroy tkReplicaOfDelete
        // 관리자 레플리카티켓 업데이트..
        cltnBorTkReplicaAdmin.setMetadataOfKey(id: (tkReplicaOfAdmin?.id)! , key: "paidday", data: (getCurrentBlock().timestamp).toString())
        // (소유자==사용자) 레플리카마켓 콜렉션 capa..
        let cltnCapMkReplicaUser = getAccount(acctAuthUser.address).getCapability(/public/MetaUzuCollection_Market_Replica) 
                                    .borrow<&ReplicaMarket.SaleCollection{ReplicaMarket.SaleCollectionPublic}>()
                                    ?? panic("Can't get the User's mkReplica's SaleCollection.")
        // 소유자에게 판매대금 입금..
        cltnCapMkReplicaUser.payment(payment: <- payment)
      }
    }
    
  }
  execute {
    log("A user purchased an NFT")
  }
}

`
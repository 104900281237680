import * as fcl from '@onflow/fcl';
import * as types from '@onflow/types';

import {
  BrowserRouter,
  Routes,
  Route,
  Switch,
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { MyWallet } from '../page/MyWallet';
import { useState, useEffect, useRef } from 'react';
// import {Swiper, SwiperSlide} from "swiper/react"
// import "swiper/swiper-bundle.min.css"
// import "swiper/swiper.min.css"

// import { StarIcon } from '@heroicons/react/24/solid';
import * as icos from '@heroicons/react/24/solid';
import * as icoo from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import _ from 'loadsh';
import { convertDT } from '../api/utils';
import { meuReplicaPurchaseSingleTx } from '../cadence/transactions/meu_replica_purchase_single';
import { meuDeedPurchaseTx } from '../cadence/transactions/meu_deed_purchase';
import { meuReplicaPurchaseTx } from '../cadence/transactions/meu_replica_purchase';
import { serverAuthorization } from '../serverSigner';

import { meuGetNFTsCentralScript } from '../cadence/scripts/meu_get_nfts_central.js';
import { meuGetSaleDeedsScript } from '../cadence/scripts/meu_get_sale_deeds_v1';
import { meuGetSaleReplicasScript } from '../cadence/scripts/meu_get_sale_replicas_v1';
import Transaction from '../components/Transaction';

export function Item(props) {
  // Progress 관련..
  const [txId, setTxId] = useState();
  const [txInProgress, setTxInProgress] = useState(false);
  const [txStatus, setTxStatus] = useState(-1);
  const [txStatusCode, setTxStatusCode] = useState(0);

  const MARKET_ADDRESS = process.env.REACT_APP_FCL_MEU_ADDRESS;

  const location = useLocation();
  const navigate = useNavigate();
  const openItem = (nft, category, objOriginal) => {
    console.log('nft is ', nft);
    console.log('category is ', category);
    console.log('objOriginal is ', objOriginal);

    nft.objOriginal = objOriginal;

    navigate('/item', {
      state: {
        nft: nft,
        category: category,
        // objOriginal: objOriginal,
      },
    });
  };

  const nft = location.state.nft;
  const category = location.state.category;
  const original = location.state.nft.objOriginal;
  const [more, setMore] = useState([]);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const [drawing, setDrawing] = useState([]);
  const [pageDrawing, setPageDrawing] = useState(0);
  const funcRefreshDraw = () => {
    // console.log('drawing')
    const arr = [];
    for (let i = 0; i < 100; i++) {
      arr.push(Math.floor(Math.random() * 2));
    }
    setDrawing((prevList) => [...arr]);
    // console.log("drawing ==>", drawing)
  };

  const getSinglesNFTs = async () => {
    try {
      const result = await fcl
        .send([
          fcl.script(meuGetNFTsCentralScript),
          fcl.args([fcl.arg(MARKET_ADDRESS, types.Address)]),
        ])
        .then(fcl.decode);

      console.log(result.length);
      // setNFTsSingles(result);

      const len = result.length;
      const arr = [];
      if (len > 5) {
        while (arr.length < 5) {
          const num = Math.floor(Math.random() * len);
          if (!arr.includes(num)) {
            arr.push(num);
          }
        }
        for (let i = 0; i < arr.length; i++) {
          arr[i] = result[arr[i]];
          arr[i].objOriginal = result[arr[i]];
        }
        setMore((prevList) => [...arr]);
      } else {
        for (let i = 0; i < len; i++) {
          arr[i] = result[i].nft;
          arr[i].objOriginal = result[i];
          // console.log("arr[i] ===> ", arr[i])
        }
        setMore((prevList) => [...arr]);
      }
    } catch (error) {
      console.log('error ===> ', error);
      // alert("error ===> ", error)
    }
  };

  const getDeedsNFTs = async () => {
    try {
      const result = await fcl
        .send([
          fcl.script(meuGetSaleDeedsScript),
          fcl.args([fcl.arg(MARKET_ADDRESS, types.Address)]),
        ])
        .then(fcl.decode);

      console.log(result);
      // setNFTsDeeds(result);

      const len = result.length;
      const arr = [];
      if (len > 5) {
        while (arr.length < 5) {
          const num = Math.floor(Math.random() * len);
          if (!arr.includes(num)) {
            arr.push(num);
          }
        }
        for (let i = 0; i < arr.length; i++) {
          arr[i] = result[arr[i]].nft;
          arr[i].objOriginal = result[arr[i]];
        }
        setMore((prevList) => [...arr]);
      } else {
        for (let i = 0; i < len; i++) {
          arr[i] = result[i].nft;
          arr[i].objOriginal = result[i];
          // console.log("arr[i] ===> ", arr[i])
        }
        setMore((prevList) => [...arr]);
      }
    } catch (error) {
      console.log('error ===> ', error);
      // alert("error ===> ", error)
    }
  };

  const getReplicasNFTs = async () => {
    try {
      const result = await fcl
        .send([
          fcl.script(meuGetSaleReplicasScript),
          fcl.args([fcl.arg(MARKET_ADDRESS, types.Address)]),
        ])
        .then(fcl.decode);

      console.log(result);
      // setNFTsReplicas(result);

      const len = result.length;
      const arr = [];
      if (len > 5) {
        while (arr.length < 5) {
          const num = Math.floor(Math.random() * len);
          if (!arr.includes(num)) {
            arr.push(num);
          }
        }
        for (let i = 0; i < arr.length; i++) {
          arr[i] = result[arr[i]].nft;
          arr[i].objOriginal = result[arr[i]];
        }
        setMore((prevList) => [...arr]);
      } else {
        for (let i = 0; i < len; i++) {
          arr[i] = result[i].nft;
          arr[i].objOriginal = result[i];
          // console.log("arr[i] ===> ", arr[i])
        }
        setMore((prevList) => [...arr]);
      }
    } catch (error) {
      console.log('error ===> ', error);
      // alert("error ===> ", error)
    }
  };

  /*** 최초 페이지 로딩 후, 1회만 실행 ***/
  useEffect(() => {
    funcRefreshDraw();
    if (category === 'Singles') getSinglesNFTs(); // more 데이터 셋업..
    else if (category === 'Deeds') getDeedsNFTs(); // more 데이터 셋업..
    else if (category === 'Replicas') getReplicasNFTs();

    // console.log('more ===> ', more);
  }, []);

  const reviews = { href: '#', average: 4, totalCount: 117 };
  // const [selectedColor, setSelectedColor] = useState(product.colors[0]);
  // const [selectedSize, setSelectedSize] = useState(product.sizes[2]);

  if (nft == null || nft == '' || nft == undefined) {
    // history.goBack();
    console.log('data is null');
    navigate('/', { replace: true });
    // navigate('/home');
    // location.href = '/';
  }

  const setInitVarTransaction = () => {
    setTxId('Initializing');
    setTxStatusCode(0);
    setTxInProgress(true);
    setTxStatus(-1);
  };
  const purchaseSingle = async (id) => {
    try {
      // 트랜잭션 상태바 관련값 설정
      setTxId("Initializing")
      setTxStatusCode(0)
      setTxInProgress(true);
      setTxStatus(-1);
      setInitVarTransaction();

      console.log('id : ', id, 'types.UInt64 : ', types.UInt64);

      // 트랜잭션 시작
      const transactionId = await fcl
        .send([
          fcl.transaction(meuReplicaPurchaseSingleTx),
          fcl.args([
            fcl.arg(id, types.UInt64), // 여기서의 id 는 cid 값을 가리킴..
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);

      // 디버깅용 콘솔로그
      console.log(transactionId);
      // 트랜잭션 ID값 설정
      setTxId(transactionId);
      fcl.tx(transactionId).subscribe((res) => {
        setTxStatus(res.status);
        setTxStatusCode(res.statusCode);
        console.log(res);
      });

      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      setTxStatusCode(1); // 트랜잭션 실패되었다고, 트랜잭션 Progress 변경되게 함.
      // alert('Fail to Purchase..', error);
      console.log('error ===> ', error);
    }
  };

  const purchaseDeed = async (id, cid) => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuDeedPurchaseTx),
          fcl.args([
            fcl.arg(id, types.UInt64), // 여기서의 id 는 did 값을 가리킴..
            fcl.arg(cid, types.UInt64),
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);

      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log('error ===> ', error);
      // alert("error ===> ", error)
    }
  };

  const purchaseReplica = async (id) => {
    try {
      const transactionId = await fcl
        .send([
          fcl.transaction(meuReplicaPurchaseTx),
          fcl.args([fcl.arg(id, types.UInt64)]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999),
        ])
        .then(fcl.decode);

      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log('error ===> ', error);
      // alert("error ===> ", error)
    }
  };

  if (nft) {
    // console.log('item nft ===>', nft);
    // console.log('item category ===>', category);
    // console.log('item original ===>', original);

    return (
      <div className="bg-white">
        {/* {JSON.stringify(nft)} */}
        <div className="pt-6">
          <nav aria-label="Breadcrumb">
            <ol
              role="list"
              className="max-w-2xl mx-auto px-4 flex items-center space-x-2 sm:px-6 lg:max-w-7xl lg:px-8 text-sm font-medium"
            >
              <li>
                <NavLink
                  className="text-gray-900 cursor-pointer hover:text-meu-500 hover:underline underline-offset-4"
                  to="/marketplace"
                >
                  Marketplace
                </NavLink>
                {/* 또는 navigate("/marketplace")*/}
              </li>
              <li>
                <div className="flex items-center">
                  <icos.ChevronDoubleRightIcon className="h-3 w-3 text-gray-400 mr-2" />
                  <a
                    className="mr-2 text-gray-900 cursor-pointer hover:text-meu-500 hover:underline underline-offset-4"
                    // href={breadcrumb.href}
                    onClick={() => {
                      navigate('/marketplace', {
                        // state: {tab: category,}
                      });
                    }}
                  >
                    {category}
                  </a>
                  <icos.ChevronDoubleRightIcon className="h-3 w-3 text-gray-400" />
                </div>
              </li>
              <li>
                <a
                  className="text-purple-500 text-base underline underline-offset-4 font-semibold shadow-md"
                  // href={product.href}
                  aria-current="page"
                >
                  {nft.metadata.nameArticle}
                </a>
              </li>
            </ol>
          </nav>

          {/* <div className="mt-4 max-w-md mx-auto bg-white px-4 space-x-2 sm:px-6 md:max-w-2xl lg:max-w-7xl lg:px-8"> */}
          <div className="mt-4 max-w-3xl mx-auto px-4 bg-white space-x-2 lg:max-w-7xl">
            <div className="md:flex ">
              <div className="shrink-0 relative h-full md:max-w-sm">
                {/* <div className="shrink-0 border border-gray-200 rounded-md overflow-hidden relative shadow"> */}
                {/* https://onlinepngtools.com/convert-png-to-grayscale >> 그레이스케일로 변환 */}
                <ul className="flex p-2.5 border border-gray-200 rounded-t-md">
                  <li className="w-1/2 flex">
                    <img
                      className="h-5 w-auto pr-1.5"
                      src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`}
                    />
                    <span className="text-sm font-semibold  overflow-visible whitespace-nowrap">
                      Blockchain: Flow
                    </span>
                  </li>
                  <li className="w-1/2 flex justify-end">
                    <span className="text-sm font-semibold  pr-1.5">4</span>
                    <div className="relative flex flex-col items-center group">
                      {nft.uuid % 2 === 0 ? (
                        <icoo.HeartIcon className="h-5 w-5 text-gray-400 cursor-pointer hover:text-rose-600" />
                      ) : (
                        <icos.HeartIcon className="h-5 w-5 text-rose-500 cursor-pointer hover:text-rose-600" />
                      )}
                      <div className="absolute bottom-0 flex-col items-center hidden mb-6 group-hover:flex ">
                        <span className="relative z-10 p-2.5 text-sm leading-none text-white whitespace-nowrap bg-gray-700 rounded-md font-semibold shadow-xl">
                          Favorite
                        </span>
                        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-700"></div>
                        {/* 툴팁예제 :: https://codepen.io/robstinson/pen/eYZLRdv */}
                      </div>
                    </div>
                  </li>
                </ul>

                <img
                  className="w-full object-cover md:h-full md:w-96 bg-white border-r border-l border-b"
                  src={`https://ipfs.io/ipfs/${nft.ipfsHash}`}
                />
                <div className="">
                  <div className="text-right pr-2 font-semibold py-2.5 border-l border-r">
                    {category === 'Singles' ? (
                      <>
                        <span className="text-3xl">
                          {nft.price.split('.')[0]}.
                        </span>
                        <span className="text-xl">
                          {nft.price.split('.')[1]}
                        </span>
                      </>
                    ) : category === 'Deeds' ? (
                      <>
                        <span className="text-3xl">
                          {original.price.split('.')[0]}.
                        </span>
                        <span className="text-xl">
                          {original.price.split('.')[1]}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="text-3xl">
                          {original.price.split('.')[0]}.
                        </span>
                        <span className="text-xl">
                          {original.price.split('.')[1]}
                        </span>
                      </>
                    )}
                    {/* <span className="text-3xl">{nft.price.split(".")[0]}.</span>
                    <span className="text-xl">{nft.price.split(".")[1]}</span> */}
                    <span className="text-base pl-2 text-gray-700">FLOW</span>
                  </div>
                  <button
                    className="relative flex flex-col items-center group rounded-b-md w-full bg-gradient-to-r hover:from-indigo-500 hover:via-purple-500 hover:to-pink-500 from-green-400 to-blue-500 text-white py-3 text-lg font-semibold hover:bg-meu-600 shadow-sm"
                    onClick={() => {
                      if (category === 'Singles') {
                        const debug = purchaseSingle(nft.id);
                        console.log('Singles Purchase debug ===>> ', debug);
                      } else if (category === 'Deeds')
                        purchaseDeed(original.id, nft.id);
                      else if (category === 'Replicas')
                        purchaseReplica(original.id);
                    }}
                  >
                    Purchase
                    <div className="absolute bottom-4 left-2 flex-col items-center hidden mb-6 group-hover:flex ">
                      <span className="relative z-10 p-2.5 text-sm leading-none text-white whitespace-nowrap bg-gray-700 rounded-md font-semibold shadow-xl">
                        Have a nice day!
                      </span>
                      <div className="w-3 h-3 -mt-2 -ml-10 rotate-45 bg-gray-700"></div>
                      {/* 툴팁예제 :: https://codepen.io/robstinson/pen/eYZLRdv */}
                    </div>
                  </button>
                </div>

                {/* 트랜잭션 상태 Progress 보여주는 곳 (임시).. */}
                <Transaction
                  txId={txId}
                  txInProgress={txInProgress}
                  txStatus={txStatus}
                  txStatusCode={txStatusCode}
                />

                <div className="  shadow  mt-5 rounded-md">
                  <div className="flex p-5 border bg-white border-gray-200 rounded-t-md">
                    <icos.SwatchIcon className="h-5 w-5 mt-[1px] mr-1.5" />
                    Drawing Board
                    <icos.ArrowPathIcon
                      className="h-5 w-5 mt-[1px] absolute right-5 text-meu-500 hover:text-meu-600 cursor-pointer"
                      onClick={() => {
                        funcRefreshDraw();
                      }}
                    />
                  </div>
                  <span className="inline-grid grid-cols-10 gap-0 w-full text-xs text-center text-gray-600 leading-5">
                    {drawing.map((draw, index) => {
                      // console.log(draw)
                      if (draw === 1) {
                        return (
                          <span key={index} className="border h-5 bg-meu-500">
                            {index + pageDrawing * 100}
                          </span>
                        );
                      } else {
                        return (
                          <span key={index} className="border h-5">
                            {index + pageDrawing * 100}
                          </span>
                        );
                      }
                    })}
                  </span>
                  <div className="flex flex-col items-center pt-2 mb-10">
                    {/* <!-- ButtonsHelp text --> */}
                    <span className="text-sm text-gray-700 ">
                      Showing{' '}
                      <span className="font-semibold text-gray-900 ">
                        {0 + pageDrawing * 100}
                      </span>{' '}
                      to{' '}
                      <span className="font-semibold text-gray-900 ">
                        {99 + pageDrawing * 100}
                      </span>{' '}
                      of{' '}
                      <span className="font-semibold text-gray-900 ">1000</span>{' '}
                      Entries
                    </span>
                    <div className="inline-flex mt-2 xs:mt-0 w-full">
                      {/* <!-- Buttons --> */}
                      <button
                        className="inline-flex w-1/2 items-center py-2 px-4 text-sm justify-center font-medium text-white bg-meu-500 rounded-bl-md hover:bg-meu-600 "
                        onClick={() => {
                          if (pageDrawing > 0) {
                            const page = pageDrawing - 1;
                            setPageDrawing(page);
                            funcRefreshDraw();
                          }
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          className="mr-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        Prev
                      </button>
                      <button
                        className="inline-flex w-1/2 items-center py-2 px-4 text-sm justify-center font-medium text-white bg-meu-500 rounded-br-md border-0 border-l border-white hover:bg-meu-600 "
                        onClick={() => {
                          if (pageDrawing < 9) {
                            const page = pageDrawing + 1;
                            setPageDrawing(page);
                            funcRefreshDraw();
                          }
                        }}
                      >
                        Next
                        <svg
                          aria-hidden="true"
                          className="ml-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full pt-2 ml-0 md:ml-6 ">
                <div className=" relative pl-2">
                  <ul className=" lg:absolute right-0 -top-2 flex justify-end mb-4 lg:mb-0">
                    <li className="relative flex flex-col items-center group">
                      <button
                        className="p-1 lg:p-2.5 border-2 rounded-tl-xl rounded-bl-xl border-r-0 hover:shadow-sm bg-white hover:bg-meu-50 border-meu-200 text-gray-600 hover:text-meu-500"
                        onClick={() => {
                          console.log('Clicked, Refresh Button!');
                        }}
                      >
                        <icos.ArrowPathIcon className="h-5 w-5" />
                      </button>
                      <div className="absolute -top-10 flex-col items-center hidden mb-6 group-hover:flex ">
                        <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-gray-700 rounded-md font-semibold shadow">
                          Refresh metadata
                        </span>
                        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-700"></div>
                      </div>
                    </li>
                    <li className="relative flex flex-col items-center group">
                      <button
                        className="p-1 lg:p-2.5 border-2 border-r-0 hover:shadow-sm bg-white hover:bg-meu-50 border-meu-200 text-gray-600 hover:text-meu-500"
                        onClick={() => navigate('/channel')}
                      >
                        <icos.ArrowTopRightOnSquareIcon className="h-5 w-5" />
                      </button>
                      <div className="absolute -top-10 flex-col items-center hidden mb-6 group-hover:flex ">
                        <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-gray-700 rounded-md font-semibold shadow">
                          View on {nft.metadata.nameAuthor}
                        </span>
                        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-700"></div>
                      </div>
                    </li>
                    <li>
                      <button className="p-1 lg:p-2.5 border-2 border-r-0 hover:shadow-sm bg-white hover:bg-meu-50 border-meu-200 text-gray-600 hover:text-meu-500">
                        <icos.ShareIcon className="h-5 w-5" />
                      </button>
                    </li>
                    <li className="relative flex flex-col items-center group">
                      <button className="p-1 lg:p-2.5 border-2 rounded-tr-xl rounded-br-xl hover:shadow-sm bg-white hover:bg-meu-50 border-meu-200 text-gray-600 hover:text-meu-500">
                        <icos.EllipsisVerticalIcon className="h-5 w-5" />
                      </button>
                      <div className="absolute -top-10 flex-col items-center hidden mb-6 group-hover:flex ">
                        <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-gray-700 rounded-md font-semibold shadow">
                          More
                        </span>
                        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-700"></div>
                      </div>
                    </li>
                  </ul>
                  <span
                    className="cursor-pointer text-base font-semibold text-purple-500 hover:text-purple-600 underline-offset-4 hover:underline"
                    onClick={() => navigate('/channel')}
                  >
                    <span className="flex">
                      <icos.CheckBadgeIcon className="h-5 w-5 mt-[1px] mr-1.5" />
                      {nft.metadata.nameAuthor}
                      <span className="">
                        &nbsp;(
                        {nft.metadata.addrAuthor.slice(0, 6) +
                          '...' +
                          nft.metadata.addrAuthor.slice(-4)}
                        )
                      </span>
                    </span>
                  </span>
                </div>
                <div className="mt-6 text-3xl font-semibold text-gray-800 pl-2">
                  {nft.metadata.nameArticle}
                </div>
                <div className="mt-9 pl-2">
                  <span>
                    <span className="text-gray-500 text-sm">
                      Owned by&nbsp;&nbsp;
                    </span>
                    {nft.addrOwner.slice(0, 6) +
                      '...' +
                      nft.addrOwner.slice(-4)}
                  </span>
                  <ul className="block lg:flex mt-1 pl-1 ">
                    <li className="flex flex-nowrap whitespace-nowrap mr-6 cursor-pointer hover:text-rose-500">
                      <icos.HeartIcon className="h-5 w-5 mr-1.5 mt-[1px] text-rose-500" />{' '}
                      5 favorites
                    </li>
                    <li className="flex flex-nowrap whitespace-nowrap mr-6 cursor-pointer hover:text-amber-500">
                      <icoo.ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5 mr-1.5 mt-[1px] text-amber-500" />{' '}
                      1156 chats
                    </li>
                    <li className="flex flex-nowrap whitespace-nowrap mr-6">
                      <icoo.ArrowDownTrayIcon className="h-5 w-5 mr-1.5 mt-[1px] text-meu-500" />
                      {nft.totalReplica} replicas
                    </li>
                    <li className="flex flex-nowrap whitespace-nowrap mr-6">
                      <icos.EyeIcon className="h-5 w-5 mr-1.5 mt-[1px] " /> 87
                      views
                    </li>
                  </ul>
                </div>
                <div className="rounded-md border shadow border-gray-200 mt-5">
                  <ul>
                    <li className="flex p-5 border-b bg-white">
                      <icos.Bars3BottomLeftIcon className="h-5 w-5 mt-[1px] mr-1.5" />
                      Description
                    </li>
                    <li className="h-auto max-h-52 overflow-y-auto p-6 pt-8 bg-meu-50/[.33]">
                      <div className="flex mb-2 justify-start">
                        <span className="text-sm leading-6 pr-1">By</span>
                        <span className="text-base font-semibold">
                          {nft.metadata.nameAuthor}
                        </span>
                        <icos.CheckBadgeIcon className="h-5 w-5 mt-[1px] ml-1.5 text-purple-500" />
                      </div>
                      <span className="text-gray-600 text-base">
                        {nft.metadata.description}
                      </span>
                      {/* adsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fjaadsasdas das asd asd mklsd fja */}
                    </li>
                  </ul>
                </div>
                <div className="rounded-md border shadow border-gray-200 mt-5">
                  <ul>
                    <li className="flex p-5 border-b bg-white">
                      <icos.DocumentTextIcon className="h-5 w-5 mt-[1px] mr-1.5" />
                      Metadata
                    </li>
                    <li className="px-6 pt-8 bg-meu-50/[.33]">
                      <span className="text-xs font-semibold text-amber-600 pr-2">
                        [Article]
                      </span>
                      <span className="text-gray-600 font-semibold font-sm">
                        {nft.metadata.nameArticle}
                      </span>
                    </li>
                    <li className="px-6 pt-0.5 bg-meu-50/[.33]">
                      <span className="text-xs font-semibold text-amber-600 pr-2">
                        [Author]
                      </span>
                      <span className="text-gray-600 font-semibold font-sm">
                        {nft.metadata.nameAuthor} (
                        {nft.metadata.addrAuthor.slice(0, 6) +
                          '...' +
                          nft.metadata.addrAuthor.slice(-4)}
                        )
                      </span>
                    </li>
                    <li className="px-6 pt-0.5 bg-meu-50/[.33]">
                      <span className="text-xs font-semibold text-amber-600 pr-2">
                        [Birthday]
                      </span>
                      <span className="text-gray-600 font-semibold font-sm">
                        {new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        }).format(parseInt(nft.metadata.birthday) * 1000)}
                      </span>
                    </li>
                    <li className="px-6 pt-0.5 bg-meu-50/[.33]">
                      <span className="text-xs font-semibold text-amber-600 pr-2">
                        [Replicated]
                      </span>
                      <span className="text-gray-600 font-semibold font-sm">
                        {nft.totalReplica}
                      </span>
                    </li>
                    <li className="px-6 pt-0.5 pb-8 bg-meu-50/[.33]">
                      <span className="text-xs font-semibold text-amber-600 pr-2">
                        [Revenue]
                      </span>
                      <span className="text-gray-600 font-semibold font-sm">
                        To be added..
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="rounded-md border shadow border-gray-200 mt-5 mb-10">
                  <div className="flex p-5 border-b bg-white">
                    <icos.DocumentTextIcon className="h-5 w-5 mt-[1px] mr-1.5" />
                    More NFTs of {category}
                  </div>
                  <div className="flex mx-auto p-6 bg-meu-50/[.33]">
                    {more.map((mor, index) => {
                      // console.log(mor);
                      return (
                        <div
                          key={mor.id}
                          className="w-1/5 m-2 rounded overflow-hidden cursor-pointer border-2 hover:border-meu-500 shadow transition-transform transform hover:-translate-y-2"
                          onClick={() => {
                            console.log('category in map ===> ', category);
                            if (category === 'Singles') getSinglesNFTs();
                            else if (category === 'Deeds') getDeedsNFTs();
                            else if (category === 'Replicas') getReplicasNFTs();

                            setPageDrawing(0);
                            funcRefreshDraw();
                            openItem(mor, category, mor.objOriginal);
                          }}
                        >
                          <img
                            className="w-full object-cover md:h-full md:w-96 bg-white"
                            src={`https://ipfs.io/ipfs/${mor.ipfsHash}`}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

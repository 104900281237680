export const meuReplicaPurchaseTx = `
// Official
import FlowToken from 0xFLOW
import NonFungibleToken from 0xNFT
// MEU
import ReplicaNFT from 0xMEU
import ReplicaMarket from 0xMEU

transaction(id: UInt64) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(accuAuthUser: AuthAccount, acctAuthAdmin: AuthAccount) {
    
    let cltnCapMkReplicaAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Market_Replica)
                                  .borrow<&ReplicaMarket.SaleCollection{ReplicaMarket.SaleCollectionPublic}>()
                                  ?? panic("Can't get the Admin's mkReplica's collection.")
    
    // 판매자와 (구매자==사용자)가 같은지 주소로 확인..
    let addrSellerOfReplica = cltnCapMkReplicaAdmin.getSeller(id: id)
    if addrSellerOfReplica == accuAuthUser.address {
      panic("You can't buy your own. (Replica)")
    }

    // (구매자==사용자)의 콜렉션 가져오기
    let cltnCapReplicaUser = getAccount(accuAuthUser.address).getCapability(/public/MetaUzuCollection_Emojia_Replica) 
                              .borrow<&ReplicaNFT.Collection{NonFungibleToken.CollectionPublic}>()
                              ?? panic("Can't get the User's Replica's collection.")
    
    // 변수 셋업
    let price = cltnCapMkReplicaAdmin.getPrice(id: id)
    let payment <- accuAuthUser.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)!.withdraw(amount: price) as! @FlowToken.Vault
    
    // purchase() 함수 실행
    cltnCapMkReplicaAdmin.purchase(id: id, recipientCollection: cltnCapReplicaUser, payment: <- payment)

    // [여기까지 마치면..] 현재 상태는..
    // 구매자는 돈을 관리자에게 주었고.. 구매자는 플로우 감소, 관리자는 플로우 증가..
    // 관리자가 보관중이던 레플리카를 구매자의 콜렉션에 입금했고..
    // 관리자의 마켓의 판매리스트의 forSale 은 지워지고.. forPayment 로 갱신된 상태..
    // 가 되야함..
  }

  execute {
    log("A user purchased an NFT")
  }
}

`
export const meuCancleSaleReplicaTx = `
// Official
import FlowToken from 0xFLOW
import FungibleToken from 0xFT
import NonFungibleToken from 0xNFT
// MEU
import ReplicaNFT from 0xMEU
import ReplicaMarket from 0xMEU
import ReplicaMarketTicket from 0xMEU

transaction(id: UInt64, rid:UInt64) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthUser: AuthAccount, acctAuthAdmin: AuthAccount) {

    // 판매자와 사용자가 같은지 주소로 확인..
    let cltnCapMkReplicaAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Market_Replica)
                                  .borrow<&ReplicaMarket.SaleCollection{ReplicaMarket.SaleCollectionPublic}>()
                                  ?? panic("Can't get the Admin's mkReplica's collection.")
    let addrSellerOfReplica = cltnCapMkReplicaAdmin.getSeller(id: id)
    if addrSellerOfReplica != acctAuthUser.address {
      panic("It's not yours.")
    }

    // 관리자의 마켓판매정보 삭제하기
    let cltnBorMkReplicaAdmin = acctAuthAdmin.borrow<&ReplicaMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Replica)
                                  ?? panic("This Admin's mkReplica's SaleCollection does not exist")
    cltnBorMkReplicaAdmin.cancleSale(id: id)

    // (판매자==사용자)의 티켓 삭제하기
    let cltnBorTkReplicaUser = acctAuthUser.borrow<&ReplicaMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Replica)
                                ?? panic("This User's tkReplica's Collection does not exist")
    let tkReplicaOfUser <- cltnBorTkReplicaUser.withdraw(withdrawID: id)
    destroy tkReplicaOfUser
    
    // 관리자의 콜렉션 가져와서 replica 출금하기
    let cltnBorReplicaAdmin = acctAuthAdmin.borrow<&ReplicaNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Replica)
                                ?? panic("This Admin's Replica's Collection does not exist")
    let replicaOfAdmin <- cltnBorReplicaAdmin.withdraw(withdrawID: rid)

    // (판매자==사용자)의 콜렉션 가져와서 입금해주기
    let cltnCapReplicaUser = getAccount(acctAuthUser.address).getCapability(/public/MetaUzuCollection_Emojia_Replica) 
                              .borrow<&ReplicaNFT.Collection{NonFungibleToken.CollectionPublic}>()
                              ?? panic("Can't get the User's Replica's collection.")
    cltnCapReplicaUser.deposit(token: <- replicaOfAdmin)
    
  }
  execute {
    log("A user listed an NFT for Sale")
  }
}
`
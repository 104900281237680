export const meuGetCollectionCheckTx = `
// Official
import NonFungibleToken from 0xNFT
// MEU
import CentralNFT from 0xMEU
import DeedNFT from 0xMEU
import DeedMarket from 0xMEU
import DeedMarketTicket from 0xMEU
import ReplicaNFT from 0xMEU
import ReplicaMarket from 0xMEU
import ReplicaMarketTicket from 0xMEU

pub struct TempStruct {
  pub let name: String?
  pub let exists: Bool?

  init(
        name: String,
        exists: Bool?,
    ) {
        self.name = name
        self.exists = exists
    }
}


pub fun main(acctAddr: Address): [AnyStruct] {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  // 설명 >> https://docs.onflow.org/cadence/language/accounts/#path-functions
  // let storagePath = /storage/path
  // storagePath.toString()  // is "/storage/path"
  // fun PublicPath(identifier: string): PublicPath?
  // fun PrivatePath(identifier: string): PrivatePath?
  // fun StoragePath(identifier: string): StoragePath?
  // let pathID = "foo"
  // let path = PublicPath(identifier: pathID) // is /public/foo


  let arrayPath: [PublicPath] = [
    /public/MetaUzuCollection_Emojia_Central,
  
    /public/MetaUzuCollection_Emojia_Deed,
    /public/MetaUzuCollection_Market_Deed,
    /public/MetaUzuCollection_Emojia_Ticket_Deed,

    /public/MetaUzuCollection_Emojia_Replica,
    /public/MetaUzuCollection_Market_Replica,
    /public/MetaUzuCollection_Emojia_Ticket_Replica
  ]
  let returnVals: [TempStruct] = []

  returnVals.append(TempStruct(
    name: arrayPath[0].toString(),
    exists: getAccount(acctAddr).getCapability(arrayPath[0]).check<&CentralNFT.Collection{NonFungibleToken.CollectionPublic, CentralNFT.CollectionPublic}>()
  ))
  returnVals.append(TempStruct(
    name: arrayPath[1].toString(),
    exists: getAccount(acctAddr).getCapability(arrayPath[1]).check<&DeedNFT.Collection{DeedNFT.CollectionPublic, NonFungibleToken.CollectionPublic}>()
  ))
  returnVals.append(TempStruct(
    name: arrayPath[2].toString(),
    exists: getAccount(acctAddr).getCapability(arrayPath[2]).check<&DeedMarket.SaleCollection{DeedMarket.SaleCollectionPublic}>()
  ))
  returnVals.append(TempStruct(
    name: arrayPath[3].toString(),
    exists: getAccount(acctAddr).getCapability(arrayPath[3]).check<&DeedMarketTicket.Collection{DeedMarketTicket.CollectionPublic, NonFungibleToken.CollectionPublic}>()
  ))
  returnVals.append(TempStruct(
    name: arrayPath[4].toString(),
    exists: getAccount(acctAddr).getCapability(arrayPath[4]).check<&ReplicaNFT.Collection{ReplicaNFT.CollectionPublic, NonFungibleToken.CollectionPublic}>()
  ))
  returnVals.append(TempStruct(
    name: arrayPath[5].toString(),
    exists: getAccount(acctAddr).getCapability(arrayPath[5]).check<&ReplicaMarket.SaleCollection{ReplicaMarket.SaleCollectionPublic}>()
  ))
  returnVals.append(TempStruct(
    name: arrayPath[6].toString(),
    exists: getAccount(acctAddr).getCapability(arrayPath[6]).check<&ReplicaMarketTicket.Collection{ReplicaMarketTicket.CollectionPublic, NonFungibleToken.CollectionPublic}>()
  ))




  // for name in names {
  //   let path = PublicPath(identifier: name)
  //   var exists = false
  //   if(getAccount(acctAddr).getCapability( path! ) != nil){
  //     exists = true
  //   }
  //   returnVals.append(TempStruct(
  //     name: path!.toString(),
  //     exists: exists
  //   ))
  // }



  return returnVals

  
}
`
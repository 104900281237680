import './App.css';

import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import {useState, useEffect} from 'react';
import {meuGetSaleDeedsScript} from "./cadence/scripts/meu_get_sale_deeds";
import {meuDeedPurchaseTx} from "./cadence/transactions/meu_deed_purchase";

import {serverAuthorization} from './serverSigner';

function MeuCollectionMarketsDeeds(props) {
  const [nfts, setNFTs] = useState([]);

  useEffect(() => {
    getUserSaleNFTs();
  }, [])

  const getUserSaleNFTs = async () => {
    try {
      const result = await fcl.send([
            fcl.script(meuGetSaleDeedsScript),
            fcl.args([
                fcl.arg(props.address, t.Address)
            ])
      ]).then(fcl.decode);

      console.log(result);
      setNFTs(result);
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }  
  }

  const purchase = async (id, cid) => {
    try {
      const transactionId = await fcl.send([
          fcl.transaction(meuDeedPurchaseTx),
          fcl.args([
            fcl.arg(id, t.UInt64),     // 여기서의 id 는 did 값을 가리킴..
            fcl.arg(cid, t.UInt64)
          ]),
          fcl.payer(fcl.authz),
          fcl.proposer(fcl.authz),
          fcl.authorizations([fcl.authz, serverAuthorization]),
          fcl.limit(9999)
      ]).then(fcl.decode);
  
      console.log(transactionId);
      return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
  }
 
  // nftID ==> did ==> deedNFT.id
  // nftRef ==> deedNFT 임..
  return (
    <div style={{backgroundColor: 'lightblue'}}>
      <h3>[ Whole Markets of Deed.. (Admin's Collections..) ]</h3>
      {Object.keys(nfts).map(nftID => (   
        // nftID 는 tdid 값으로 현재 json 에서 키값을 담당함
          <div style={{margin:"50px"}} key={nftID} className="flex flex-col justify-center justify-items-center items-center">
              <img style={{width: "100px"}} className="" src={`https://ipfs.io/ipfs/${nfts[nftID].nftRef.ipfsHash}`} />
              <h4 style={{margin:"0"}}>[id=&gt;&gt;key=&gt;&gt;tdid] {nftID}</h4>
              <h4 style={{margin:"0"}}>[did] {nfts[nftID].did}</h4>
              <h4 style={{margin:"0"}}>[cid] {nfts[nftID].nftRef.cid}</h4>
              <h4 style={{margin:"0"}}>[price] {nfts[nftID].price}</h4>
              <h4 style={{margin:"0"}}>[seller] {nfts[nftID].seller}</h4>
              <button onClick={() => purchase(nftID, nfts[nftID].nftRef.cid)}>Purchase this NFT</button>
          </div>
      ))}
    </div>
  );
}

export default MeuCollectionMarketsDeeds;
import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faInstagram,
  faDiscord,
  faReddit,
  faYoutube,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  return (
    <Fragment>
      <div className="py-5 bg-meu-600">
        <div className="max-w-7xl mx-auto">
          <div className="mx-auto py-5 grid gap-6 w-full grid-cols-1 lg:grid-cols-2 border-b-2 border-meu-200">
            <div className="relative text-white font-medium px-8 py-3 ">
              <h3 className="font-bold text-2xl">Stay in the loop</h3>
              <p className="py-3">
                Join our mailing list to stay in the loop with our newest
                feature releases, NFT drops, and tips and tricks for navigating
                MetaUzu.
              </p>
              <div className="flex my-2">
                <input
                  type="email"
                  className="form-input w-full px-4 py-3 rounded-lg border-none outline-none focus:ring-meu-700 focus:shadow-md placeholder:text-slate-400 text-gray-700"
                  placeholder="Your email address"
                />
                <a
                  href="#"
                  className="items-center justify-center px-5 py-3 ml-3 border border-transparent text-base font-medium rounded-md text-white bg-meu-500 hover:bg-meu-400 hover:shadow-sm"
                >
                  Register
                </a>
              </div>
            </div>
            <div className="relative text-white font-medium px-8 py-3">
              <h3 className="font-bold text-2xl">Join the community</h3>
              <p className="py-3">
                <a
                  href="#"
                  className="inline-block items-center justify-center px-3 pt-3 pb-2 mr-3 mb-3 border border-transparent text-base font-medium rounded-lg bg-meu-500 hover:bg-meu-400 hover:shadow-sm"
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="h-6"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="#"
                  className="inline-block items-center justify-center px-3 pt-3 pb-2 mr-3 mb-3 border border-transparent text-base font-medium rounded-lg bg-meu-500 hover:bg-meu-400 hover:shadow-sm"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="h-6"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="#"
                  className="inline-block items-center justify-center px-3 pt-3 pb-2 mr-3 mb-3 border border-transparent text-base font-medium rounded-lg bg-meu-500 hover:bg-meu-400 hover:shadow-sm"
                >
                  <FontAwesomeIcon
                    icon={faDiscord}
                    className="h-6"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="#"
                  className="inline-block items-center justify-center px-3 pt-3 pb-2 mr-3 mb-3 border border-transparent text-base font-medium rounded-lg bg-meu-500 hover:bg-meu-400 hover:shadow-sm"
                >
                  <FontAwesomeIcon
                    icon={faReddit}
                    className="h-6"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="#"
                  className="inline-block items-center justify-center px-3 pt-3 pb-2 mr-3 mb-3 border border-transparent text-base font-medium rounded-lg bg-meu-500 hover:bg-meu-400 hover:shadow-sm"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="h-6"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="#"
                  className="inline-block items-center justify-center px-3 pt-3 pb-2 mr-3 mb-3 border border-transparent text-base font-medium rounded-lg bg-meu-500 hover:bg-meu-400 hover:shadow-sm"
                >
                  <FontAwesomeIcon
                    icon={faTiktok}
                    className="h-6"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="#"
                  className="inline-block items-center justify-center px-3 pt-3 pb-2 mr-3 mb-3 border border-transparent text-base font-medium rounded-lg bg-meu-500 hover:bg-meu-400 hover:shadow-sm"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="h-6"
                    aria-hidden="true"
                  />
                </a>
              </p>
            </div>
          </div>

          <div className="mx-auto py-5 grid gap-0 w-full grid-cols-1 md:grid-cols-3 border-b-2 border-meu-200">
            <div className="relative block px-8 py-10 text-white">
              <img
                className="h-12 w-auto mr-3"
                src={`${process.env.PUBLIC_URL}/assets/LogoMetaUzuReverse600.png`}
                alt="MetaUzu"
              />
              <h3 className="font-bold text-2xl pt-3">MetaUzu</h3>
              <p className="py-2">
                Anyone can create and trade Emoji NFTs, and use a variety of
                emoticons anywhere in your online life to express your emotions
                in a rich way.
              </p>
            </div>
            <div className="relative md:col-span-2 pl-8 pr-8 py-10 ">
              <div className="grid gap-0 w-full grid-cols-2 md:grid-cols-4">
                <div className="relative px-0 text-white">
                  <h4 className="font-bold text-base pt-3 pb-4">[ Markets ]</h4>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      All NFTs
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      New
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Trending
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Top
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Style & Tag
                    </a>
                  </h5>
                  <h4 className="font-bold text-base pt-14 pb-4">[ Stats ]</h4>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Rankings
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Activity
                    </a>
                  </h5>
                </div>
                <div className="relative px-0 text-white">
                  <h4 className="font-bold text-base pt-3 pb-4">[ Account ]</h4>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Profile
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Favorites
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      My Collections
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      My Auctions
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Settings
                    </a>
                  </h5>
                  <h4 className="font-bold text-base pt-14 pb-4">
                    [ Creation ]
                  </h4>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Atelier
                    </a>
                  </h5>
                </div>
                <div className="relative px-0 text-white">
                  <h4 className="font-bold text-base pt-3 pb-4">
                    [ Resources ]
                  </h4>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Help Center
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Partners
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Blog
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Newsletter
                    </a>
                  </h5>
                </div>
                <div className="relative px-0 text-white">
                  <h4 className="font-bold text-base pt-3 pb-4">[ Company ]</h4>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      About
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Careers
                    </a>
                  </h5>
                  <h5 className="my-2">
                    <a
                      href="#"
                      className="text-sm py-1 tracking-tight hover:tracking-normal hover:font-semibold"
                    >
                      Grants
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

import * as fcl from '@onflow/fcl';

const getSignature = async (signable) => {
  try {
    console.log(
      'NODE_ENV :',
      process.env.NODE_ENV,
      process.env.REACT_APP_SERVER_AUTH,
    );
    // let URL = process.env.NODE_ENV == 'development' ? '/api/sign' : '/api/sign';
    let URL = process.env.REACT_APP_SERVER_AUTH + '/api/sign';
    console.log("getSignature's URL : ", URL);
    console.log('signable is ', signable);
    let body = {
      message: signable.message,
    };

    console.log("getSignature's body  : ", body);
    const response = await fetch(URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    const signed = await response.json();
    return signed.signature;
  } catch (error) {
    console.log('error ===> ', error);
    // alert("error ===> ", error)
  }
};

export const serverAuthorization = async (account) => {
  try {
    // testnet depoly refer >> https://docs.onflow.org/dapp-development/testnet-deployment/
    const addr = process.env.REACT_APP_FCL_MEU_ADDRESS; // MEU's Admin Flow Address (testnet)
    const keyId = 0;

    return {
      ...account,
      tempId: `${addr}-${keyId}`,
      addr: fcl.sansPrefix(addr),
      keyId: Number(keyId),
      signingFunction: async (signable) => {
        try {
          console.log('signable is ', signable);
          const signature = await getSignature(signable);
          // console.log(`${API}/sign`);

          return {
            addr: fcl.withPrefix(addr),
            keyId: Number(keyId),
            signature,
          };
        } catch (error) {
          console.log('error ===> ', error);
          // alert("error ===> ", error)
        }
      },
    };
  } catch (error) {
    console.log('error ===> ', error);
    // alert("error ===> ", error)
  }
};

export const meuDeedPurchaseTx = `
// Official
import FlowToken from 0xFLOW
import NonFungibleToken from 0xNFT
// MEU
import CentralNFT from 0xMEU
import DeedNFT from 0xMEU
import DeedMarket from 0xMEU

transaction(id: UInt64, cid: UInt64) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthUser: AuthAccount, acctAuthAdmin: AuthAccount) {
    
    let cltnCapMkDeedAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Market_Deed)
                              .borrow<&DeedMarket.SaleCollection{DeedMarket.SaleCollectionPublic}>()
                              ?? panic("Can't get the Admin's mkDeed's collection.")
    // 판매자와 (구매자==사용자)가 같은지 주소로 확인..
    let addrSellerOfDeed = cltnCapMkDeedAdmin.getSeller(id: id)
    if addrSellerOfDeed == acctAuthUser.address {
      panic("You can't buy your own. (Deed)")
    }

    // (구매자==사용자)의 Deed 콜렉션 가져오기
    let cltnCapDeedUser = getAccount(acctAuthUser.address).getCapability(/public/MetaUzuCollection_Emojia_Deed) 
                            .borrow<&DeedNFT.Collection{NonFungibleToken.CollectionPublic}>()
                            ?? panic("Can't get the User's Deed's collection.")
    // 변수 셋업
    let price = cltnCapMkDeedAdmin.getPrice(id: id)
    let payment <- acctAuthUser.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)!.withdraw(amount: price) as! @FlowToken.Vault

    // 관리자의 Central 콜렉션 가져와서 소유자 변경하기(addOwner)
    let cltnBorCentralAdmin = acctAuthAdmin.borrow<&CentralNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Central)!
    let centralOfAdmin = (cltnBorCentralAdmin.borrowEntireNFT(id: cid))
    centralOfAdmin?.setOwner(addrOwner: acctAuthUser.address)
    
    // purchase() 함수 실행
    cltnCapMkDeedAdmin.purchase(id: id, recipientCollection: cltnCapDeedUser, payment: <- payment)
  }

  execute {
    log("A user purchased an NFT")
  }
}

`
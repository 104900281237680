// react 기본컴포넌트 삽입
import React from 'react';
import { useState, useEffect, Fragment } from 'react';

// Headless UI 라이브러리 컴포넌트 삽입
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'

// 커스텀 SVG 삽입
import { ReactComponent as LogoMetauzu } from '../assets/LogoMEU.svg';
import { ReactComponent as LogoMetauzuReverse } from '../assets/LogoMEU_Reverse.svg';
import { ReactComponent as LogoFacebook } from '../assets/LogoFacebook.svg';
import { ReactComponent as LogoInstagram } from '../assets/LogoInstagram.svg';
import { ReactComponent as LogoTiktok } from '../assets/LogoTiktok.svg';
import { ReactComponent as LogoTwitter } from '../assets/LogoTwitter.svg';
import { ReactComponent as LogoWhatsapp } from '../assets/LogoWhatsapp.svg';
import { ReactComponent as LogoYoutube } from '../assets/LogoYoutube.svg';

// 아이콘 써드파티 컴포넌트 삽입
import * as icos from '@heroicons/react/24/solid'
import * as icoo from "@heroicons/react/24/outline"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faObjectUngroup, faLayerGroup, faFilter, faTableCells, faTableCellsLarge, faCubes, faShapes } from '@fortawesome/free-solid-svg-icons';

const filterType = [
  { name: 'Recently listed' },
  { name: 'Recently created' },
  { name: 'Recently sold' },
  { name: 'Recently received' },
  { name: 'Price low to high' },
  { name: 'Price high to low' },
  { name: 'Highest last sale' },
  { name: 'Most viewed' },
  { name: 'Most favorited' },
  { name: 'Oldest' },
]

const dumCarnman = [
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_001.png`, name: "Carnman man #1", priceCurrent: "1,892.3", pricePrev: "1,501.7" },
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_002.png`, name: "Carnman man #2", priceCurrent: "2,412.7", pricePrev: "2,356.1" },
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_003.png`, name: "Carnman man #3", priceCurrent: "1,288.1", pricePrev: "853.6" },
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_004.png`, name: "Carnman man #4", priceCurrent: "3,510.4", pricePrev: "3,118.3" },
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_005.png`, name: "Carnman man #5", priceCurrent: "1,785.5", pricePrev: "1,441.2" },
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_006.png`, name: "Carnman car #1", priceCurrent: "1,294.9", pricePrev: "1,088.3" },
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_007.png`, name: "Carnman car #2", priceCurrent: "971.5",   pricePrev: "851" },
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_008.png`, name: "Carnman car #3", priceCurrent: "2,813.6", pricePrev: "2,418.9" },
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_009.png`, name: "Carnman car #4", priceCurrent: "3,329",   pricePrev: "3,129.2" },
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_010.png`, name: "Carnman car #5", priceCurrent: "2,745.1", pricePrev: "2,338.8" },
  { srcImage : `${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_011.png`, name: "Carnman car #6", priceCurrent: "2,112",   pricePrev: "1,924.3" },
]

// 기본 내보내기 함수 선언
export default function Channel() {
  /*******************************************************
  * 화면 공통사용 함수 선언
  *******************************************************/
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  /*******************************************************
  * 화면 공통사용 변수 선언
  *******************************************************/
  const MARKET_ADDRESS = process.env.REACT_APP_FCL_MEU_ADDRESS;
  const [tab, setTab] = useState("articles")            // 탭화면 관련 보여주거나 안보여주도록 하는 네비게이션 역할의 훅
  const [selected, setSelected] = useState(filterType[0])
  const [grid, setGrid] = useState(true)
  const [isFilter, setIsFilter] = useState(true)

  return (
    <>
      {/* 참고페이지 :: https://tailwindcomponents.com/component/mobile-social-network-profile-page */}
      <section className=" md:container md:mx-auto pb-10">
        {/* 최상단 배너 이미지 */}
        <div className=" relative">
          <img className="w-full object-cover object-center shadow-md mt-3"
            src={`${process.env.PUBLIC_URL}/assets/sample/channel/banner-carnman.png`}
          />
          <div className="absolute w-full bg-meu-500 opacity-70  font-semibold text-center text-gray-100 bottom-0 sm:bottom-7 mb-2 py-4">
            <div className="mb-2 text-2xl md:text-3xl  lg:text-5xl">Carnman Project</div>
            {/* <div className="text-xl">Thank you for visiting us.</div> */}
            <div className="hidden sm:block text-sm md:text-md lg:text-xl">Thank you for your interest!</div>
          </div>

          <ul className="flex absolute -bottom-2 sm:bottom-2 right-0 mr-2 py-1 md:py-2 lg:py-3 justify-end bg-gray-50/80 rounded-md">
            <li className="relative group">
              <LogoMetauzu className="h-5 mr-6 mt-0.5 cursor-pointer hover:animate-bounce" />
              <div className="absolute -top-10 -left-10  flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-black rounded-md font-semibold shadow">MEU Messenger</span>
                <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
              </div>
            </li>
            <li className="relative group">
              <LogoInstagram className="h-6  cursor-pointer hover:animate-bounce"/>
              <div className="absolute -top-10 -left-5  flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-black rounded-md font-semibold shadow">Instagram</span>
              <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
              </div>
            </li>
            <li className="relative group">
              <LogoTwitter className="h-6  cursor-pointer hover:animate-bounce"/>
              <div className="absolute -top-10 -left-3  flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-black rounded-md font-semibold shadow">Twitter</span>
              <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
            </div>
            </li>
            <li className="relative group">
              <LogoYoutube className="h-6  cursor-pointer hover:animate-bounce"/>
              <div className="absolute -top-10 -left-10  flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-black rounded-md font-semibold shadow">Youtube Channel</span>
              <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
            </div>
            </li>
            <li className="relative group">
              <LogoFacebook className="h-6  cursor-pointer hover:animate-bounce"/>
              <div className="absolute -top-10 -left-5  flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-black rounded-md font-semibold shadow">Facebook</span>
              <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
            </div>
            </li>
            <li className="relative group">
              <LogoTiktok className="h-6  cursor-pointer hover:animate-bounce"/>
              <div className="absolute -top-10 -left-4  flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-black rounded-md font-semibold shadow">TikTok</span>
              <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
            </div>
            </li>
            <li className="relative group">
              <LogoWhatsapp className="h-6  cursor-pointer hover:animate-bounce"/>
              <div className="absolute -top-10 -left-5  flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-black rounded-md font-semibold shadow">Whatsapp</span>
              <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
            </div>
            </li>
          </ul>
        </div>
        {/* 본문시작 */}
        <div className="w-full mt-8 px-8 relative">
          {/* 프로필사진 */}
          <img className=" w-24 h-24 md:w-28 md:h-28  lg:w-1/6 lg:h-48 rounded-2xl object-cover object-center shadow-md absolute -top-28 md:-top-32  lg:-top-52 border-4 border-gray-200"
            src={`${process.env.PUBLIC_URL}/assets/sample/ranking/rankprice_007.gif`}
          />
          {/* 우측 공유버튼 등 */}
          <ul className=" sm:absolute right-10 top-0 flex justify-end -mt-3 mb-1 sm:mt-0 sm:mb-0">
            <li className="relative flex flex-col items-center group">
              <button className="p-1 lg:p-2.5 border-2 rounded-tl-xl rounded-bl-xl border-r-0 hover:shadow-sm bg-white hover:bg-meu-50 border-meu-200 text-gray-600 hover:text-meu-500">
                <icoo.GlobeAltIcon className="h-5 w-5" />
              </button>
              <div className="absolute -top-10 flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-gray-700 rounded-md font-semibold shadow">Official website</span>
                <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-700"></div>
              </div>
            </li>
            <li className="relative flex flex-col items-center group">
              <button className="p-1 lg:p-2.5 border-2 border-r-0 hover:shadow-sm bg-white hover:bg-meu-50 border-meu-200 text-gray-600 hover:text-meu-500">
                <icoo.StarIcon className="h-5 w-5" />
              </button>
              <div className="absolute -top-10 flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-gray-700 rounded-md font-semibold shadow">Follow</span>
                <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-700"></div>
              </div>
            </li>
            <li className="relative flex flex-col items-center group">
              <button className="p-1 lg:p-2.5 border-2 border-r-0 hover:shadow-sm bg-white hover:bg-meu-50 border-meu-200 text-gray-600 hover:text-meu-500">
                <icos.ShareIcon className="h-5 w-5" />
              </button>
              <div className="absolute -top-10 flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-gray-700 rounded-md font-semibold shadow">Share</span>
                <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-700"></div>
              </div>
            </li>
            <li className="relative flex flex-col items-center group">
              <button className="p-1 lg:p-2.5 border-2 rounded-tr-xl rounded-br-xl hover:shadow-sm bg-white hover:bg-meu-50 border-meu-200 text-gray-600 hover:text-meu-500">
                <icos.EllipsisVerticalIcon className="h-5 w-5" />
              </button>
              <div className="absolute -top-10 flex-col items-center hidden mb-6 group-hover:flex ">
                <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-gray-700 rounded-md font-semibold shadow">More</span>
                <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-700"></div>
              </div>
            </li>
          </ul>
          {/* 본문상단 */}
          <div>
            <ul>
              <li className="font-semibold text-4xl flex items-baseline">
                Carnman Project
                <img className="h-6 w-auto pl-2.5 pt-0.5" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />
              </li>
              <li className="font-semibold text-xl ml-0.5 mt-1 flex">
                <span className="text-gray-400 mr-1.5">By</span>
                <span>Bomber Girl</span>
                <icos.CheckBadgeIcon className="h-5 w-5 mt-1 ml-1.5 text-purple-500" />
              </li>
              <li className="text-sm ml-0.5 mt-5 mb-5 font-semibold">
                <span className="text-meu-500 mr-2">Followers</span>
                <span className="text-gray-500 mr-6 text-base">18.2K</span>
                <span className="text-meu-500 mr-2">Following</span>
                <span className="text-gray-500 text-base">1,248</span>
              </li>
              {/* <li className="text-md text-gray-400 mt-2 max-w-3xl line-clamp-1"> */}
              <li className="text-md text-etc-gray mt-2 max-w-2xl">
                I have autism, but instead I can draw 'Carnman'. And reveal this. 'Emojia' is my canvas and palette. All are welcome.
              </li>
            </ul>
          </div>
        </div>
        {/* 탭바 */}
        <div className="mb-4 mt-8 px-8 w-full">
          <ul
            className="flex border-b"
            id="tabs-tab"
            role="tablist"
          >
            <li className="nav-item cursor-pointer" role="presentation">
              <a
                className={classNames(tab==="articles" ? "bg-meu-500 text-white underline underline-offset-4" : "", "rounded-tl-md nav-link block font-semibold text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-meu-600 hover:text-white focus:border-transparent active")}
                id="tabs-articles-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-articles"
                role="tab"
                aria-controls="tabs-articles"
                aria-selected="true"
                onClick={() => { setTab("articles"); }}
              >
                <FontAwesomeIcon icon={faObjectUngroup} aria-hidden="true" />
                <span className="ml-2">Articles</span>
                {/* 내가 작가(Author) 인 작품들 리스트.. */}
              </a>
            </li>
            <li className="nav-item cursor-pointer" role="presentation">
              <a
                className={classNames(tab==="collectibles" ? "bg-meu-500 text-white underline underline-offset-4" : "", "nav-link block font-semibold text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-meu-600 hover:text-white focus:border-transparent")}
                id="tabs-collectibles-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-collectibles"
                role="tab"
                aria-controls="tabs-collectibles"
                aria-selected="false"
                onClick={() => { setTab("collectibles"); }}
              >
                <FontAwesomeIcon icon={faLayerGroup} aria-hidden="true" />
                <span className="ml-2">Collectibles</span>
                {/* 내가 소유하고 있는 Deeds 리스트.. */}
              </a>
            </li>
            <li className="nav-item cursor-pointer" role="presentation">
              <a
                className={classNames(tab==="replicas" ? "bg-meu-500 text-white underline underline-offset-4" : "", "rounded-tr-md nav-link block font-semibold text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-meu-600 hover:text-white focus:border-transparent")}
                id="tabs-replicas-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-replicas"
                role="tab"
                aria-controls="tabs-replicas"
                aria-selected="false"
                onClick={() => { setTab("replicas"); }}
              >
                <FontAwesomeIcon icon={faCubes} aria-hidden="true" />
                <span className="ml-2">Replicas</span>
                {/* 내가 소유하고 있는 Replicas 리스트.. */}
              </a>
            </li>
          </ul>
        </div>
        {/* 숫자정보 표시부분 */}
        <div className="px-8 w-full">
          <ul className="flex flex-row space-x-10 items-center text-center font-semibold ml-4 pt-2 pb-4 tracking-tight">
            { tab === "articles" && <>
              <li className="">
                <div className="text-xl text-gray-900">17</div>
                <div className="text-sm text-gray-400 -mt-1">items</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900">13</div>
                <div className="text-sm text-gray-400 -mt-1">owners</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />23.8K
                </div>
                <div className="text-sm text-gray-400 -mt-1">total volume</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />718.2
                </div>
                <div className="text-sm text-gray-400 -mt-1">floor price</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />14.2K
                </div>
                <div className="text-sm text-gray-400 -mt-1">best price</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />338.8K
                </div>
                <div className="text-sm text-gray-400 -mt-1">best seigniorage</div>
              </li>
            </>}
            { tab === "collectibles" && <>
              <li className="">
                <div className="text-xl text-gray-900">6</div>
                <div className="text-sm text-gray-400 -mt-1">items</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />3,186
                  </div>
                <div className="text-sm text-gray-400 -mt-1">total volume</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />207.3
                  </div>
                <div className="text-sm text-gray-400 -mt-1">floor price</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />1,631
                  </div>
                <div className="text-sm text-gray-400 -mt-1">best price</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />1.4K
                  </div>
                <div className="text-sm text-gray-400 -mt-1">best seigniorage</div>
              </li>
            </>}
            { tab === "replicas" && <>
              <li className="">
                <div className="text-xl text-gray-900">31</div>
                <div className="text-sm text-gray-400 -mt-1">items</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />89.2
                  </div>
                <div className="text-sm text-gray-400 -mt-1">total volume</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />0.9
                  </div>
                <div className="text-sm text-gray-400 -mt-1">floor price</div>
              </li>
              <li className="">
                <div className="text-xl text-gray-900 flex items-center">
                  <img className="h-4 w-auto mr-1" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />6.1
                  </div>
                <div className="text-sm text-gray-400 -mt-1">best price</div>
              </li>
            </>}
            
          </ul>
        </div>
        {/* 검색창 기타 도구모음부분 */}
        <div className="w-full flex justify-end px-8 pt-4">
          {/* 필터아이콘 */}
          <FontAwesomeIcon className="text-white bg-meu-500 p-2.5 mr-4 rounded-lg cursor-pointer hover:bg-meu-600"
            icon={faFilter} aria-hidden="true"
            onClick={() => {setIsFilter(!isFilter)}}
          />
          {/* 검색창 */}
          <div className="relative mt-0 w-full mr-4">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              {/* <svg className="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg> */}
              <icos.MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
            </div>
            <input type="text" id="search-navbar" className="block p-2 pl-10 w-full text-gray-900 bg-gray-50 rounded-lg border border-meu-400 sm:text-sm focus:ring-meu-500 focus:border-meu-500 " placeholder="Search..." />
          </div>
          {/* 검색조건 셀렉팅부분 */}
          {/* <div className="fixed top-16 w-72"> */}
          <div className="w-72">
            <Listbox value={selected} onChange={setSelected}>
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-gray-50 py-2 pl-3 pr-10 border text-left  border-meu-400 focus:ring-meu-500 focus:border-meu-500  sm:text-sm">
                  <span className="block truncate text-etc-gray font-semibold">{selected.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="z-10 absolute mt-1  w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filterType.map((filter, filterIdx) => (
                      <Listbox.Option
                        key={filterIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? 'bg-meu-100 text-meu-500' : 'text-etc-gray'
                          }`
                        }
                        value={filter}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              // className={`block truncate ${
                              //   selected ? 'font-medium' : 'font-normal'
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {filter.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-meu-500 font-semibold">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          {/* 그리드 크기선택 */}
          <FontAwesomeIcon icon={faTableCellsLarge} className={classNames(grid ? "text-white bg-meu-500" : "text-meu-500 bg-gray-50  cursor-pointer hover:shadow-md ", "p-2.5 ml-4 rounded-l-lg border border-meu-500")} aria-hidden="true"
            onClick={() => { setGrid(true) }}
          />
          <FontAwesomeIcon icon={faTableCells} className={classNames(!grid ? "text-white bg-meu-500" : "text-meu-500 bg-gray-50  cursor-pointer hover:shadow-md ", "p-2.5 mr-4 rounded-r-lg border border-meu-500")} aria-hidden="true"
            onClick={() => { setGrid(false) }}
          />
          {/* 연락하기 */}
          <div className="relative group">
            <LogoMetauzu className="h-[38px] w-[38px] cursor-pointer bg-meu-500 p-1 rounded-lg" />
            <div className="absolute -top-10 -left-10  flex-col items-center hidden mb-6 group-hover:flex ">
              <span className="relative z-10 p-2 text-sm leading-none text-white whitespace-nowrap bg-black rounded-md font-semibold shadow">MEU Messenger</span>
              <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
            </div>
            <div className="absolute w-3 h-3 rounded-full bg-purple-400 -top-1 -right-1 group-hover:animate-bounce"></div>
          </div>
        </div>

        <div className="flex flex-row mt-8 px-8 mb-8">
          {/* AsideNav */}
          <aside className={classNames(isFilter ? "" : "hidden", "w-60 h-full shadow-md py-4 mr-8")}>
            <ul className="flex flex-col">
              {/* Status */}
              <li className="relative ">
                <input id="asideStatus" type="checkbox" className="peer hidden" defaultChecked={true} />
                <label htmlFor="asideStatus" className="block w-full font-semibold text-md hover:bg-meu-50 pl-4 py-2 cursor-pointer bg-white">Status</label>
                <label htmlFor="asideStatus" className="absolute top-3 right-2.5 peer-checked:hidden cursor-pointer"><icos.ChevronDownIcon className="h-5 w-5" /></label>
                <label htmlFor="asideStatus" className="absolute top-3 right-2.5 hidden peer-checked:block cursor-pointer"><icos.ChevronUpIcon className="h-5 w-5" /></label>
                <ul id="detailStatus" className="flex-col hidden peer-checked:flex">
                  <li className="">
                    <label className="flex items-center justify-between w-full pl-6 pr-3 py-2 cursor-pointer hover:bg-meu-50">
                      Buy Now
                      <input type="checkbox" className=" border-2 border-meu-400 rounded focus:ring-0 ring-offset-0  outline-none text-meu-500"/>
                    </label>
                    <label className="flex items-center justify-between w-full pl-6 pr-3 py-2 cursor-pointer hover:bg-meu-50">
                      On Auction
                      <input type="checkbox" className=" border-2 border-meu-400 rounded focus:ring-0 ring-offset-0  outline-none text-meu-500"/>
                    </label>
                  </li>
                </ul>
              </li>
              {/* Price */}
              <li className="relative ">
                <input id="asidePrice" type="checkbox" className="peer hidden" defaultChecked={true} />
                <label htmlFor="asidePrice" className="block w-full font-semibold text-md hover:bg-meu-50 pl-4 py-2 cursor-pointer bg-white">Price</label>
                <label htmlFor="asidePrice" className="absolute top-3 right-2.5 peer-checked:hidden cursor-pointer"><icos.ChevronDownIcon className="h-5 w-5" /></label>
                <label htmlFor="asidePrice" className="absolute top-3 right-2.5 hidden peer-checked:block cursor-pointer"><icos.ChevronUpIcon className="h-5 w-5" /></label>
                <ul id="detailStatus" className="flex-col hidden peer-checked:flex">
                  <li className="">
                    <label className="flex items-center justify-between w-full pl-6 pr-3 py-2 cursor-pointer hover:bg-meu-50">
                      <input type="range" placeholder="range...." className="border w-full border-gray-300 p-2 my-2 rounded-md focus:outline-none text-meu-500 bg-meu-500" />
                      {/* <input type="checkbox" className=" border-2 border-meu-400 rounded focus:ring-0 ring-offset-0  outline-none text-meu-500"/> */}
                    </label>
                  </li>
                </ul>
              </li>
              {/* Category */}
              <li className="relative ">
                <input id="asideCategory" type="checkbox" className="peer hidden" defaultChecked={true} />
                <label htmlFor="asideCategory" className="block w-full font-semibold text-md hover:bg-meu-50 pl-4 py-2 cursor-pointer bg-white">Category</label>
                <label htmlFor="asideCategory" className="absolute top-3 right-2.5 peer-checked:hidden cursor-pointer"><icos.ChevronDownIcon className="h-5 w-5" /></label>
                <label htmlFor="asideCategory" className="absolute top-3 right-2.5 hidden peer-checked:block cursor-pointer"><icos.ChevronUpIcon className="h-5 w-5" /></label>
                <ul id="detailStatus" className="flex-col hidden peer-checked:flex">
                  <li className="">
                    <label className="flex items-center justify-between w-full pl-6 pr-3 py-2 cursor-pointer hover:bg-meu-50">
                      Car
                      <span className="flex items-center">
                        <span className="mr-3">9</span>
                        <input type="checkbox" className=" border-2 border-meu-400 rounded focus:ring-0 ring-offset-0  outline-none text-meu-500" />
                      </span>
                      
                    </label>
                    <label className="flex items-center justify-between w-full pl-6 pr-3 py-2 cursor-pointer hover:bg-meu-50">
                      Man
                      <span className="flex items-center">
                        <span className="mr-3">5</span>
                        <input type="checkbox" className=" border-2 border-meu-400 rounded focus:ring-0 ring-offset-0  outline-none text-meu-500" />
                      </span>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>
          <div className="flex flex-col w-full">
            {/* 토탈 아이템수 */}
            <div className="w-full text-right mb-4 font-semibold text-lg">
              {tab === "articles" && dumCarnman.length}
              {tab === "collectibles" && "6"}
              {tab === "replicas" && "31"}
              &nbsp;items
            </div>
            {/* 내용들 나열 */}
            <ul role="list" className="mt-2 w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
              {dumCarnman.map((carnman, index) => {
                return (
                  <li className=" relative w-full rounded-xl overflow-hidden shadow-xl group cursor-pointer hover:bg-white">
                    <div className="overflow-hidden">
                      <img className="w-full object-cover object-center transition-transform transform duration-300 group-hover:scale-110 bg-white"
                        // src={`${process.env.PUBLIC_URL}/assets/sample/carnman/carnman_001.png`}
                        src={carnman.srcImage}
                      />
                    </div>
                    <div className="p-4 text-sm font-semibold text-[#04111D]">
                      {/* <div>Carnman man #1</div> */}
                      <div>{carnman.name}</div>
                      <div className="mt-3 text-xs text-gray-800">Price</div>
                      <div className="mb-3 text-lg flex items-center pl-1">
                        <img className="h-4 w-auto mr-1.5" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />{carnman.priceCurrent}
                      </div>
                      <div className="text-xs text-gray-500 flex items-center">
                        <div className=" line-clamp-1">Last sale: </div><img className="h-3 w-auto mr-1 ml-1.5" src={`${process.env.PUBLIC_URL}/assets/flow-50x50.png`} />{carnman.pricePrev}
                      </div>
                    </div>
                    <div className="absolute -bottom-10 w-full bg-meu-500 text-gray-100 text-center py-2 transition-transform transform duration-300 group-hover:-translate-y-10">
                      Buy now
                    </div>
                  </li>
                )
              })}
              
              
            </ul>






          </div>
          
        </div>






























      </section>
    </>
  );
}

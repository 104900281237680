export const meuCancleSaleDeedTx = `
// Official
import FlowToken from 0xFLOW
import FungibleToken from 0xFT
import NonFungibleToken from 0xNFT
// MEU
import DeedNFT from 0xMEU
import DeedMarket from 0xMEU
import DeedMarketTicket from 0xMEU

transaction(id: UInt64, did:UInt64) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthUser: AuthAccount, acctAuthAdmin: AuthAccount) {

    // 판매자와 판매취소하려는 자가 같은자인지 주소로 확인..
    let cltnCapMkDeedAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Market_Deed)
                              .borrow<&DeedMarket.SaleCollection{DeedMarket.SaleCollectionPublic}>()
                              ?? panic("Can't get the Admin's mkDeed's collection.")
    let addrSellerOfDeed = cltnCapMkDeedAdmin.getSeller(id: id)
    if addrSellerOfDeed != acctAuthUser.address {
      panic("It's not yours.")
    }

    // 관리자의 마켓판매정보 삭제하기
    let cltnBorMkDeedAdmin = acctAuthAdmin.borrow<&DeedMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Deed)
                              ?? panic("This Admins's mkDeed's SaleCollection does not exist")
    cltnBorMkDeedAdmin.cancleSale(id: id)

    // (판매자==사용자)의 티켓 삭제하기
    let cltnBorTkDeedUser = acctAuthUser.borrow<&DeedMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Deed)
                              ?? panic("This User's tkDeed's Collection does not exist")
    let tkDeedOfUser <- cltnBorTkDeedUser.withdraw(withdrawID: id)
    destroy tkDeedOfUser
    
    // 관리자의 콜렉션 가져와서 deed 출금하기
    let cltnBorDeedAdmin = acctAuthAdmin.borrow<&DeedNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Deed)
                            ?? panic("This Admin's Deed's Collection does not exist")
    let deedOfAdmin <- cltnBorDeedAdmin.withdraw(withdrawID: did)

    // (판매자==사용자)의 콜렉션 가져와서 입금해주기
    let cltnCapDeedUser = getAccount(acctAuthUser.address).getCapability(/public/MetaUzuCollection_Emojia_Deed) 
                    .borrow<&DeedNFT.Collection{NonFungibleToken.CollectionPublic}>()
                    ?? panic("Can't get the User's Deed's collection.")
    cltnCapDeedUser.deposit(token: <- deedOfAdmin)
    
  }
  execute {
    log("A user listed an NFT for Sale")
  }
}
`
export const meuReplicaPurchaseSingleTx = `
// Official
import FlowToken from 0xFLOW
import NonFungibleToken from 0xNFT
// MEU
import CentralNFT from 0xMEU
import ReplicaNFT from 0xMEU
import ReplicaMarket from 0xMEU
import ReplicaMarketTicket from 0xMEU

transaction(cid: UInt64) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthUser: AuthAccount, acctAuthAdmin: AuthAccount) {
    // Admin's Central 콜렉션 가져오기..
    let cltnCapCentralAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Emojia_Central)
                                .borrow<&CentralNFT.Collection{NonFungibleToken.CollectionPublic, CentralNFT.CollectionPublic}>()
                                ?? panic("Can't get the Admin's Central's collection.")
    
    // Admin's ReplicaMarket 콜렉션 가져오기
    let cltnCapMkReplicaAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Market_Replica)
                                  .borrow<&ReplicaMarket.SaleCollection{ReplicaMarket.SaleCollectionPublic}>()
                                  ?? panic("Can't get the Admin's mkReplica's collection.")

    // 해당하는 Central 정보 가져오기
    let centralOfAdmin: &CentralNFT.NFT? = cltnCapCentralAdmin.borrowEntireNFT(id: cid)
    
    // (구매자==사용자)'s Payment 셋업..
    let payBuyer <- acctAuthUser.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)!.withdraw(amount: (centralOfAdmin?.price)! ) as! @FlowToken.Vault
    
    // Admin's Central Borrow..
    let cltnBorCentralAdmin = acctAuthAdmin.borrow<&CentralNFT.Collection>(from: /storage/MetaUzuCollection_Emojia_Central)!
    var nid = (centralOfAdmin?.totalReplica)!
    var nidAfter = nid + 1

    // (구매자==사용자)'s ReplicaNFT 콜렉션 가져오기
    let cltnCapReplicaUser = getAccount(acctAuthUser.address).getCapability(/public/MetaUzuCollection_Emojia_Replica)
                              .borrow<&ReplicaNFT.Collection{NonFungibleToken.CollectionPublic, ReplicaNFT.CollectionPublic}>()
                              ?? panic("Can't get the User's Replica's collection.")
    
    // ReplicaNFT 민팅하기.. (원래는 관리자 bor 에서 민팅되어야함..)
    let replicaOfAdmin <- ReplicaNFT.createToken(cid: cid, nid: nid, replicator: acctAuthUser.address, ipfsHash: (centralOfAdmin?.ipfsHash)! , metadata: {
      "birthday": (getCurrentBlock().timestamp).toString()
    }, metadataAny: {
    } )
    let rid = replicaOfAdmin.id
    cltnCapReplicaUser.deposit(token: <- replicaOfAdmin)       // (구매자==사용자)에게 ReplicaNFT 전달..
    
    // 동일한 티켓생성.. (관리자에서..),, 관리자와 (Owner==deed소유자) 가 동일한 티켓을 생성 및 소지..
    let birthday = (getCurrentBlock().timestamp).toString()
    let tkReplicaOfAdmin <- ReplicaMarketTicket.createToken(cid: cid, rid: rid, nid: nid, tgtkid: ReplicaMarketTicket.totalSupply+1, price: (centralOfAdmin?.price)! , metadata: {
      "birthday": birthday,
      "soldday": birthday,
      "paidday": ""
    }, metadataAny: {
    })
    // (원래는 여기도 관리자 bor 에서만 생성되어야함..).. !!!!!!!!!!!!!!!!!!!!!!!!!!!
    // pakt87 :: 22.06.13 >>> 이부분 나중에 수정해줘야함.. !!!!!!!!!!!!!!!!!!!!!!!!!!!
    // 콜렉션 모두 삭제후, 처음 레플리카 거래시, owner가 받은 티켓의 rid 값과 tgtkid 값이 모두 0으로 동일한 증상이 있음.. !!!!!!!!!!!!!!!!!!!!!!!!!!!
    // 추후 확인 필요함... !!!!!!!!!!!!!!!!!!!!!!!!!!!
    let tkReplicaOfOwner <- ReplicaMarketTicket.createToken(cid: cid, rid: rid, nid: nid, tgtkid: tkReplicaOfAdmin.id, price: (centralOfAdmin?.price)! , metadata: {
      "birthday": birthday,
      "soldday": birthday,
      "paidday": ""
    }, metadataAny: {
    })

    // 관리자의 티켓 콜렉션 가져오기
    let cltnCapTkReplicaAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Emojia_Ticket_Replica)
                                  .borrow<&ReplicaMarketTicket.Collection{NonFungibleToken.CollectionPublic, ReplicaMarketTicket.CollectionPublic}>()
                                  ?? panic("Can't get the Admin's tkReplica's collection.")
    cltnCapTkReplicaAdmin.deposit(token: <- tkReplicaOfAdmin)    // 관리자에게 티켓 저장..

    // 소유자(Owner!=사용자) 의 티켓 콜렉션 가져오기
    let cltnCapTkReplicaOwner = getAccount( (centralOfAdmin?.addrOwner)! ).getCapability(/public/MetaUzuCollection_Emojia_Ticket_Replica)
                                  .borrow<&ReplicaMarketTicket.Collection{NonFungibleToken.CollectionPublic, ReplicaMarketTicket.CollectionPublic}>()
                                  ?? panic("Can't get the Owner's tkReplica's collection.")
    cltnCapTkReplicaOwner.deposit(token: <- tkReplicaOfOwner)    // Owner 에게 티켓 저장..

    // 대금지급.. (관리자에게)
    cltnCapMkReplicaAdmin.payment(payment: <- payBuyer)

    // centralNFT nid ++ (발행물량 +1..) && 값 반영..
    (cltnBorCentralAdmin.borrowEntireNFT(id: cid))?.setTotalReplica(total: nidAfter)

  }

  execute {
    log("A user purchased an NFT")
  }
}

`
export const meuDeedPaidTx = `
// Official
import FlowToken from 0xFLOW
import NonFungibleToken from 0xNFT
// MEU
import DeedNFT from 0xMEU
import DeedMarket from 0xMEU
import DeedMarketTicket from 0xMEU

transaction(id: UInt64, cid:UInt64, did:UInt64, price: UFix64) {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  prepare(acctAuthUser: AuthAccount, acctAuthAdmin: AuthAccount) {

    let tdid = id     // 헷갈려서 변수 따로 설정..

    // 담아야 하는 관리자의 Ticket 콜렉션 가져오기
    let cltnCapTkDeedAdmin = getAccount(acctAuthAdmin.address).getCapability(/public/MetaUzuCollection_Emojia_Ticket_Deed) 
                              .borrow<&DeedMarketTicket.Collection{NonFungibleToken.CollectionPublic}>()
                              ?? panic("Can't get the Admin's tkDeed's collection.")

    // (판매자==사용자)의 티켓 콜렉션 가져오기
    let cltnBorTkDeedUser = acctAuthUser.borrow<&DeedMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Deed)!

    // 돈을 받아야 하는 (판매자==사용자)의 market 콜렉션 가져오기
    let cltnCapMkDeedUser = getAccount(acctAuthUser.address).getCapability(/public/MetaUzuCollection_Market_Deed)
                              .borrow<&DeedMarket.SaleCollection{DeedMarket.SaleCollectionPublic}>()
                              ?? panic("Can't get the User's mkDeed's SaleCollection.")
    
    // 관리자의 마켓 콜렉션에서 해당되었던 거래내역 가져오기
    let cltnBorMkDeedAdmin = acctAuthAdmin.borrow<&DeedMarket.SaleCollection>(from: /storage/MetaUzuCollection_Market_Deed)
                              ?? panic("This Admins' mkDeed's SaleCollection does not exist")

    // 서로 바라보고 있는 정보가 일치하는지 확인 및 검사
    let tkDeedOfUser = cltnBorTkDeedUser.borrowEntireNFT(id: tdid)

    if( tkDeedOfUser?.did == cltnBorMkDeedAdmin.getDid(id: tdid) && tkDeedOfUser?.price == cltnBorMkDeedAdmin.getPrice2(id: tdid) && acctAuthUser.address == cltnBorMkDeedAdmin.getSeller(id: tdid) ){
      // Ticket 관리자에게 넘겨주기
      cltnCapTkDeedAdmin.deposit(token: <- cltnBorTkDeedUser.withdraw(withdrawID: tdid))

      // 관리자의 마켓콜렉션 조회위한 변수 설정
      let soldday = cltnBorMkDeedAdmin.getSoldday(id: tdid)
      
      // 기타 변수 미리 셋업
      let price = cltnBorMkDeedAdmin.getPrice2(id: tdid)
      let payment <- acctAuthAdmin.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)!.withdraw(amount: price) as! @FlowToken.Vault
      
      // 거래내역의 soldday 를 관리자에게 보관된 티켓에 추가업데이트 하기
      let cltnBorTkDeedAdmin = acctAuthAdmin.borrow<&DeedMarketTicket.Collection>(from: /storage/MetaUzuCollection_Emojia_Ticket_Deed)!
      cltnBorTkDeedAdmin.setMetadataOfKey(id: tdid, key: "soldday", data: soldday)
      cltnBorTkDeedAdmin.setMetadataOfKey(id: tdid, key: "paidday", data: (getCurrentBlock().timestamp).toString())

      // 관리자의 마켓콜렉션에서 해당되었던 거래내역들 다 지우기 
      cltnBorMkDeedAdmin.beforePayment(id: tdid)

      // 판매자에게 판매대금 지급하기
      cltnCapMkDeedUser.payment(payment: <- payment)
    }
  }

  execute {
    log("A user purchased an NFT")
  }
}

`
export const meuGetNFTsDeedScript = `
// Official
import NonFungibleToken from 0xNFT
// MEU
import CentralNFT from 0xMEU
import DeedNFT from 0xMEU

pub struct TempStruct {
  pub let nft: &DeedNFT.NFT?
  pub let price: UFix64?
  init(
        nft: &DeedNFT.NFT?,
        price: UFix64?,
    ) {
        self.nft = nft
        self.price = price
    }
}

pub fun main(acctAddrUser: Address, acctAddrAdmin: Address): [AnyStruct] {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  let cltnCapDeedUser = getAccount(acctAddrUser).getCapability(/public/MetaUzuCollection_Emojia_Deed)
                          .borrow<&DeedNFT.Collection{NonFungibleToken.CollectionPublic, DeedNFT.CollectionPublic}>()
                          ?? panic("Can't get the User's Deed's collection.")

  let cltnCapCentralAdmin = getAccount(acctAddrAdmin).getCapability(/public/MetaUzuCollection_Emojia_Central)
                              .borrow<&CentralNFT.Collection{NonFungibleToken.CollectionPublic, CentralNFT.CollectionPublic}>()
                              ?? panic("Can't get the Admin's Central's collection.")
  
  let returnVals: [TempStruct] = []            // 최종 반환값을 빈 배열로 생성
  let ids = cltnCapDeedUser.getIDs()          // Deeds id 값들 가져옴..

  for id in ids {
    let deed = cltnCapDeedUser.borrowEntireNFT(id: id)                 // Deed 가져옴..
    let central = cltnCapCentralAdmin.borrowEntireNFT(id: (deed?.cid)! )    // Central 가져옴..
    
    // deed.insert(key: "price", central.price)  // 이런형태론 안됨..
    // deed[price] = central.price               // 이런형태도 안됨..
    
    // 예전 cdc 에서 적용되었던방식..
    // deed?.metadataAny["price"] = central?.price    // metadataAny or metadata 를 활용하자..
    // returnVals.append(deed)

    // 22.06.13 변경된 방식으로 적용..
    returnVals.append(TempStruct(
      nft: deed,
      price: central?.price,
    ))

  }

  return returnVals
}
`
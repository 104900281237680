export const meuGetSaleReplicasScript = `
// Official
import FlowToken from 0xFLOW
import NonFungibleToken from 0xNFT
// MEU
import ReplicaNFT from 0xMEU
import ReplicaMarket from 0xMEU

pub fun main(acctAddrAdmin: Address): {UInt64: ReplicaMarket.SaleItem} {
  // 저장 경로: StoragePath = /storage/경로이름
  // 사적 경로: PrivatePath = /private/경로이름
  // 공공 경로: PublicPath  =  /public/경로이름

  // 1. 센트랄 경로이름      ==> MetaUzuCollection_Emojia_Central             CentralNFT

  // 2. 증서고 경로이름      ==> MetaUzuCollection_Emojia_Deed                DeedNFT
  // 3. 증서고 마켓경로      ==> MetaUzuCollection_Market_Deed                DeedMarket
  // 4. 증서고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Deed         DeedMarketTicket

  // 5. 복각고 경로이름      ==> MetaUzuCollection_Emojia_Replica             ReplicaNFT
  // 6. 복각고 마켓경로      ==> MetaUzuCollection_Market_Replica             ReplicaMarket
  // 7. 복각고 티켓경로      ==> MetaUzuCollection_Emojia_Ticket_Replica      ReplicaMarketTicket

  if (getAccount(acctAddrAdmin).getCapability(/public/MetaUzuCollection_Market_Replica)
      .borrow<&ReplicaMarket.SaleCollection{ReplicaMarket.SaleCollectionPublic}>() != nil) &&
     (getAccount(acctAddrAdmin).getCapability(/public/MetaUzuCollection_Emojia_Replica)
      .borrow<&ReplicaNFT.Collection{NonFungibleToken.CollectionPublic, ReplicaNFT.CollectionPublic}>() != nil)  {
    
        let cltnCapMkReplicaAdmin = getAccount(acctAddrAdmin).getCapability(/public/MetaUzuCollection_Market_Replica)
                                      .borrow<&ReplicaMarket.SaleCollection{ReplicaMarket.SaleCollectionPublic}>()
                                      ?? panic("Can't get the Admin's mkReplica's collection.")

        let cltnCapReplicaAdmin = getAccount(acctAddrAdmin).getCapability(/public/MetaUzuCollection_Emojia_Replica) 
                                    .borrow<&ReplicaNFT.Collection{NonFungibleToken.CollectionPublic, ReplicaNFT.CollectionPublic}>()
                                    ?? panic("Can't get the Admin's Replica's collection.")

        let saleIDs = cltnCapMkReplicaAdmin.getIDs()

        let returnVals: {UInt64: ReplicaMarket.SaleItem} = {}

        // 여기서 일반사용자들이 구매하는데 도움이 되는 정보들을 섞어서 보내줄것..
        // 마켓에 노출되는 정보들을 여기서 조립하는 것임..
        for saleID in saleIDs {
          let price = cltnCapMkReplicaAdmin.getPrice(id: saleID)
          let seller = cltnCapMkReplicaAdmin.getSeller(id: saleID)
          let trid = saleID
          let rid = cltnCapMkReplicaAdmin.getRid(id: saleID)   // === nftRef.id
          let nftRef = cltnCapReplicaAdmin.borrowEntireNFT(id: rid)

          returnVals.insert(key: trid, ReplicaMarket.SaleItem(_id: trid, _rid: rid, _price: price, _seller: seller, _nftRef: nftRef,))
        }
        return returnVals

  }else{

        return {}
  }
  
  
}
`
import * as fcl from '@onflow/fcl';
import * as types from '@onflow/types';
import { meuCreateAllCollectionTx } from '../cadence/transactions/meu_create_all_collection';
import { meuDeleteAllCollectionTx } from '../cadence/transactions/meu_delete_all_collection';
import { meuMintCentralDeedNFT } from '../cadence/transactions/meu_mint_central_deed';
import { serverAuthorization } from '../serverSigner';
// import { create } from 'ipfs-http-client';

/*** 기존 infura 방식.. (infura에서, deprecated 되었음.. ) ***/
// const client = create('https://ipfs.infura.io:5001/api/v0');
/*** 새로운 nft.storage 방식.. ***/
// const { NFTStorage, File } = require('nft.storage')  // CommonJs 방식..
// import { NFTStorage, File } from 'nft.storage'       // ES모듈 방식.. (webpack 5이상에서만 사용가능..)
import { NFTStorage, File, Blob } from 'nft.storage/dist/bundle.esm.min.js' // 현재 프로젝트가 webpack4 버전이라, 해당방식으로 사용함..
const client = new NFTStorage({
  // endpoint: 'https://api.nft.storage',   // <== 이부분을 넣어주면, 내 계정에서 관리를 안함.. 전체공개된스토리지? 쯤으로 이동되는것 같음..
                                            // 빼면, 내 계정에서 관리가 가능해짐..
  token: process.env.REACT_APP_NFT_STORAGE_API_KEY_1,
})

/*********************************************************************
 * event.target.files[0] 의 형태 그대로, file 매개변수로 받아서 사용함..
 * Blob 으로 올리게되면, 같은파일은 계속해서 같은 hash 값만 나오기 때문에.. (파일이름이 달라도..)
 * ..이는 p2p 방식이라, 동일파일은 같은 cid 를 계속해서 사용하기 때문인 것으로 보임..
 * ..때문에 같은(동일)한 파일의 중복등록방지가 되는 효과는 있으나,
 * ..일단 해당 hash 값을 return 하기 때문에, 중복체크하는 과정이 없기에, 
 * ..현재 meu 방식에선 사용할수가 없는 상태임..
 * ..주석 및 함수 제거하지 말것..
 *********************************************************************/
const uploadNftStorageOfStoreBlob = async (fileImage) => {
  const hash = await client.storeBlob(fileImage)
  console.log("uploadNftStorageOfStireBlob's hash ===> ", hash)
  console.log("image address #1 ===> ", `https://ipfs.io/ipfs/${hash}`)
  console.log("image address #2 ===> ", `https://${hash}.ipfs.nftstorage.link`)
}
/*********************************************************************
 * fileName : String..
 * fileDescription : String..
 * fileImage : event.target.files[0] 의 형태 그대로, file 매개변수로 받아서 사용함..
 * NFT를 metadata 와 함께 저장하는 방식의 함수..
 * ..json 형태의 metadata 를 return 함..
 * ..내부적으로 동일한 파일이고, 파일이름이 같을때는 같은 image 파일로 돌려주기 때문에..
 * ..사용시 고려해볼 부분 있음.. (잠재적이슈있음.. ※※※※※※)
 * ..생성된 metadata URI 에 들어가보면 아래와 같은 json 형태의 데이터를 확인 가능함..
 * {
 *    "name":"NFT name",
 *	  "description":"NFT decription",
 * 	  "image":"ipfs://bafybeiagkcxeibm6szdfftxsjl4c7eyobllbimcqs4cwolb2h62iudfyk4/cocs.png"
 * }
 *********************************************************************/
const uploadNftStorageOfStore = async (fileName, fileDescription, fileImage) => {
  const metadata = await client.store({
    name: fileName,
    description: fileDescription,
    image: fileImage
  })
  console.log("uploadNftStorageOfStore's metadata ===> ", metadata)
  console.log("metadata's URI address ===> ", `https://${metadata.ipnft}.ipfs.nftstorage.link/metadata.json`)

  const requestURL = `https://${metadata.ipnft}.ipfs.nftstorage.link/metadata.json`
  const request = new XMLHttpRequest()
  request.open('GET', requestURL)
  request.responseType = 'json'
  request.send()
  request.onload = () => {
    const md = request.response;
    const hash = md.image.replace("ipfs://", "")
    console.log("md ===> ", md)
    console.log("md.image ===> ", md.image)
    console.log("md.image address ===> ", `${md.image.replace("ipfs://", "https://ipfs.io/ipfs/")}`)
    console.log("uploadNftStorageOfStore's hash ===> ", hash)
  }
  console.log("......###")
}

const deleteAllCollectionUser = async () => {
  try {
    const transactionId = await fcl
      .send([
        fcl.transaction(meuDeleteAllCollectionTx),
        fcl.args([]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz]),
        fcl.limit(9999),
      ])
      .then(fcl.decode);
  
    console.log(transactionId);
    return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
};

const createAllCollectionAdmin = async () => {
  try {
    const transactionId = await fcl
      .send([
        fcl.transaction(meuCreateAllCollectionTx),
        fcl.args([fcl.arg(true, types.Bool)]),
        fcl.payer(serverAuthorization),
        fcl.proposer(serverAuthorization),
        fcl.authorizations([serverAuthorization]),
        fcl.limit(9999),
      ])
      .then(fcl.decode);
  
    console.log(transactionId);
    return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
};

const createAllCollectionUser = async () => {
  try {
    const transactionId = await fcl
      .send([
        fcl.transaction(meuCreateAllCollectionTx),
        fcl.args([fcl.arg(false, types.Bool)]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz]),
        fcl.limit(9999),
      ])
      .then(fcl.decode);
  
    console.log(transactionId);
    return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
};

const deleteAllCollectionAdmin = async () => {
  try {
    const transactionId = await fcl
      .send([
        fcl.transaction(meuDeleteAllCollectionTx),
        fcl.args([]),
        fcl.payer(serverAuthorization),
        fcl.proposer(serverAuthorization),
        fcl.authorizations([serverAuthorization]),
        fcl.limit(9999),
      ])
      .then(fcl.decode);
  
    console.log(transactionId);
    return fcl.tx(transactionId).onceSealed();
    } catch (error) {
      console.log("error ===> ", error)
      // alert("error ===> ", error)
    }
};

const logIn = () => {
  // log in through Blocto
  fcl.authenticate();
};

const logOut = () => {
  // log in through Blocto

  fcl.unauthenticate();
  setUser(null);
};




/******************************************************************
 * 해당기능은, 구현은 되어있으나, progress 관련하여..
 * Create 페이지내에서 별도로 추가 구현되도록 하였음..
 * 만약 현재 api 처럼 사용하려면, 별도로 component 형태로 사용될수 있도록 
 * 생각해봐야함...
 ******************************************************************
 * @param {*} fileCentral
 * @param {*} nameAuthorOfCentralNFT
 * @param {*} nameArticleOfCentralNFT
 * @param {*} descOfCentralNFT
 * @param {*} priceOfCentralNFT
 * @returns
 */
const mintCentralDeed = async (
  fileCentral,
  nameAuthorOfCentralNFT,
  nameArticleOfCentralNFT,
  descOfCentralNFT,
  priceOfCentralNFT
) => {
  console.log(
    'mintCentralDeed params : ',
    fileCentral,
    nameAuthorOfCentralNFT,
    nameArticleOfCentralNFT,
    descOfCentralNFT,
    priceOfCentralNFT
  );
  try {
    /*** 기존 infura 방식.. (infura에서, deprecated 되었음.. ) ***/
    // const added = await client.add(fileCentral);
    // const hash = added.path;
    /*** 새로운 nft.storage 방식.. ***/
    console.log("..Step #1 : Storing image file...")
    const json = await client.store({
      name: nameArticleOfCentralNFT,
      description: descOfCentralNFT,
      image: fileCentral
    })
    const requestURL = `https://${json.ipnft}.ipfs.nftstorage.link/metadata.json`
    const request = new XMLHttpRequest()
    request.open('GET', requestURL)
    request.responseType = 'json'
    request.send()

    console.log("..Step #2 : Receiving json file...")
    request.onload = async () => {
      const metadata = request.response
      const hash = metadata.image.replace("ipfs://", "")

      console.log("..Step #3 : Received json file.. Waiting for Flow Blockchain's signature..")
      const transactionId = await fcl.send([
        fcl.transaction(meuMintCentralDeedNFT),
        fcl.args([
          fcl.arg(hash, types.String),
          fcl.arg(nameArticleOfCentralNFT, types.String),
          fcl.arg(nameAuthorOfCentralNFT, types.String),
          fcl.arg(descOfCentralNFT, types.String),
          fcl.arg(priceOfCentralNFT, types.UFix64),
        ]),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz, serverAuthorization]),
        fcl.limit(9999),
      ]).then(fcl.decode);
      console.log("..Step #4 : Waiting for transaction to be sealed..")
      console.log("transactionId ===> ", transactionId);
      return fcl.tx(transactionId).onceSealed();
    }
    // console.log("heW!")
    // setTimeout(() => { console.log("pause.. 3sec..") }, 3000);

  } catch (error) {
    console.log('Error uploading file: ', error);
  }
};

export default {
  deleteAllCollectionUser,
  createAllCollectionUser,
  createAllCollectionAdmin,
  deleteAllCollectionAdmin,
  logIn,
  logOut,
  mintCentralDeed
};

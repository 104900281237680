import { config } from "@onflow/fcl"

config({
  "accessNode.api": process.env.REACT_APP_FCL_ACCESS_NODE,
  "discovery.wallet": process.env.REACT_APP_FCL_WALLET_DISCOVERY,
  "discovery.authn.api": process.env.REACT_APP_FCL_WALLET_DISCOVERY_API,
  "0xFT": process.env.REACT_APP_FT_CONTRACT,
  "0xFUSD": process.env.REACT_APP_FUSD_CONTRACT,
  "0xFLOW": process.env.REACT_APP_FLOW_CONTRACT,
  "0xNFT": process.env.REACT_APP_NFT_CONTRACT,
  "0xMEU": process.env.REACT_APP_MEU_CONTRACT,
  "app.detail.title": "MetaUzu Wallet",                           // fcl 0.0.79-alpha.4 부터 적용됨..
  // "app.detail.icon": "https://placekitten.com/g/200/200"    // fcl 0.0.79-alpha.4 부터 적용됨..
  // "app.detail.icon": `${process.env.PUBLIC_URL}/assets/LogoMetaUzu.png`    // fcl 0.0.79-alpha.4 부터 적용됨..
  // "app.detail.icon": "https://drive.google.com/file/d/1qbY-VAk1MEeqvSLPhQSJXaQ8OI8dZB4s/view?usp=sharing" // 일단 임시로 이렇게 사용..
  "app.detail.icon": "https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FuqJpZ%2FbtqyenBIIXx%2Fmh1Cc5F023UGpfQTFBdqV0%2Fimg.jpg" // 일단 임시로 이렇게 사용..
  // 뭔가,, 어디에 있느냐에 따라 이미지가.. 되고 안되고 하는것 같음.. 일단 제껴..
})